/***************************************************************************************************************
||||||||||||||||||||||||||       STATAMIC STYLESHEET FOR MHCHESS         |||||||||||||||||||||||||||||||||||||||
****************************************************************************************************************


/*** @IMPORTED 'statamic.scss' ***/
html, body {
    min-width: 360px;
}

body {
    font-size: 16px;
    line-height: 1.5em;
    box-sizing: border-box;
}
p.white, .state-content ul li .text p.white {
    color: #fff !important;
}
.content-section {
    padding: 80px 0 50px;
}
.content-section img {
    padding: 5px;
    border: 1px solid #e0e0e0;
    display: block;
    margin: 0 auto;
    box-shadow: 0px 0px 6px rgba(110, 110, 110, 0.2);
    }

.content-section {
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #333333;
    font-weight: 300;
    line-height: 40px;
    margin: 0 0 11px;
    }
    h1, .h1 {
        font-size: 2.4em;
    }
    h2, .h2 {
        font-size: 2em;
    }
    h3, .h3 {
        font-size: 1.5em;
    }
    h4, .h4 {
        font-size: 1em;
        line-height: 1em;
    }
    h5, .h5 {
        font-size: .8em;
        line-height: 1em;
    }
    h6, .h6 {
        font-size: .5em;
        line-height: 1em;
    }
}
.grey-background {
    background-color: rgb(233, 233, 233);
}
.black-background {
    background-color: #000;
}
.primary-background {
    background-color: $primary-color;
    background-image: url(/assets/media/backgrounds/egg-shell.png);
}

.secondary-background {
    background-color: $secondary-color;
    background-image: url(/assets/media/backgrounds/egg-shell.png);
}

.tertiary-background {
    background-color: $tertiary-color;
    background-image: url(/assets/media/backgrounds/egg-shell.png);
}

.primary-color {
    color: $primary-color !important;
}
.secondary-color {
    color: $secondary-color !important;
}
.tertiary-color {
    color: $tertiary-color !important;
}

.bg-light {
    & a {
        color: $primary-color;
    }
    & a:hover {
        color: lighten($primary-color, 30%);
    }
}


/*** UTILITY CLASSES ***/

.clear {
    clear: both;
}
.clearfix {
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }

/*** CONTENT CSS ***/

.header-top,
.layout-default .mainmenu-area {
    background-image: url(/assets/media/backgrounds/checker.jpg);
}

.layout-default .stricky-fixed .logo {
    display: none;
}

.layout-default .stricky-fixed .main-menu.float-left {
    float: none !important;
    text-align: center !important;
}

.layout-default nav {
    margin-top: 3em;
}

.layout-default .stricky-fixed nav {
    margin: 0;
}

.mainmenu-area.style3.stricky-fixed {
    background: #000;
    @media screen and (min-width: 990px) {
        background: #f5f5f5;
    }
}

a {
    color: lighten($primary-color, 20%);
    transition: all .5s ease-in-out;
    &:hover {
        color: #ccc;
    }
}

.icon-holder img {
    height: 120px;
    margin-top: 1em;
}

h1.title {
    color: $primary-color;
}

h1.title span {
    color: $tertiary-color;
}

.header-style1-area .header-right p {
    margin: 0;
    @media screen and (min-width: 768px) {
        margin: 0 30px 3px;
    }
}

.header-style1-area .header-right h2 {
    padding-right: 0;
    @media screen and (min-width: 980px) {
        padding-right: 40px;
    }
}

.header-style1-area .header-right p.small {
    margin-right: 30px;
    @media screen and (min-width: 980px) {
        margin-right: 0;
    }
    @media screen and (min-width: 768px) {
        margin-right: 36px;
    }
}

.primary {
    color: $primary-color !important;
}

.secondary {
    color: $secondary-color !important;
}

.tertiary {
    color: $tertiary-color !important;
}

/*** FLEXSLIDER STUFF ****/

.flexslider {
    margin: 0;
    border: none;
    border-radius: 0;
}

.flex-direction-nav a:before {}

.flex-viewport {
    border: none;
}

.flex-direction-nav a:before {
    margin-top: 10px;
}

.flexslider .slides > li {
    line-height: .8em;
    @media (min-width: 480px) {
        line-height: 2em;
    }
    @media (min-width: 780px) {
        line-height: 3em;
    }
    @media (min-width: 960px) {
        line-height: 5em;
    }

}
ul.slides li {
    position: relative;
    .slide-content {
        padding-top: 1em;
        top: 0;
        left: 0;
        position: absolute;
        font-family: 'Poppins', Arial, Helvetica, sans-serif !important;
        color: #fff;
        font-size: 1vw;
        @media (min-width:960px) {
            padding-top: 2em;  
        }
        @media (min-width:1500px) {
          padding-top: 150px;
        }
        p {
            text-shadow: 3px 3px 0px rgba(0, 0, 0, .2);
        }
        .small-line {
            font-size: 2em;
            font-weight: 300;
            white-space: nowrap;
        }
        .medium-line {
            font-size: 4em;
            font-weight: 400;
            white-space: nowrap;
        }
        .large-line {
            font-size: 6em;
            font-weight: 600;
            text-transform: uppercase;
            white-space: nowrap;
        }
        a.btn-one {
            background-color: #18391A;
            margin-top: 1em;
            &:hover {
                background-color: #FFCF70;
                color: #18391A;
            }
        }
    }

}

.flex-direction-nav a,
.flex-direction-nav a::before {
    color: lighten($primary-color, 20%);
}
.slidertext-row .btn-one {
    padding: 13px 16px 13px;
}
/*** EVENTS CSS ***/

.event-date {
    background-color: #cfcfcf;
    position: relative;
    padding: 55px 5px 0;
    margin-left: 10px;
    height: 90px;
    border-radius: 10px;
    min-width: 145px;
    margin-bottom: .5em;

    .year {
        display: block;
        position: absolute;
        right: -5px;
        top: 29px;
        transform: rotate(-90deg);
        font-size: 32px;
        font-weight: 100;
    }
    .month {
        text-transform: uppercase;
        font-size: 18px;
    }
    
    .day {
        font-size: 45px;
        line-height: 45px;        
        position: absolute;        
        left: 15px;        
        top: 10px;
    }

}



/*** NAVS & PILLS ***/

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: $tertiary-color;
}
    
  
    
.contact-form .map {
    display: none;
    @media (min-width: 980px) {
    display: block;
    position: absolute;
    top: -90px;
    left: 0;
    bottom: -90px;
    right: -10000px;
    // background: #f5f5f5;
    content: "";
    z-index: 2;
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-image: url(https://placeimg.com/900/800/any);
    }
    
} 

/*** SPONSOR PAGES ***/
.sponsor-lists ul, .sponsor-lists li {
    list-style: inherit;
}

.sponsor-categories {
    font-size: 1.4em;
}
.sponsor-categories a {
    background-color: lighten($primary-color,20%);
    color: #fff;
    margin-right: 1em;
    transition: all .5s ease-in-out;
    padding: 20px;
    margin-bottom: 1em;
    &:hover {
        color: $tertiary-color;
        background-color: $primary-color;
    }
}

/*** CONTACT FORM ***/

.email-success {
    background-color: #63946e;
    border-top: 4px solid #14461f;
    border-bottom: 4px solid #14461f;
    height: 100px;
}