/******************************************************************************************
 ***********************              VARIABLES             *******************************
 ******************************************************************************************
*/
/*** MIXINS ***/
/*** INSERTS ***/
/***************************************************************************************************************
||||||||||||||||||||||||||       MASTER STYLESHEET FOR FixZone          |||||||||||||||||||||||||||||||||||||||
****************************************************************************************************************
||||||||||||||||||||||||||                TABLE OF CONTENT               |||||||||||||||||||||||||||||||||||||||
****************************************************************************************************************
****************************************************************************************************************

01. Imported styles
02. Flaticon reset styles
03. Header styles
04. Mainmenu styles
05. Rev_slider_wrapper styles
66. Call to action area Style
07. Categories area style
08. Fact counter area style
09. Latest Blog area style
10. Latest blog area style 
11. Testimonial area style
12. Brand area style
13. Single service style
14. Pricing table area style
15. About page style
16. Account page style
17. 404 page style style
18. Blog page style
19. Blog single style
20. Contact page style

****************************************************************************************************************
||||||||||||||||||||||||||||            End TABLE OF CONTENT                ||||||||||||||||||||||||||||||||||||
****************************************************************************************************************/
/* Imported styles */
@import url("https://fonts.googleapis.com/css?family=Titillium+Web:300,300i,400,400i,600,600i,700");
@import url("https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i");
@import url(imp.css);
@import url(bootstrap.css);
@import url(font-awesome.min.css);
@import url(hover.css);
@import url(jquery.bxslider.css);
@import url(owl.css);
@import url(owl.theme.default.css);
@import url(animate.min.css);
@import url(jquery.bootstrap-touchspin.css);
@import url("bootstrap-select.min.css");
@import url("imagehover.css");
@import url("jquery.fancybox.min.css");
@import url("jquery.mCustomScrollbar.min.css");
@import url("tinyscrollbar.css");
@import url(../fonts/flaticon/flaticon.css);
/*** Price filter***/
@import url(../assets/price-filter/nouislider.css);
@import url(../assets/price-filter/nouislider.pips.css);
/*** single shop slide ***/
@import url(flexslider.css);
/*** Time picker css ***/
@import url(../assets/timepicker/timePicker.css);
/*** flaticon fonts css ***/
@import url(../fonts/flaticon/flaticon.css);
/*** jquery ui css ***/
@import url(../assets/jquery-ui-1.11.4/jquery-ui.css);
/*** Revolution slider ***/
@import url(../plugins/revolution/css/settings.css);
@import url(../plugins/revolution/css/layers.css);
@import url(../plugins/revolution/css/navigation.css);
/*** Bootstrap select picker css ***/
@import url(../assets/bootstrap-sl-1.12.1/bootstrap-select.css);
/*** Language Switcher ***/
@import url(../assets/language-switcher/polyglot-language-switcher.css);
/*==============================================
   Base Css
===============================================*/
html,
body {
  height: 100%;
}

body {
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  color: #848484;
  line-height: 24px;
  font-weight: 400;
}

button:focus {
  outline: none;
}

button {
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  margin: 0;
}

h3 {
  font-size: 1.6em;
  line-height: 30px;
}

h4 {
  font-size: 1.4em;
  line-height: 24px;
}

a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
  border: none;
}

.fix {
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

i {
  font-style: normal;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.big-item {
  width: 40%;
  float: left;
}

.span-width-5 {
  width: 20%;
  float: left;
  padding: 0 15px;
}

.sec-pd-one {
  padding: 80px 0;
}

.sec-pding-two {
  padding: 80px 0 40px;
}

.btn-one {
  background: #ff612a;
  color: #ffffff;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  display: inline-block;
  padding: 23px 40px 23px;
  text-transform: uppercase;
  font-family: 'Titillium Web', sans-serif;
  transition: all 500ms ease;
  position: relative;
  border-radius: 30px;
}

.btn-one:hover {
  color: #ffffff;
  background: #ef3f02;
}

.btn-two {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Titillium Web', sans-serif;
  transition: all 500ms ease;
}

.btn-two span:before {
  position: relative;
  top: 1px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  padding-left: 9px;
}

.btn-two:hover {
  color: #36813b;
}

.sec-title {
  position: relative;
  margin-top: -5px;
  padding-bottom: 43px;
}

.sec-title .inner {
  position: relative;
  display: inline-block;
  line-height: 14px;
  border-left: 6px solid #36813b;
  padding-left: 9px;
  border-right: 6px solid #36813b;
  padding-right: 9px;
}

.sec-title .inner.clr2 {
  border-left: 6px solid #f3441e;
  border-right: 6px solid #f3441e;
}

.sec-title .inner span {
  position: relative;
  top: 1px;
  color: #36813b;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
  line-height: 14px;
}

.sec-title .inner span.clr2 {
  color: #f3441e;
}

.sec-title .title {
  position: relative;
  display: block;
  margin: 7px 0 0;
  color: #18391A;
  font-size: 36px;
  line-height: 40px;
  font-weight: 400;
  text-transform: none;
  font-family: 'Titillium Web', sans-serif;
  z-index: 1;
}

.sec-title .title.clr2 {
  color: #2f2f2f;
}

.sec-title .title.clrwhite {
  color: #ffffff;
}

.parallax-bg-one {
  background-attachment: fixed;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.parallax-bg-one::before {
  background: rgba(18, 32, 0, 0.9) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.parallax-bg-two {
  background-attachment: fixed;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.parallax-bg-two::before {
  background: rgba(9, 23, 33, 0.9) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.flex-box {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-box-two {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-box-three {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.flex-box-four {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.flex-box-five {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.owl-nav-style-one.owl-theme .owl-nav [class*="owl-"] {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 50%;
  color: #909090;
  font-size: 24px;
  font-weight: 700;
  height: 50px;
  line-height: 46px;
  margin: 0 0 0 0px;
  padding: 0;
  width: 50px;
  border: 1px solid #f3f3f3;
  transition: all 700ms ease 0s;
}

.owl-nav-style-one.owl-theme .owl-nav .owl-next {
  margin-left: 10px;
}

.owl-nav-style-one.owl-theme .owl-nav [class*="owl-"]:hover {
  border-color: #172272;
  color: #172272;
}

.owl-nav-style-two.owl-theme .owl-nav [class*="owl-"] {
  background: #fff none repeat scroll 0 0;
  border-radius: 0%;
  color: #3740b0;
  font-size: 24px;
  font-weight: 700;
  height: 50px;
  line-height: 50px;
  margin: 0 0 0 0px;
  padding: 0;
  width: 50px;
  transition: all 700ms ease 0s;
}

.owl-nav-style-two.owl-theme .owl-nav .owl-next {
  margin-left: 0px;
}

.owl-nav-style-two.owl-theme .owl-nav [class*="owl-"]:hover {
  color: #ffffff;
  background: #3740b0;
}

.accordion-box {
  display: block;
  overflow: hidden;
  padding: 0;
}

.accordion-box .accordion {
  position: relative;
  background: #ffffff;
  margin-bottom: 10px;
  border-radius: 30px;
  display: block;
  padding: 0 30px;
}

.accordion-box .accordion .accord-btn {
  border-bottom: 1px solid #ffffff;
  cursor: pointer;
  position: relative;
  transition: all 500ms ease;
  display: block;
  padding-left: 40px;
  padding-top: 21px;
  padding-bottom: 22px;
}

.accordion-box .accordion .accord-btn h4 {
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  transition: all 500ms ease;
}

.accordion-box .accordion .accord-btn::after {
  color: #e6e6e6;
  content: "\f105";
  font-family: "Flaticon";
  font-size: 25px;
  line-height: 25px;
  font-weight: 500;
  position: absolute;
  left: 0;
  top: 22px;
  transition: all 500ms ease 0s;
  text-align: center;
  display: block;
  width: 30px;
  height: 30px;
}

.accordion-box .accordion .accord-btn.active {
  border-bottom: 1px solid #f2f2f2;
  transition: all 500ms ease;
}

.accordion-box .accordion .accord-btn.active h4 {
  color: #3aabd0;
}

.accordion-box .accordion .accord-btn.active:after {
  color: #3aabd0;
}

.accordion-box .accordion .accord-content {
  display: none;
  position: relative;
  padding-top: 35px;
  padding-bottom: 33px;
}

.accordion-box .accordion .accord-content.collapsed {
  display: block;
}

.accordion-box .accordion .accord-content p {
  margin: 0;
  color: #999999;
}

.boxed_wrapper {
  background: #fff none repeat scroll 0 0;
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 320px;
  min-height: 400px;
  overflow: hidden;
}

/*________________Preloader_______________ */
.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999999999999999;
  background-color: #f4f6f4;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(/assets/media/backgrounds/chess.gif);
}

/* Update header Style */
@keyframes menu_sticky {
  0% {
    margin-top: -100px;
  }
  50% {
    margin-top: -74px;
  }
  100% {
    margin-top: 0;
  }
}

.mainmenu-area.stricky {
  background-color: #18391A;
  background-image: url(/assets/media/backgrounds/green-fibers.png);
  min-height: 200px;
}

#home .mainmenu-area.stricky {
  min-height: auto;
}

.mainmenu-area.stricky-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  background: #000;
  animation-name: menu_sticky;
  min-height: auto;
  animation-duration: 0.60s;
  animation-timing-function: ease-out;
  transition: all .25s ease-in-out;
}

@media screen and (min-width: 990px) {
  .mainmenu-area.stricky-fixed {
    background: #f5f5f5;
  }
}

.mainmenu-area.style2.stricky-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999999999;
  background: #f5f5f5;
  margin: 0;
  animation-name: menu_sticky;
  animation-duration: 0.60s;
  animation-timing-function: ease-out;
  transition: all .25s ease-in-out;
}

.mainmenu-area.style2.stricky-fixed .button a {
  border-radius: 0;
}

.mainmenu-area.style4.stricky-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999999999;
  background: #f5f5f5;
  margin: 0;
  animation-name: menu_sticky;
  animation-duration: 0.60s;
  animation-timing-function: ease-out;
  transition: all .25s ease-in-out;
}

.mainmenu-area.style3.stricky-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999999999;
  background: #f5f5f5;
  margin: 0;
  animation-name: menu_sticky;
  animation-duration: 0.60s;
  animation-timing-function: ease-out;
  transition: all .25s ease-in-out;
}

/* scroll to top styles */
.scroll-to-top {
  background: #282828;
  border-radius: 4px;
  bottom: 25px;
  color: #ffffff;
  cursor: pointer;
  display: none;
  font-size: 25px;
  font-weight: normal;
  height: 55px;
  line-height: 56px;
  position: fixed;
  right: 15px;
  text-align: center;
  transition: all 500ms ease 0s;
  width: 55px;
  z-index: 99999999;
  box-shadow: 0px 8px 23px -6px #1b1b1b;
}

.scroll-to-top:hover {
  opacity: .5;
}

/* Form validation styles */
input:focus,
textarea:focus,
select:focus {
  border-color: #43c3ea;
  outline: none;
}

#contact-form input[type="text"].error {
  border-color: red;
}

#contact-form input[type="email"].error {
  border-color: red;
}

#contact-form select.error {
  border-color: red;
}

#contact-form textarea.error {
  border-color: red;
}

/* Post pagination styles */
.post-pagination {
  display: block;
  margin-top: 10px;
}

.post-pagination li {
  display: inline-block;
  margin: 0 3px;
}

.post-pagination li a {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #f2f2f2;
  color: #2f2f2f;
  display: block;
  font-size: 18px;
  font-weight: 600;
  height: 55px;
  padding: 15px 0;
  transition: all 500ms ease 0s;
  width: 55px;
  font-family: 'Titillium Web', sans-serif;
  border-radius: 4px;
}

.post-pagination li.active a,
.post-pagination li:hover a {
  background: #f3441e;
  color: #ffffff;
  border: 1px solid #f3441e;
}

/* Overlay styles */
.overlay-style-one {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(20, 20, 20, 0.9);
  content: "";
  opacity: 0;
  z-index: 9;
  transition: all 0.4s ease-in-out 0s;
}

.overlay-style-one .box {
  display: table;
  height: 100%;
  width: 100%;
}

.overlay-style-one .box .content {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.review-box ul li {
  display: inline-block;
  float: left;
  margin-right: 4px;
}

.review-box ul li:last-child {
  margin-right: 0px;
}

.review-box ul li i {
  font-size: 12px;
}

.sociallinks-style-one li {
  display: inline-block;
  float: left;
  margin-right: 15px;
}

.sociallinks-style-one li:last-child {
  margin-right: 0px;
}

.sociallinks-style-one li a i {
  color: #959595;
  font-size: 14px;
  transition: all 500ms ease;
}

.sociallinks-style-one li a:hover i {
  color: #172272;
}

.sociallinks-style-two {
  overflow: hidden;
}

.sociallinks-style-two li {
  display: inline-block;
  margin-right: 10px;
}

.sociallinks-style-two li:last-child {
  margin-right: 0px;
}

.sociallinks-style-two li a i {
  border: 2px solid #e5e5e5;
  background: #e5e5e5;
  border-radius: 50%;
  color: #848484;
  font-size: 14px;
  height: 45px;
  line-height: 42px;
  padding: 0;
  text-align: center;
  transition: all 500ms ease 0s;
  width: 45px;
}

.sociallinks-style-two li a:hover i {
  background: #4eab27;
  border-color: #4eab27;
  color: #fff;
}

/*** 
===========================================
	Fancy Box
===========================================

***/
.fancybox-next span,
.fancybox-prev span {
  background-image: none !important;
  width: 44px !important;
  height: 44px !important;
  line-height: 44px !important;
  text-align: center;
}

.fancybox-next span:before,
.fancybox-prev span:before {
  content: '';
  position: absolute;
  font-family: 'FontAwesome';
  left: 0px;
  top: 0px;
  font-size: 12px;
  width: 44px !important;
  height: 44px !important;
  line-height: 44px !important;
  background-color: rgba(28, 28, 28, 0.4) !important;
  color: #ffffff;
  visibility: visible;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.fancybox-next span:before {
  content: '\f178';
}

.fancybox-prev span:before {
  content: '\f177';
}

.fancybox-next:hover span:before,
.fancybox-prev:hover span:before {
  background-color: #ffffff !important;
  color: #000000;
}

.fancybox-type-image .fancybox-close {
  right: 0px;
  top: 0px;
  width: 45px;
  height: 45px;
  background: url(../images/icon/icon-cross.png) center center no-repeat;
  background-color: rgba(17, 17, 17, 0.5) !important;
}

.fancybox-type-image .fancybox-close:hover {
  background-color: #000000 !important;
}

.fancybox-type-image .fancybox-skin {
  padding: 0px !important;
}

/*==============================================
    Top bar Area Css
===============================================*/
.top-bar-style1 {
  position: relative;
  display: block;
  padding: 15.5px 0;
  background: #18391A;
  background-image: url(/assets/media/backgrounds/green-fibers.png);
}

.top-bar {
  position: relative;
  display: block;
  width: 100%;
}

.top-bar .top-left {
  position: relative;
  display: block;
  max-width: 500px;
  width: 100%;
}

.top-bar .top-left ul {
  display: block;
  overflow: hidden;
  margin: 2px 0;
}

.top-bar .top-left ul li {
  display: inline-block;
  float: left;
  color: #ffffff;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid rgba(252, 252, 252, 0.15);
  line-height: 16px;
}

.top-bar .top-left ul li:last-child {
  margin-right: 0px;
  padding-right: 0px;
  border-right: 0px solid;
}

.top-bar .top-left ul li span:before {
  position: relative;
  top: 2px;
  display: inline-block;
  padding-right: 12px;
  color: #36813b;
  font-size: 20px;
  line-height: 20px;
}

.top-bar .top-right {
  position: relative;
  display: block;
  max-width: 200px;
  width: 100%;
}

.top-bar .top-right .header-social-links {
  display: block;
  overflow: hidden;
  text-align: right;
  line-height: 10px;
  margin: 4px 0;
}

.top-bar .top-right .header-social-links li {
  display: inline-block;
  margin-right: 15px;
  line-height: 16px;
}

.top-bar .top-right .header-social-links li:last-child {
  margin-right: 0;
}

.top-bar .top-right .header-social-links li a {
  display: block;
  float: left;
}

.top-bar .top-right .header-social-links li a i {
  color: #ffffff;
  font-size: 14px;
  transition: all 500ms ease;
}

.top-bar .top-right .header-social-links li a:hover i {
  color: #ff612a;
}

/*==============================================
    Top bar Style2 Css
===============================================*/
.top-bar-style2 {
  padding: 0 0;
  background: #ffffff;
}

.top-bar-style2 .top-bar {
  position: relative;
  display: block;
  border-bottom: 1px solid #f2f2f2;
}

.topmenu {
  position: relative;
  display: block;
}

.topmenu li {
  position: relative;
  display: inline-block;
  float: left;
  line-height: 50px;
  margin-right: 25px;
}

.topmenu li:before {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 3px;
  background: #f3441e;
  content: "";
  transition: all 500ms ease;
}

.topmenu li:hover:before {
  width: 100%;
}

.topmenu li:last-child {
  margin-right: 0;
}

.topmenu li a {
  color: #848484;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  transition: all 500ms ease;
}

.topmenu li a:hover {
  color: #f3441e;
}

.top-right-v2 {
  position: relative;
  display: block;
  padding-right: 60px;
  margin: 13px 0;
}

.top-right-v2 ul {
  display: block;
  overflow: hidden;
}

.top-right-v2 ul li {
  display: inline-block;
  float: left;
  margin-right: 18px;
}

.top-right-v2 ul li:last-child {
  margin-right: 0;
}

.top-right-v2 ul li a {
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}

.top-right-v2 ul li a span:before {
  position: relative;
  display: inline-block;
  padding-right: 9px;
  font-size: 16px;
  line-height: 16px;
  color: #f3441e;
}

/*==============================================
    Top bar Style3 Css
===============================================*/
.top-bar-style3 {
  position: relative;
  display: block;
  padding: 15.5px 0;
  background: #000000;
}

.top-bar.style3 .top-left ul li {
  color: #848484;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.top-bar.style3 .top-left ul li:last-child {
  border: none;
}

.top-bar.style3 .top-left ul li span:before {
  color: #3aabd0;
}

.top-bar.style3 .top-right .help-line p {
  color: #3aabd0;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Titillium Web', sans-serif;
  margin: 0;
  line-height: 22px;
}

.top-bar.style3 .top-right .help-line p a {
  position: relative;
  top: 1px;
  display: inline-block;
  padding-left: 5px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}

/*==============================================
    Header Area Css        
===============================================*/
.header-style1-area {
  position: relative;
  display: block;
  background: #ffffff;
}

.header-style1-area .inner-content {
  position: relative;
  display: block;
}

.header-style1-area .header-left {
  position: relative;
  display: block;
  width: 300px;
  border-right: 1px solid #FFCF70;
  padding: 32px 0px 70px 25px;
}

.header-style1-area .header-left:before {
  position: absolute;
  top: 42px;
  left: 0;
  width: 37px;
  height: 40px;
  background: url(../images/icon/header-left-arrow.png);
  background-repeat: no-repeat;
  content: "";
}

.header-style1-area .header-left p {
  color: #85cb89;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 3px;
}

.header-style1-area .header-left h2 {
  position: relative;
  display: block;
  padding-left: 25px;
  color: #36813b;
  font-size: 24px;
  font-family: 'Rubik', sans-serif;
}

.header-style1-area .header-middle {
  max-width: 540px;
  width: 100%;
  float: left;
}

.logo {
  display: inline-block;
  transition: all 500ms ease;
}

.logo a {
  display: inline-block;
}

.header-style1-area .header-right {
  position: relative;
  display: block;
  width: 300px;
  text-align: right;
  border-left: 1px solid #FFCF70;
  padding: 31.5px 0px 43px 25px;
}

.header-style1-area .header-right:before {
  position: absolute;
  top: 42px;
  right: 0;
  width: 37px;
  height: 40px;
  background: url(../images/icon/header-right-arrow.png);
  background-repeat: no-repeat;
  content: "";
}

.header-style1-area .header-right p {
  color: #85cb89;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 3px;
}

.header-style1-area .header-right h2 {
  position: relative;
  display: block;
  padding-right: 15px;
  color: #36813b;
  font-size: 24px;
  font-family: 'Rubik', sans-serif;
}

@media screen and (min-width: 768px) {
  .header-style1-area .header-right h2 {
    padding-right: 25px;
  }
}

/*==============================================
    Header Style2 Area Css        
===============================================*/
.header-style2-area {
  display: block;
  background: #ffffff;
  padding: 29px 0;
}

.header-style2-logo {
  display: block;
  margin: 5px 0;
}

.header-style2-logo a {
  display: block;
}

.headers2-header-right {
  position: relative;
  display: block;
}

.headers2-header-right ul li {
  display: inline-block;
  float: left;
  margin-right: 28px;
  padding-right: 28px;
  border-right: 1px solid #ebebeb;
}

.headers2-header-right ul li:last-child {
  margin-right: 0;
  padding-right: 0;
  border: none;
}

.headers2-header-right ul li .single-item p {
  color: #2f2f2f;
  text-transform: uppercase;
  margin: 0 0 3px;
}

.headers2-header-right ul li .single-item p span:before {
  position: relative;
  top: 1px;
  font-size: 20px;
  line-height: 20px;
  color: #f3441e;
  display: inline-block;
  padding-right: 10px;
}

.headers2-header-right ul li .single-item span {
  color: #848484;
}

.headers2-header-right ul li .single-item h3 {
  color: #f3441e;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  margin: 13px 0;
}

.headers2-header-right ul li .single-item h3 span {
  position: relative;
  top: 2px;
  color: #2f2f2f;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  display: inline-block;
  padding-left: 10px;
}

/*==============================================
    Header Style3 Area Css        
===============================================*/
.header-style3-area {
  display: block;
  background: #151515;
  padding: 25px 0;
}

.header-style3-logo {
  position: relative;
  display: block;
}

.header-style3-logo a {
  display: inline-block;
  margin: 8px 0;
}

.headers3-header-right {
  position: relative;
  display: block;
}

.headers3-header-right ul {
  position: relative;
  display: block;
  float: left;
  padding-right: 85px;
}

.headers3-header-right ul li {
  display: inline-block;
  float: left;
  border-right: 1px solid #383838;
  padding-right: 20px;
  margin-right: 20px;
  line-height: 40px;
}

.headers3-header-right ul li:last-child {
  border: none;
  margin: 0;
  padding: 0;
}

.headers3-header-right ul li .single-item p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.headers3-header-right ul li .single-item p span:before {
  position: relative;
  top: 2px;
  color: #f3441e;
  font-size: 23px;
  line-height: 23px;
  display: inline-block;
  padding-right: 15px;
}

.headers3-header-right .toggler-button .nav-toggler {
  position: absolute;
  top: -7px;
  right: 0;
  display: block;
  background: #f3441e;
  width: 55px;
  height: 55px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.headers3-header-right .toggler-button .nav-toggler .inner {
  display: block;
  position: relative;
  margin: 20px 0 35px;
}

.headers3-header-right .toggler-button .nav-toggler span {
  display: block;
  position: absolute;
  height: 2px;
  width: 32px;
  background: #ffffff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.headers3-header-right .toggler-button .nav-toggler span:nth-child(2) {
  top: 7px;
  width: 32px;
}

.headers3-header-right .toggler-button .nav-toggler span:nth-child(3) {
  top: 14px;
  right: 12px;
  margin-right: 0;
  width: 32px;
}

.headers3-header-right .toggler-button .nav-toggler.open span:nth-child(1) {
  top: 4px;
  transform: rotate(135deg);
}

.headers3-header-right .toggler-button .nav-toggler.open span:nth-child(2) {
  opacity: 0;
}

.headers3-header-right .toggler-button .nav-toggler.open span:nth-child(3) {
  top: 4px;
  transform: rotate(-135deg);
}

/*** 
=====================================================
	Mainmenu Area style
=====================================================
***/
.main-menu {
  position: relative;
}

.main-menu .navbar-collapse {
  padding: 0px;
}

.main-menu .navigation {
  position: relative;
  float: none;
  margin: 0;
}

.main-menu .navigation li {
  position: relative;
  float: none;
  display: inline-block;
  padding: 26.5px 20px 27.5px;
}

.main-menu .navigation li a {
  position: relative;
  color: #FFCF70;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  opacity: 1;
  padding: 0px;
  transition: all 300ms ease;
  font-family: 'Titillium Web', sans-serif;
}

.stricky-fixed .main-menu .navigation li a {
  color: #18391A;
}

.main-menu .navigation > li:hover > a,
.main-menu .navigation > li.current > a {
  color: #36813b;
}

.main-menu .navigation > li > ul {
  position: absolute;
  left: 0px;
  top: 130%;
  width: 250px;
  background: #ffffff;
  padding: 13px 25px 12px;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: all 500ms ease;
  display: block;
  border-radius: 0px 0px 10px 10px;
}

.main-menu .navigation > li:hover > ul {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.main-menu .navigation > li > ul > li {
  float: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  border: none;
  display: block;
  transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li:last-child {
  border-right: 0px dashed;
}

.main-menu .navigation li ul li a {
  color: #2f2f2f;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
  text-transform: uppercase;
  transition: all 500ms ease 0s;
  padding: 9px 0px 7px;
  font-family: 'Rubik', sans-serif;
}

.main-menu .navigation > li > ul > li:hover > a {
  color: #36813b;
}

.main-menu .navbar-collapse > ul li.dropdown .dropdown-btn {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 34px;
  height: 30px;
  border: 1px solid #ffffff;
  background: url(../images/icon/submenu-icon.png) center center no-repeat;
  background-size: 20px;
  cursor: pointer;
  z-index: 5;
  display: none;
  border-radius: 3px;
}

/*** 
=====================================================
	Mainmenu Area Style2 style
=====================================================
***/
.mainmenu-area.style2 {
  position: relative;
  display: block;
  background: transparent;
  margin-bottom: -60px;
  z-index: 100;
}

.mainmenu-area.style2 .inner-content {
  position: relative;
  display: block;
  padding-left: 35px;
  background: #f5f5f5;
  border-radius: 0 0px 15px 15px;
}

.mainmenu-area.style2 .main-menu {
  position: relative;
  display: inline-block;
  float: left;
}

.mainmenu-area.style2 .main-menu .navigation li {
  position: relative;
  display: inline-block;
  float: left;
  padding: 0;
  margin-right: 30px;
}

.mainmenu-area.style2 .main-menu .navigation li:last-child {
  margin-right: 0;
}

.mainmenu-area.style2 .main-menu .navigation li a {
  position: relative;
  display: block;
  padding: 22px 5px;
  color: #2f2f2f;
}

.mainmenu-area.style2 .main-menu .navigation > li:hover > a,
.mainmenu-area.style2 .main-menu .navigation > li.current > a {
  color: #f3441e;
}

.mainmenu-area.style2 .main-menu .navigation li a:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 2px;
  background: #f3441e;
  content: "";
  transition: all 700ms ease;
}

.mainmenu-area.style2 .main-menu .navigation li.current a:before,
.mainmenu-area.style2 .main-menu .navigation li:hover a:before {
  width: 100%;
}

.mainmenu-area.style2 .main-menu .navigation li ul li {
  float: none;
  margin: 0;
  display: block;
}

.mainmenu-area.style2 .main-menu .navigation li ul li a {
  padding: 9px 0px 7px;
  color: #2f2f2f;
}

.mainmenu-area.style2 .main-menu .navigation li ul li:hover a {
  color: #f3441e;
}

.mainmenu-area.style2 .main-menu .navigation li ul li a:before {
  display: none;
}

.outer-search-box {
  display: inline-block;
  float: left;
  padding-left: 20px;
  margin: 22px 0;
}

.outer-search-box .seach-toggle {
  color: #2f2f2f;
  cursor: pointer;
  font-size: 16px;
  position: relative;
  text-align: center;
  transition: all 500ms ease;
  height: 16px;
  display: block;
  width: 25px;
  line-height: 14px;
}

.outer-search-box .seach-toggle:hover,
.outer-search-box .seach-toggle.active {
  color: #f3441e;
}

.search-box {
  background: #2f2f2f;
  opacity: 0;
  padding: 20px;
  position: absolute;
  top: 120%;
  transition: all 500ms ease 0s;
  visibility: hidden;
  z-index: 100;
}

.search-box.now-visible {
  top: 100%;
  opacity: 1;
  visibility: visible;
  z-index: 100;
}

.search-box .form-group {
  position: relative;
  padding: 0px;
  margin: 0px;
  width: 100%;
  min-width: 250px;
}

.search-box .form-group input[type="search"] {
  background: #ffffff none repeat scroll 0 0;
  border: 1px solid #1d1d1d;
  color: #000;
  display: block;
  font-size: 13px;
  line-height: 24px;
  position: relative;
  transition: all 500ms ease 0s;
  width: 100%;
  height: 40px;
  padding-left: 15px;
  padding-right: 50px;
}

.search-box .form-group input[type="search"]:focus {
  border-color: #000000;
}

.search-box .form-group button,
.search-box .form-group input[type="submit"] {
  background: #1d1d1d;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  transition: all 500ms ease 0s;
}

.search-box .form-group button:hover,
.search-box .form-group input:focus + button {
  color: #fff;
  background: #000;
}

.mainmenu-area.style2 .button {
  float: right;
}

.mainmenu-area.style2 .button a {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  display: block;
  background: #f3441e;
  padding: 18px 40px;
  text-transform: uppercase;
  font-family: 'Titillium Web', sans-serif;
  border-radius: 0 0px 15px 0px;
  transition: all 500ms ease;
}

.mainmenu-area.style2 .button a span:before {
  position: relative;
  top: 1px;
  font-size: 20px;
  line-height: 20px;
  display: inline-block;
  padding-right: 15px;
  font-weight: 400;
}

.mainmenu-area.style2 .button a:hover {
  background: #151515;
}

/*** 
=====================================================
	Mainmenu Area Style3 style
=====================================================
***/
.mainmenu-area.style3 .logo {
  margin: 24px 0 25px;
  margin-right: 20px;
}

.mainmenu-area.style3 .main-menu .navigation li {
  position: relative;
  padding: 36.5px 16px 37.5px;
}

.mainmenu-area.style3 .main-menu .navigation > li > ul > li {
  padding: 0;
}

.mainmenu-area.style3 .outer-search-box {
  padding-left: 20px;
  margin: 37px 0;
}

.cart-toggler-option {
  position: relative;
  display: block;
  width: 150px;
}

.cart-toggler-option .cart-button a {
  position: relative;
  display: block;
  float: left;
  background: #3aabd0;
  width: 75px;
  height: 90px;
  color: #ffffff;
  font-size: 25px;
  text-align: center;
  line-height: 90px;
}

.cart-toggler-option .toggler-button .nav-toggler {
  position: relative;
  display: block;
  float: left;
  background: #3fb8df;
  width: 75px;
  height: 90px;
  color: #ffffff;
  text-align: center;
  line-height: 90px;
  cursor: pointer;
}

.cart-toggler-option .toggler-button .nav-toggler .inner {
  display: block;
  position: relative;
  margin: 37px 0 53px;
}

.cart-toggler-option .toggler-button .nav-toggler span {
  display: block;
  position: absolute;
  height: 2px;
  width: 32px;
  background: #ffffff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.cart-toggler-option .toggler-button .nav-toggler span:nth-child(2) {
  top: 7px;
  width: 32px;
}

.cart-toggler-option .toggler-button .nav-toggler span:nth-child(3) {
  top: 14px;
  right: 21px;
  margin-right: 0;
  width: 32px;
}

.cart-toggler-option .toggler-button .nav-toggler.open span:nth-child(1) {
  top: 4px;
  transform: rotate(135deg);
}

.cart-toggler-option .toggler-button .nav-toggler.open span:nth-child(2) {
  opacity: 0;
}

.cart-toggler-option .toggler-button .nav-toggler.open span:nth-child(3) {
  top: 4px;
  transform: rotate(-135deg);
}

/*** 
============================================
	Hidden Sidebar style
============================================
***/
.hidden-bar {
  position: fixed;
  top: 0;
  width: 320px;
  height: 100%;
  background: #111111;
  z-index: 9999999999;
  transition: all 700ms ease;
}

.hidden-bar .mCSB_inside > .mCSB_container {
  margin-right: 0px;
}

.hidden-bar.right-align {
  right: -400px;
}

.hidden-bar.right-align.visible-sidebar {
  right: 0px;
  transition-delay: 300ms;
}

.hidden-bar .hidden-bar-closer {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 20px;
  top: 10px;
  background: none;
  color: #ffffff;
  border-radius: 0px;
  text-align: center;
  line-height: 40px;
  transition: all 300ms ease;
  z-index: 999999;
}

.hidden-bar .hidden-bar-closer button {
  background: none;
  display: block;
  font-size: 40px;
  color: #ffffff;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.hidden-bar .hidden-bar-closer button span:before {
  font-size: 30px;
  line-height: 30px;
}

.hidden-bar .hidden-bar-closer:hover button {
  color: #ffffff;
}

.hidden-bar-wrapper {
  height: 100%;
  padding: 30px 5px 30px 30px;
}

.hidden-bar .logo {
  padding: 0px 0px 30px;
}

.hidden-bar .logo img {
  display: inline-block;
  max-width: 100%;
}

.hidden-bar .content-box {
  position: relative;
  padding-right: 32px;
}

.hidden-bar .content-box a {
  padding: 20px 40px 20px;
}

.hidden-bar .content-box h2 {
  position: relative;
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 20px;
}

.hidden-bar .content-box .text {
  position: relative;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 25px;
}

.hidden-bar .contact-info {
  position: relative;
  margin-top: 60px;
}

.hidden-bar .contact-info h2 {
  position: relative;
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 20px;
}

.hidden-bar .contact-info li {
  position: relative;
  margin-bottom: 12px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  font-weight: 400;
  padding-left: 30px;
  line-height: 2em;
}

.hidden-bar .contact-info li a {
  color: rgba(255, 255, 255, 0.9);
  transition: all 300ms ease;
}

.hidden-bar .contact-info li a:hover {
  color: #ffffff;
}

.hidden-bar .contact-info li .icon {
  position: absolute;
  left: 0px;
  top: 4px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  line-height: 1.2em;
}

/*** 
=====================================================
	Mainmenu Area style4
=====================================================
***/
.mainmenu-area.style4 {
  position: relative;
  display: block;
  background: #ffffff;
  margin: 0px;
  z-index: 100;
}

.mainmenu-area.style4 .main-menu {
  position: relative;
  display: inline-block;
  float: left;
}

.mainmenu-area.style4 .main-menu .navigation li {
  position: relative;
  float: none;
  display: inline-block;
  padding: 21.5px 0px 22.5px;
  margin-right: 40px;
}

.mainmenu-area.style4 .main-menu .navigation li:last-child {
  margin: 0;
}

.mainmenu-area.style4 .main-menu .navigation li a {
  color: #333333;
}

.mainmenu-area.style4 .main-menu .navigation li:hover > a,
.mainmenu-area.style4 .main-menu .navigation li.current > a {
  color: #f3441e;
}

.mainmenu-area.style4 .main-menu .navigation > li > ul > li {
  margin: 0;
  padding: 0;
}

.mainmenu-area.style4 .outer-search-box {
  display: inline-block;
  float: left;
  padding-left: 40px;
  margin: 22px 0;
}

.mainmenu-area.style4 .help-line {
  float: right;
  display: block;
  margin: 18px 0;
}

.mainmenu-area.style4 .help-line {
  float: right;
  display: block;
}

.mainmenu-area.style4 .help-line h3 {
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}

.mainmenu-area.style4 .help-line h3 span {
  position: relative;
  top: -2px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: #f3441e;
  text-transform: uppercase;
  font-family: 'Titillium Web', sans-serif;
}

/*** 
=====================================================
	Main Slider style
=====================================================
***/
.main-slider {
  position: relative;
  z-index: 10;
}

.main-slider .tp-caption {
  z-index: 5;
}

.main-slider .tp-dottedoverlay {
  background: none !important;
}

.main-slider .slide-content {
  position: relative;
  display: block;
}

.main-slider .slide-content .big-title {
  position: relative;
  font-size: 48px;
  color: #18391A;
  font-weight: 400;
  text-transform: none;
  line-height: 1.2em;
  font-family: 'Titillium Web', sans-serif;
}

.main-slider .slide-content .big-title span {
  font-weight: 600;
}

.main-slider .slide-content .text {
  position: relative;
  font-size: 18px;
  font-weight: 400;
  color: #36813b;
  line-height: 1.6em;
  display: block;
  font-family: 'Rubik', sans-serif;
}

.main-slider .slide-content .btn-box a.sb1 {
  background: transparent;
  border: 1px solid #36813b;
  padding: 20px 22px 22px;
  color: #18391A;
}

.main-slider .slide-content .btn-box a.sb1:hover {
  background: #36813b;
  border-color: #36813b;
}

.main-slider .slide-content .btn-box a.sb1 span:before {
  position: relative;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  padding-left: 15px;
  top: 1px;
}

.main-slider #rev_slider_two_wrapper .slide-content .big-title {
  color: #2f2f2f;
}

.main-slider #rev_slider_two_wrapper .slide-content .big-title.clr-white {
  color: #ffffff;
}

.main-slider #rev_slider_two_wrapper .slide-content .text {
  color: #848484;
}

.main-slider #rev_slider_two_wrapper .slide-content .text.clr-white {
  color: #ffffff;
}

.main-slider #rev_slider_two_wrapper .slide-content .btn-box a.sb1 {
  background: #f3441e;
  border: 1px solid #f3441e;
  padding: 20px 18px 22px;
  color: #ffffff;
  border-radius: 3px;
}

.main-slider #rev_slider_two_wrapper .slide-content .btn-box a.sb1:hover {
  background: #d52a05;
}

.main-slider #rev_slider_three_wrapper .slide-content .big-title {
  color: #2f2f2f;
}

.main-slider #rev_slider_three_wrapper .slide-content .text {
  color: #848484;
}

.main-slider #rev_slider_three_wrapper .slide-content .btn-box a.sb1 {
  background: #3aabd0;
  border: 1px solid #3aabd0;
  padding: 20px 25px 22px;
  color: #ffffff;
  border-radius: 30px;
}

.main-slider #rev_slider_three_wrapper .slide-content .btn-box a.sb1:hover {
  background: #0680a8;
}

/*** 
=============================================
    Call To Action Area style      
=============================================
***/
.call-to-action-area {
  position: relative;
  display: block;
  background: #18391A;
  padding-bottom: 70px;
  z-index: 100;
}

.callto-action {
  position: relative;
  display: block;
}

.callto-action ul {
  display: block;
  width: 100%;
}

.callto-action ul .single-box {
  position: relative;
  display: block;
  width: 50%;
  float: left;
  text-align: center;
}

.callto-action ul .single-box:last-child {
  border: none;
}

.callto-action ul .single-box .icon-holder {
  position: absolute;
  top: -80px;
  left: 0px;
  right: 0;
  margin: 0 auto;
  display: block;
  height: 165px;
  width: 165px;
  background: transparent;
  border-radius: 15px;
  transform: rotate(0deg);
  z-index: 1;
}

.callto-action ul .single-box .icon-holder:before {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0;
  margin: 0 auto;
  display: block;
  height: 165px;
  width: 165px;
  background: #18391A;
  border-radius: 15px;
  transform: rotate(45deg);
  content: "";
  z-index: -1;
  transition: all 500ms ease;
}

.callto-action ul .single-box:hover .icon-holder:before {
  transform: rotate(0deg);
}

.callto-action ul .single-box .icon-holder span:before {
  color: #36813b;
  font-size: 65px;
  line-height: 65px;
  display: block;
  padding: 50px 0;
  z-index: 1;
}

.callto-action ul .single-box .inner {
  position: relative;
  display: block;
  margin-top: 70px;
  padding: 0 30px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 2;
}

.callto-action ul .single-box:last-child .inner {
  border: none;
}

.callto-action ul .single-box .inner h3 {
  color: #ffffff;
  display: block;
  margin: 0 0 20px;
}

.callto-action ul .single-box .inner p {
  color: #a8dbab;
  margin: 0 0 26px;
}

.callto-action ul .single-box .inner a {
  display: block;
  transition: all 500ms ease;
}

.callto-action ul .single-box:hover .inner a {
  color: #36813b;
}

/*** 
=============================================
    About V1 Area Style      
=============================================
***/
.about-v1-area {
  background-attachment: scroll;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 90px;
  padding-bottom: 60px;
  position: relative;
  z-index: 1;
}

.about-left-content .sec-title {
  display: block;
  padding-bottom: 45px;
}

.about-left-content .inner-content h2 {
  color: #18391A;
  font-size: 26px;
  text-transform: uppercase;
  line-height: 34px;
  margin: 0 0 17px;
}

.about-left-content .inner-content p {
  margin: 0 0 43px;
}

.about-left-content .inner-content .certificate {
  position: relative;
  display: block;
}

.about-left-content .inner-content .certificate ul {
  display: block;
  overflow: hidden;
}

.about-left-content .inner-content .certificate ul li {
  display: inline-block;
  float: left;
  margin-right: 30px;
}

.about-left-content .inner-content .certificate ul li:last-child {
  margin-right: 0;
}

.about-left-content .inner-content .certificate ul li img {
  width: auto;
}

.about-right-content {
  position: relative;
  display: block;
}

.about-right-content .single-box {
  display: block;
  text-align: center;
}

.about-right-content .single-box.left {
  margin: 50px 0;
}

.certificate a.btn-one {
  background-color: #18391A;
  width: 300px;
  margin-top: 2em;
}

.certificate a.btn-one:hover {
  background-color: #FFCF70;
  color: #18391A;
}

.btn-one .flaticon-next {
  margin-left: 1em;
}

.about-right-content .single-box img {
  padding: 5px;
  border: 1px solid #e0e0e0;
  display: block;
  margin: 0 auto;
  box-shadow: 0px 0px 6px rgba(110, 110, 110, 0.2);
}

/*** 
=============================================
    About V1 Area Style2 Style      
=============================================
***/
.about-v1-area.style2 {
  position: relative;
  display: block;
}

.about-v1-area.style2 .sec-title .title.clr2 {
  color: #ffffff;
}

.about-v1-area.style2 .about-left-content .inner-content h2 {
  color: #ffffff;
}

.about-v1-area.style2 .about-right-content .single-box {
  background: rgba(0, 0, 0, 0.6);
}

.about-v1-area.style2 .about-right-content .single-box .icon-holder span:before {
  color: #f3441e;
}

/*** 
=============================================
    Services Style1 Area Style      
=============================================
***/
.services-style1-area {
  position: relative;
  display: block;
  padding-top: 90px;
  padding-bottom: 90px;
}

.services-style1-area .sec-title {
  position: relative;
  max-width: 870px;
  margin: -5px auto 0;
  padding-bottom: 53px;
}

.services-style1-area .sec-title p {
  margin: 22px 0 0;
  font-size: 18px;
  line-height: 26px;
}

.single-service-style1 {
  position: relative;
  display: block;
  border: 1px solid #eef3f7;
  min-height: 225px;
  border-radius: 10px;
  padding: 23px 25px 0px;
  margin-bottom: 40px;
  transition: all 500ms ease;
}

.single-service-style1:hover {
  background: #18391A;
  border-color: #18391A;
}

.single-service-style1 .icon-holder {
  display: block;
  margin-bottom: 25px;
}

.single-service-style1 .icon-holder span:before {
  color: #36813b;
  font-size: 50px;
  line-height: 50px;
  transition: all 700ms ease;
}

.single-service-style1:hover .icon-holder span:before {
  color: #ffffff;
}

.single-service-style1 .text-holder h3 {
  color: #18391A;
  margin: 0 0 20px;
  transition: all 500ms ease;
}

.single-service-style1:hover .text-holder h3 {
  color: #ffffff;
}

.single-service-style1 .text-holder p {
  margin: 0;
  transition: all 500ms ease;
}

.single-service-style1:hover .text-holder p {
  color: #a8dbab;
}

/*** 
=============================================
    Services style2 Area Style      
=============================================
***/
.services-style2-area {
  background-attachment: fixed;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 90px;
  padding-bottom: 179px;
  position: relative;
  z-index: 1;
}

.services-style2-area::before {
  background: rgba(0, 0, 0, 0.9) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.services-style2-area .sec-title {
  position: relative;
  max-width: 870px;
  margin: -5px auto 0;
  padding-bottom: 53px;
}

.services-style2-area .sec-title .title {
  color: #ffffff;
}

.services-style2-area .sec-title p {
  color: #7e839a;
  font-size: 18px;
  line-height: 26px;
  margin: 22px 0 0;
}

.single-service-style2 {
  position: relative;
  display: block;
  transition: all 700ms ease;
}

.single-service-item:hover {
  transform: scale(1, 1.1);
  z-index: 999;
}

.single-service-style2 .icon-holder {
  position: relative;
  display: block;
  padding-bottom: 50px;
  transition: all 300ms ease;
}

.single-service-style2 .icon-holder span:before {
  color: #797e94;
  font-size: 130px;
  line-height: 130px;
}

.single-service-style2 .title-holder {
  display: block;
  border-top: 1px solid #1c1c1c;
  padding-top: 23px;
}

.single-service-style2 .title-holder h3 {
  color: #ffffff;
  font-size: 24px;
  line-height: 30px;
}

.single-service-style2 .title-holder h3 a {
  color: #ffffff;
  transition: all 500ms ease;
}

.single-service-style2:hover .title-holder h3 a {
  color: #f3441e;
}

.single-service-style2 .overlay-content {
  position: absolute;
  top: -199px;
  left: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: all 500ms ease;
  transform: scale(0);
  overflow: hidden;
  min-height: 380px;
  z-index: 2;
  opacity: 0;
}

.single-service-style2:hover .overlay-content {
  transform: scale(1);
  opacity: 1;
}

.single-service-style2 .overlay-content .box {
  display: table;
  height: 100%;
  width: 100%;
}

.single-service-style2 .overlay-content .box .content {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.single-service-style2 .overlay-content .box .content .inner-content {
  position: relative;
  display: block;
}

.single-service-style2 .overlay-content .box .content .inner-content .img-holder img {
  border-radius: 20px 20px 0px 0px;
}

.single-service-style2 .overlay-content .box .content .inner-content .text-holder {
  position: relative;
  display: block;
  padding: 35px 30px 38px;
  background: #ffffff;
}

.single-service-style2 .overlay-content .box .content .inner-content .text-holder p {
  font-size: 16px;
  color: #848484;
  line-height: 24px;
  font-weight: 400;
  margin: 0 0 18px;
  font-family: 'Rubik', sans-serif;
}

.single-service-style2 .overlay-content .box .content .inner-content .text-holder a.info {
  color: #2f2f2f;
}

.single-service-style2 .overlay-content .box .content .inner-content .text-holder a.info span:before {
  position: relative;
  top: 1px;
  color: #2f2f2f;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  padding-left: 9px;
}

/*** 
=============================================
    Appointment Style1 Area Style
=============================================
***/
.appointment-style1-area {
  position: relative;
  display: block;
  background-attachment: scroll;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 35px 0 86px;
  z-index: 1;
}

.appoinment {
  display: block;
  background: #ffffff;
  border-radius: 20px;
}

.appoinment .title {
  display: block;
  padding: 15.5px 0;
  background: #36813b;
  text-align: center;
  margin-bottom: 36px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.appoinment .title h3 {
  color: #ffffff;
}

.appoinment .text {
  display: block;
  padding: 0 30px;
  margin-bottom: 24px;
}

.appoinment .text p {
  margin: 0;
}

.appoinment .appoinment-form {
  display: block;
  padding: 0 30px 60px;
}

.appoinment .appoinment-form .inner-title {
  overflow: hidden;
  display: block;
  margin: 0 0 5px;
  width: 100%;
  padding-left: 15px;
}

.appoinment .appoinment-form .inner-title.bottom {
  margin-top: 3px;
}

.appoinment .appoinment-form .inner-title h3 {
  color: #36813b;
  font-size: 16px;
  font-weight: 600;
}

.appoinment .appoinment-form .margin {
  margin-left: -10px;
  margin-right: -10px;
}

.appoinment .appoinment-form .margin .pd {
  padding-left: 10px;
  padding-right: 10px;
}

.appoinment .appoinment-form .input-box {
  position: relative;
  margin-bottom: 10px;
}

.appoinment .appoinment-form .input-box .icon-box {
  position: absolute;
  right: 19px;
  top: 12px;
}

.appoinment .appoinment-form .input-box .icon-box i {
  color: #848484;
  font-size: 14px;
}

.appoinment .appoinment-form .input-box input[type=text],
.appoinment .appoinment-form .input-box textarea {
  width: 100%;
  border: 1px solid #45a54b;
  height: 45px;
  display: block;
  padding: 0 20px;
  color: #848484;
  font-size: 16px;
  font-weight: 400;
  border-radius: 10px;
  font-family: 'Rubik', sans-serif;
  transition: all 500ms ease;
}

.appoinment .appoinment-form .input-box textarea {
  height: 150px;
  resize: none;
}

.appoinment .appoinment-form button {
  display: block;
  background: #36813b;
  margin-top: 3px;
  width: 100%;
}

.appoinment .appoinment-form button:hover {
  background: black;
}

.appoinment .appoinment-form button span:before {
  position: relative;
  top: 1px;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  padding-left: 9px;
  font-weight: 600;
}

.appoinment .appoinment-form .input-box input[type=text]:focus {
  border-color: #36813b;
}

#ui-datepicker-div.ui-widget-content {
  border: 1px solid #c5c5c5;
  background: #fff none repeat scroll 0 0;
  border: 1px solid #777;
  color: #252525;
  font-family: "Poppins",sans-serif;
  font-size: 14px;
  border-radius: 0px;
  width: 270px;
  padding: 5px;
}

#ui-datepicker-div.ui-datepicker .ui-datepicker-header {
  background: #151515 none repeat scroll 0 0;
  border: 2px solid #252525;
  border-radius: 0;
  color: #fff;
  font-weight: 700;
  padding: 5px 0;
  position: relative;
}

.ui-datepicker td a {
  text-align: center;
}

.ui-datepicker .ui-datepicker-prev {
  left: 5px;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  border-radius: 30%;
  height: 20px;
  position: absolute;
  top: 7px;
  width: 20px;
  transition: all 500ms ease;
  cursor: pointer;
}

.ui-datepicker .ui-datepicker-next {
  right: 5px;
}

.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
  top: 7px;
  background: #fff;
  border: none;
}

.ui-datepicker table {
  border-collapse: collapse;
  font-size: 13px;
  margin: 0 0 0.4em;
  width: 100%;
}

.ui-datepicker th {
  border: medium none;
  font-weight: 600;
  padding: 2px 3px;
  text-align: center;
}

.ui-datepicker-calendar .ui-state-default:hover,
.ui-datepicker-calendar .ui-state-default:active {
  background: #FFCF70;
  color: #fff;
  border-color: #FFCF70;
}

.appointment-right-style1 {
  position: relative;
  display: block;
  padding: 42px 38px 41px;
  max-width: 370px;
  border: 2px solid #FFCF70;
  border-radius: 20px;
  margin-top: 258px;
  margin-left: 100px;
}

.appointment-right-style1 .top {
  position: relative;
  overflow: hidden;
  display: block;
  padding-left: 70px;
  border-bottom: 1px solid #FFCF70;
  padding-bottom: 24px;
  margin-bottom: 20px;
}

.appointment-right-style1 .top .icon {
  position: absolute;
  top: 6px;
  left: 0;
}

.appointment-right-style1 .top .icon span:before {
  color: #36813b;
  font-size: 50px;
  line-height: 50px;
}

.appointment-right-style1 .top .title {
  display: block;
}

.appointment-right-style1 .top .title h2 {
  font-size: 24px;
  color: #ffffff;
  margin: 0 0 10px;
}

.appointment-right-style1 .top .title span {
  color: #a8dbab;
}

.appointment-right-style1 .text {
  overflow: hidden;
  display: block;
}

.appointment-right-style1 .text h1 {
  color: #36813b;
  font-size: 30px;
  display: block;
  margin: 0 0 13px;
}

.appointment-right-style1 .text p {
  color: #a8dbab;
  margin: 0;
}

.appointment-right-style1 .button {
  display: block;
  overflow: hidden;
  margin-top: 26px;
}

/*** 
=============================================
    Appointment Style2 Area Style
=============================================
***/
.appointment-style2-area {
  background-attachment: scroll;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 90px;
  padding-bottom: 90px;
  position: relative;
  z-index: 1;
}

.appointment-style2-area .appoinment {
  position: relative;
  display: block;
  z-index: 1;
  background: #ffffff;
  border-radius: 0;
}

.appointment-style2-area .appoinment:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  border-bottom: 600px solid #f5f5f5;
  border-left: 570px solid transparent;
  background-size: 100% 100%;
}

.appointment-style2-area .appoinment .appoinment-form .inner-title h3 {
  color: #f3441e;
}

.appointment-style2-area .appoinment .appoinment-form #ptTimeSelectCntr .ui-widget-header {
  background: #f3441e;
}

.appointment-style2-area .appoinment .appoinment-form .input-box input[type=text] {
  border-radius: 0;
}

.appointment-style2-area .appoinment .appoinment-form .input-box input[type=text]:focus {
  border-color: #f3441e;
}

.appointment-style2-area .appoinment .title {
  background: #f3441e;
  border-radius: 0;
}

.appointment-style2-area .appoinment .appoinment-form button {
  display: block;
  background: #f3441e;
  border-radius: 0;
}

.appointment-style2-area .appoinment .appoinment-form button:hover {
  background: #151515;
}

.appointment-right-style2 {
  position: relative;
  display: block;
  margin-top: 173px;
  max-width: 570px;
  width: 100%;
}

.appointment-right-style2 .title {
  position: relative;
  display: block;
  margin: 0 0 29px;
}

.appointment-right-style2 .title h2 {
  color: #2f2f2f;
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 0 4px;
}

.appointment-right-style2 .title h1 {
  color: #2f2f2f;
  font-size: 36px;
  line-height: 40px;
  font-weight: 400;
}

.appointment-right-style2 .since-working {
  position: relative;
  display: block;
  z-index: 1;
}

.appointment-right-style2 .since-working:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 170px;
  height: 1px;
  background: #dadada;
  content: "";
  z-index: -1;
  bottom: 0;
  margin: 13px 0;
}

.appointment-right-style2 .since-working:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 170px;
  height: 1px;
  background: #dadada;
  content: "";
  z-index: -1;
  bottom: 0;
  margin: 13px 0;
}

.appointment-right-style2 .since-working h3 {
  position: relative;
  color: #f3441e;
  font-size: 24px;
  font-weight: 400;
  display: inline-block;
  padding: 0 15px;
}

.appointment-right-style2 .button {
  display: block;
  margin-top: 48px;
}

.appointment-right-style2 .button a {
  position: relative;
  display: inline-block;
  background: #f3441e;
  color: #ffffff;
  width: 180px;
  height: 60px;
  line-height: 60px;
  margin: 0 13px;
  border-radius: 4px;
}

.appointment-right-style2 .button a:hover {
  background: #ffffff;
  color: #151515;
}

.appointment-right-style2 .button a.btn-two span:before {
  font-weight: 500;
  padding-left: 19px;
}

.appointment-right-style2 .button a.white-bg {
  background: #ffffff;
  color: #2f2f2f;
}

.appointment-right-style2 .button a.white-bg:hover {
  background: #f3441e;
  color: #ffffff;
}

/*** 
=============================================
    Appointment Style3 Area Style
=============================================
***/
.appointment-style3-area {
  position: relative;
  display: block;
}

.appoinment-form3 {
  position: relative;
  display: block;
  overflow: hidden;
  padding-top: 90px;
  padding-bottom: 90px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

.appoinment-form3 .appoinment {
  max-width: 570px;
  float: right;
  margin-right: 65px;
  background: rgba(255, 255, 255, 0.93);
}

.appoinment-form3 .appoinment .title {
  background: #46afd1;
}

.appoinment-form3 .appoinment .appoinment-form button {
  background: #3aabd0;
}

.appoinment-form3 .appoinment .appoinment-form button:hover {
  background: #0c98c6;
}

.appointment-right-style3 {
  position: relative;
  display: block;
  overflow: hidden;
  background: #f5f5f5;
  min-height: 779px;
  margin-left: -30px;
  z-index: 1;
}

.appointment-right-style3:before {
  position: absolute;
  bottom: 110px;
  right: -190px;
  width: 490px;
  height: 568px;
  background: url(../images/icon/faq-icon.png);
  background-repeat: no-repeat;
  content: "";
  z-index: -1;
}

.appointment-right-style3 .inner-content {
  max-width: 470px;
  width: 100%;
  float: left;
  margin-left: 65px;
  padding-top: 90px;
}

.appointment-right-style3 .inner-content .more-answer {
  display: block;
  overflow: hidden;
  margin-top: 28px;
}

.appointment-right-style3 .inner-content .more-answer a {
  color: #3aabd0;
}

/*** 
=============================================
    Latest blog Area style
=============================================
***/
.latest-blog-area {
  background: #ffffff;
  position: relative;
  display: block;
  padding-top: 90px;
  padding-bottom: 30px;
}

.single-blog-post {
  position: relative;
  display: block;
  overflow: hidden;
  transition: all 0.5s ease-in-out 0.6s;
  margin-bottom: 60px;
}

.single-blog-post:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 100%;
  background: #ffffff;
  content: "";
  z-index: 2;
}

.single-blog-post .img-holder {
  display: block;
  overflow: hidden;
  position: relative;
}

.single-blog-post .img-holder img {
  transition: all 0.5s ease-in-out 0.6s;
  width: 100%;
}

.single-blog-post:hover .img-holder img {
  transform: scale(1.2, 1.2);
}

.single-blog-post .img-holder .date-box {
  position: absolute;
  bottom: 30px;
  left: 5px;
  min-width: 100px;
  height: 30px;
  background: #ffffff;
  text-align: center;
  z-index: 3;
  -moz-box-shadow: -2px 2px 7px #ededed;
  -webkit-box-shadow: -2px 2px 7px #ededed;
  box-shadow: -2px 2px 7px #ededed;
}

.single-blog-post .img-holder .date-box span {
  color: #2f2f2f;
  line-height: 30px;
}

.single-blog-post .overlay-style-one {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: auto;
  height: auto;
  opacity: 1;
  border: 1px solid #f2f2f2;
  transition: all 500ms ease;
  background: #ffffff;
  transform: translate3d(0px, 100%, 0px);
}

.single-blog-post:hover .overlay-style-one {
  transform: translate3d(0px, 0, 0px);
}

.single-blog-post .overlay-style-one .box .content {
  padding: 0 30px;
}

.single-blog-post .overlay-style-one .box .content p {
  margin: 0 0 31px;
}

.single-blog-post .overlay-style-one .box .content a.readmore {
  color: #18391A;
}

.single-blog-post .text-holder {
  display: block;
  transition: all 500ms ease;
}

.single-blog-post .text-holder .blog-title {
  display: block;
  line-height: 26px;
  font-size: 20px;
  padding: 18px 30px 0;
}

.single-blog-post .text-holder .blog-title a {
  color: #18391A;
  transition: all 500ms ease;
}

.single-blog-post .text-holder .blog-title a:hover {
  color: #35ac46;
}

.single-blog-post .text-holder .meta-box {
  display: block;
  overflow: hidden;
}

.single-blog-post .text-holder .meta-box .meta-info {
  overflow: hidden;
  display: block;
  padding-top: 9px;
  text-align: center;
}

.single-blog-post .text-holder .meta-box .meta-info li {
  display: inline-block;
  float: none;
  line-height: 20px;
}

.single-blog-post .text-holder .meta-box .meta-info li a {
  color: #848484;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  font-family: 'Rubik', sans-serif;
  transition: all 500ms ease;
}

.single-blog-post .text-holder .meta-box .meta-info li a span {
  color: #36813b;
}

/*** 
=============================================
    Latest blog area style2 style
=============================================
***/
.single-blog-post.style2 .text-holder .meta-box .meta-info li a span {
  color: #f3441e;
}

.single-blog-post.style2 .overlay-style-one .box .content a.readmore {
  color: #2f2f2f;
}

/*** 
=============================================
    Latest blog area style2 style
=============================================
***/
.latest-blog-area.style3 {
  position: relative;
  display: block;
  background: #f5f5f5;
  padding-bottom: 150px;
}

.single-blog-post-style3 {
  position: relative;
  border: 1px solid #e5e5e5;
  padding: 49px 30px 37px;
  transition: all 500ms ease;
}

.single-blog-post-style3:hover {
  background: #ffffff;
  border-color: #ffffff;
  -moz-box-shadow: 0px 2px 3px #e3e0e0;
  -webkit-box-shadow: 0px 2px 3px #e3e0e0;
  box-shadow: 0px 2px 3px #e3e0e0;
}

.single-blog-post-style3 .date-box {
  display: block;
  margin-bottom: 22px;
}

.single-blog-post-style3 .date-box span {
  display: inline-block;
  background: #3aabd0;
  color: #ffffff;
  font-size: 16px;
  padding: 4px 10px 2px;
}

.single-blog-post-style3 .text-holder h3 {
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  margin: 0 0 14px;
}

.single-blog-post-style3 .text-holder h3 a {
  color: #2f2f2f;
}

.single-blog-post-style3 .text-holder .meta-box ul li a {
  color: #848484;
  font-size: 16px;
}

.single-blog-post-style3 .text-holder .meta-box ul li a span {
  color: #3aabd0;
}

.latest-blog-area.style3 .owl-theme .owl-nav.disabled + .owl-dots {
  position: absolute;
  bottom: -72px;
  left: 0;
  right: 0;
  margin: 0;
  display: block;
}

.latest-blog-area.style3 .owl-dots .owl-dot {
  background-image: none;
  width: 10px;
  height: 10px;
  margin: 5px 4px;
  border: 2px solid #d0d0d0;
  padding: 0px;
  border-radius: 50%;
  background: #ffffff;
  transition: all 500ms ease;
  display: inline-block;
}

.latest-blog-area.style3 .owl-dots .owl-dot span {
  display: none;
}

.latest-blog-area.style3 .owl-dots .owl-dot.active {
  border-color: #3aabd0;
}

/*** 
==========================================================
	Testimonials area Style
==========================================================
***/
.testimonial-area {
  position: relative;
  display: block;
  background: #ffffff;
  padding-top: 90px;
  padding-bottom: 90px;
  z-index: 1;
}

.testimonial-area:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 449px;
  background: #eef3f7;
  content: "";
  z-index: -1;
}

.testimonial-area .carousel-outer {
  position: relative;
  overflow: hidden;
}

.testimonial-area .owl-item {
  opacity: 0.3;
}

.testimonial-area .owl-item.active {
  opacity: 1;
}

.testimonial-area .owl-carousel {
  max-width: 575px;
  margin: 0 auto;
  position: static;
}

.testimonial-area .owl-carousel .owl-stage-outer {
  overflow: visible;
}

.single-testimonial-style1 .img-holder {
  position: relative;
  display: block;
  overflow: hidden;
  text-align: center;
}

.single-testimonial-style1 .img-holder img {
  transition: all 500ms ease;
  width: auto;
  transform: scale(1, 1);
}

.single-testimonial-style1 .text-holder {
  position: relative;
  display: block;
  padding-top: 44px;
  -moz-box-shadow: 0px 1px 9px #f0f0f0;
  -webkit-box-shadow: 0px 1px 9px #f0f0f0;
  box-shadow: 0px 1px 9px #f0f0f0;
  transform: scale(0, 0);
  transition: all 500ms ease;
  padding: 44px 40px 43px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.testimonial-area .owl-item.center .text-holder {
  transform: scale(1, 1);
  transition: all 500ms ease;
}

.single-testimonial-style1 .text-holder .quote-icon {
  position: absolute;
  bottom: 41px;
  right: 50px;
}

.single-testimonial-style1 .text-holder .quote-icon span:before {
  color: #f4f8fb;
  font-size: 85px;
  line-height: 85px;
}

.single-testimonial-style1 .text-holder .text {
  display: block;
  margin-bottom: 27px;
}

.single-testimonial-style1 .text-holder .text p {
  color: #18391A;
  font-size: 22px;
  line-height: 30px;
  margin: 0;
}

.single-testimonial-style1 .text-holder h3 {
  color: #18391A;
  margin: 0 0 3px;
}

.single-testimonial-style1 .text-holder span {
  color: #36813b;
}

.testimonial-area .owl-theme .owl-nav {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  height: auto;
  margin: 0;
}

.testimonial-area .owl-theme .owl-nav .owl-prev {
  position: absolute;
  left: 100px;
  bottom: 0px;
}

.testimonial-area .owl-theme .owl-nav .owl-next {
  position: absolute;
  right: 100px;
  bottom: 0px;
}

.testimonial-area .owl-theme .owl-nav [class*="owl-"] {
  background: transparent;
  border-radius: 50%;
  color: #36813b;
  font-size: 30px;
  font-weight: 700;
  height: 60px;
  line-height: 56px;
  margin: 0 0 0 0px;
  padding: 0;
  width: 60px;
  border: 1px solid #eef3f7;
  transition: all 700ms ease 0s;
}

.testimonial-area .owl-theme .owl-nav [class*="owl-"]:hover {
  background: #eef3f7;
  border-color: #eef3f7;
  color: #000000;
}

/*** 
=============================================
    Brand Area Style      
=============================================
***/
.brand-area {
  position: relative;
  display: block;
}

.brand-area .brand {
  display: block;
  overflow: hidden;
  border-top: 1px solid #ddd;
  padding: 88px 0 44px;
}

.brand-area .brand li {
  display: inline-block;
  float: left;
  max-width: 290px;
  width: 100%;
  text-align: center;
  margin-bottom: 45px;
}

.brand-area .brand li a {
  display: block;
}

.brand-area .brand li a img {
  width: auto;
}

/*** 
=============================================
    Brand Area Style2      
=============================================
***/
.brand-area.style2 {
  padding: 90px 0 43px;
}

.brand-area.style2 .brand {
  border-top: none;
  padding: 0;
}

/*** 
=============================================
    Brand Area Style3     
=============================================
***/
.brand-area.style3 .brand {
  border: 0px solid;
  padding: 75px 0 30px;
}

.brand-area.style3 .brand li {
  max-width: 170px;
  width: 100%;
  margin-bottom: 45px;
  margin-right: 80px;
}

.brand-area.style3 .brand li:last-child {
  margin-right: 0;
}

/*** 
=============================================
    Footer area style
=============================================
***/
.footer-area {
  position: relative;
  display: block;
  background: #000000;
  padding-top: 88px;
  padding-bottom: 82px;
}

.single-footer-widget {
  overflow: hidden;
}

.single-footer-widget.margin-left {
  margin-left: 55px;
}

.single-footer-widget .title {
  position: relative;
  display: block;
  padding-left: 15px;
  margin-bottom: 30px;
}

.single-footer-widget .title:before {
  position: absolute;
  top: 2px;
  left: 0;
  width: 6px;
  height: 13px;
  background: #36813b;
  content: "";
}

.single-footer-widget .title h3 {
  color: #ffffff;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.state-select-box .state {
  display: none;
}

.state-select-box #value1 {
  display: block;
}

.state-content ul {
  display: block;
  margin-top: 25px;
}

.state-content ul li {
  position: relative;
  display: block;
  overflow: hidden;
  padding-left: 30px;
  margin-bottom: 20px;
  width: 100%;
}

.state-content ul li.inline {
  display: inline-block;
  float: left;
  margin-right: 15px;
  border-right: 1px solid #466489;
  padding-right: 15px;
  width: auto;
}

.state-content ul li.inline.last {
  margin-right: 0;
  padding-right: 0;
  border: none;
}

.state-content ul li .icon {
  position: absolute;
  top: 0;
  left: 0;
}

.state-content ul li .icon span:before {
  color: #36813b;
  font-size: 20px;
  line-height: 20px;
}

.state-content ul li .text p {
  color: #FFCF70;
  line-height: 22px;
  margin: 0;
}

.single-footer-widget .map-find {
  display: block;
  margin-top: 7px;
  border-top: 1px solid #193f6c;
  padding-top: 28px;
}

.single-footer-widget .quick-links {
  position: relative;
  display: block;
  margin-top: -5px;
}

.single-footer-widget .quick-links li {
  line-height: 38px;
}

.single-footer-widget .quick-links li a {
  color: #a8dbab;
  font-size: 16px;
  font-weight: 400;
}

.single-footer-widget .quick-links li a:hover {
  color: #ffffff;
}

.single-footer-widget .quick-links li a i {
  font-size: 18px;
  display: inline-block;
  padding-right: 19px;
}

.single-footer-widget .subscribe-form {
  display: block;
  overflow: hidden;
  margin-top: 32px;
}

.single-footer-widget .subscribe-form .text {
  display: block;
  margin-bottom: 14px;
}

.single-footer-widget .subscribe-form .text p {
  color: #ffffff;
  line-height: 26px;
  margin: 0;
}

.single-footer-widget .subscribe-form form {
  position: relative;
  width: 100%;
  max-width: 370px;
}

.single-footer-widget .subscribe-form form input[type=text] {
  background: transparent;
  border: none;
  border-bottom: 1px solid #193f6c;
  color: #a8dbab;
  display: block;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  height: 50px;
  padding-left: 0px;
  padding-right: 0px;
  position: relative;
  transition: all 500ms ease 0s;
  max-width: 100%;
  width: 100%;
  border-radius: 0px;
  font-family: 'Rubik', sans-serif;
}

.single-footer-widget .subscribe-form input::-webkit-input-placeholder {
  color: #a8dbab;
}

.single-footer-widget .subscribe-form input:-moz-placeholder {
  color: #a8dbab;
}

.single-footer-widget .subscribe-form input::-moz-placeholder {
  color: #a8dbab;
}

.single-footer-widget .subscribe-form input:-ms-input-placeholder {
  color: #a8dbab;
}

.single-footer-widget .subscribe-form form button {
  margin-top: 30px;
  background: #36813b;
  padding: 18px 40px 18px;
}

.single-footer-widget .subscribe-form form input[type=text]:focus {
  border-color: #ffffff;
  color: #ffffff;
}

.single-footer-widget .subscribe-form form input[type=text]:focus + button,
.single-footer-widget .subscribe-form form button:hover {
  background: #0591c4 none repeat scroll 0 0;
  color: #ffffff;
}

.single-footer-widget .footer-social-links {
  display: block;
  overflow: hidden;
  margin-top: 26px;
}

.single-footer-widget .footer-social-links ul {
  display: block;
  float: left;
  line-height: 16px;
}

.single-footer-widget .footer-social-links ul li {
  display: inline-block;
  float: left;
  margin-right: 13px;
}

.single-footer-widget .footer-social-links ul li:last-child {
  margin: 0;
}

.single-footer-widget .footer-social-links ul li a i {
  color: #a8dbab;
  font-size: 14px;
  transition: all 500ms ease;
}

.single-footer-widget .footer-social-links ul li a:hover i {
  color: #36813b;
}

/*** 
=============================================
    Footer area Style2 style
=============================================
***/
.footer-area.style2 {
  background: #151515;
}

.footer-area.style2 .single-footer-widget .title:before {
  background: #f3441e;
}

.footer-area.style2 .state-content ul li .icon span:before {
  color: #f3441e;
}

.footer-area.style2 .state-content ul li.inline {
  border-color: #505050;
}

.footer-area.style2 .state-content ul li .text p {
  color: #848484;
  transition: all 500ms ease;
}

.footer-area.style2 .state-content ul li:hover .text p {
  color: #ffffff;
}

.footer-area.style2 .single-footer-widget .quick-links li a {
  color: #848484;
}

.footer-area.style2 .single-footer-widget .quick-links li a:hover {
  color: #ffffff;
}

.footer-area.style2 .single-footer-widget .map-find {
  border-top: 1px solid #282828;
}

.footer-area.style2 .single-footer-widget .subscribe-form input::-webkit-input-placeholder {
  color: #848484;
}

.footer-area.style2 .single-footer-widget .subscribe-form input:-moz-placeholder {
  color: #848484;
}

.footer-area.style2 .single-footer-widget .subscribe-form input::-moz-placeholder {
  color: #848484;
}

.footer-area.style2 .single-footer-widget .subscribe-form input:-ms-input-placeholder {
  color: #848484;
}

.footer-area.style2 .single-footer-widget .subscribe-form form input[type=text] {
  border-bottom: 1px solid #282828;
}

.footer-area.style2 .single-footer-widget .footer-social-links ul li a i {
  color: #ffffff;
}

.footer-area.style2 .single-footer-widget .footer-social-links ul li a:hover i {
  color: #f3441e;
}

.footer-area.style2 .single-footer-widget .subscribe-form form button {
  background: #f3441e;
}

.footer-area.style2 .single-footer-widget .subscribe-form form input[type=text]:focus + button,
.footer-area.style2 .single-footer-widget .subscribe-form form button:hover {
  background: #cb2906 none repeat scroll 0 0;
  color: #ffffff;
}

.single-footer-widget.clrdeep .title:before {
  background: #f3441e;
}

.single-footer-widget.clrdeep .state-content ul li .icon span:before {
  color: #f3441e;
}

.single-footer-widget.clrdeep .subscribe-form form button {
  border-radius: 3px;
  background: #f3441e;
}

.footer-area.style2 .single-footer-widget.clrdeep .subscribe-form form button:hover {
  background: #e02e08;
}

.footer-bottom-area.style2 .copyright-text p a span {
  color: #f3441e;
}

/*** 
=============================================
    Footer area Style3 style
=============================================
***/
.footer-area.style3 {
  background: #151515;
}

.footer-area.style3 .single-footer-widget .title:before {
  background: #3aabd0;
}

.footer-area.style3 .state-content ul li.inline {
  border-color: #505050;
}

.footer-area.style3 .state-content ul li .icon span:before {
  color: #3aabd0;
}

.footer-area.style3 .state-content ul li .text p {
  color: #848484;
  transition: all 500ms ease;
}

.footer-area.style3 .state-content ul li:hover .text p {
  color: #ffffff;
}

.footer-area.style3 .single-footer-widget .quick-links li a {
  color: #848484;
}

.footer-area.style3 .single-footer-widget .quick-links li a:hover {
  color: #ffffff;
}

.footer-area.style3 .single-footer-widget .map-find {
  border-top: 1px solid #282828;
}

.footer-area.style3 .single-footer-widget .subscribe-form input::-webkit-input-placeholder {
  color: #848484;
}

.footer-area.style3 .single-footer-widget .subscribe-form input:-moz-placeholder {
  color: #848484;
}

.footer-area.style3 .single-footer-widget .subscribe-form input::-moz-placeholder {
  color: #848484;
}

.footer-area.style3 .single-footer-widget .subscribe-form input:-ms-input-placeholder {
  color: #848484;
}

.footer-area.style3 .single-footer-widget .subscribe-form form input[type=text] {
  border-bottom: 1px solid #282828;
}

.footer-area.style3 .single-footer-widget .footer-social-links ul li a i {
  color: #ffffff;
}

.footer-area.style3 .single-footer-widget .footer-social-links ul li a:hover i {
  color: #3399cc;
}

.footer-area.style3 .single-footer-widget .subscribe-form form button {
  background: #0c98c6;
}

.footer-area.style3 .single-footer-widget .subscribe-form form input[type=text]:focus + button,
.footer-area.style3 .single-footer-widget .subscribe-form form button:hover {
  background: #068cb8 none repeat scroll 0 0;
  color: #ffffff;
}

/*** 
=============================================
    Footer Bottom Area Style      
=============================================
***/
.footer-bottom-area {
  position: relative;
  display: block;
  background: #18391A;
  padding: 38px 0 34px;
  background-image: url(/assets/media/backgrounds/green-fibers.png);
}

.footer-logo {
  display: block;
}

.footer-logo a {
  display: inline-block;
}

.footer-bottom-area .copyright-text {
  margin: 25px 0 0;
}

.footer-bottom-area .copyright-text p {
  color: #a8dbab;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  line-height: 22px;
  font-family: 'Rubik', sans-serif;
}

.footer-bottom-area .copyright-text p a {
  font-weight: 500;
  color: #ffffff;
  transition: all 500ms ease;
}

.footer-bottom-area .copyright-text p a span {
  color: #36813b;
}

/*** 
=============================================
    Footer Bottom Area Style2 Style      
=============================================
***/
.footer-bottom-area.style2 {
  background: #151515;
  border-top: 1px solid #282828;
}

.footer-bottom-area .copyright-text p {
  color: #848484;
}

/*** 
=============================================
    Footer Bottom Area Style3 Style      
=============================================
***/
.footer-bottom-area.style3 {
  background: #151515;
  border-top: 1px solid #282828;
}

.footer-bottom-area.style3 .copyright-text p {
  color: #848484;
}

.footer-bottom-area.style3 .copyright-text p span {
  color: #3aabd0;
}

/*** 
=============================================
    Why Choose Area Style2 Style      
=============================================
***/
.why-choose-area {
  position: relative;
  display: block;
  background: #f5f5f5;
}

.why-choose {
  position: relative;
  margin-top: -90px;
  z-index: 10;
}

.why-choose .title {
  position: relative;
  display: block;
  overflow: hidden;
  float: left;
  width: 290px;
  height: 300px;
  z-index: 1;
  padding: 130px 0;
}

.why-choose .title:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 290px;
  height: 300px;
  background: url(../images/pattern/choose-title.jpg);
  content: "";
  z-index: -1;
}

.why-choose .title h1 {
  color: #ffffff;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
}

.why-choose .content-box {
  position: relative;
  display: block;
  overflow: hidden;
  float: left;
  max-width: 880px;
  width: 100%;
  background: #ffffff;
  min-height: 300px;
}

.why-choose .content-box ul {
  display: block;
  float: left;
  max-width: 440px;
  width: 100%;
  margin: 45px 0px 0px;
  padding-left: 50px;
}

.why-choose .content-box ul.bdr-right {
  position: relative;
  display: block;
}

.why-choose .content-box ul.bdr-right:before {
  position: absolute;
  top: 5px;
  right: 0;
  width: 1px;
  bottom: 7px;
  background: #f2f2f2;
  content: "";
}

.why-choose .content-box ul.pd {
  position: relative;
  display: block;
  padding-left: 50px;
}

.why-choose .content-box ul.pd li {
  padding-right: 50px;
}

.why-choose .content-box ul li {
  position: relative;
  display: block;
  padding-left: 70px;
  margin-bottom: 33px;
  padding-right: 50px;
}

.why-choose .content-box ul li:last-child {
  margin-bottom: 0;
}

.why-choose .content-box ul li .iocn-holder {
  position: absolute;
  top: 12px;
  left: 0;
}

.why-choose .content-box ul li .text-holder {
  display: block;
  padding-left: 20px;
}

.why-choose .content-box ul li .text-holder h3 {
  color: #2f2f2f;
  margin: 0 0 15px;
}

.why-choose .content-box ul li .text-holder p {
  margin: 0;
}

/*** 
=============================================
    Services Process Area Style2 Style      
=============================================
***/
.services-process-area {
  position: relative;
  display: block;
  background: #f5f5f5;
  padding-top: 90px;
  padding-bottom: 83px;
}

.service-process-content .single-box {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}

.single-service-process {
  position: relative;
  display: block;
  max-width: 710px;
  width: 100%;
  padding-bottom: 90px;
}

.single-service-process:before {
  position: absolute;
  bottom: 6px;
  right: 118.5px;
  width: 5px;
  height: 65px;
  background: url(../images/icon/dotted.png);
  background-repeat: no-repeat;
  content: "";
  z-index: 1;
}

.single-service-process.right:before {
  left: 128.5px;
}

.single-service-process.last-child {
  padding-bottom: 15px;
}

.single-service-process.last-child:before {
  display: none;
}

.single-service-process .inner-content {
  display: block;
  max-width: 470px;
}

.single-service-process .inner-content.right {
  padding-left: 10px;
}

.single-service-process .inner-content h3 {
  margin: 0 0 25px;
}

.single-service-process .inner-content h3 a {
  color: #2f2f2f;
  transition: all 500ms ease;
}

.single-service-process:hover .inner-content h3 a {
  color: #f3441e;
}

.single-service-process .inner-content p {
  margin: 0;
}

.single-service-process .icon-holder {
  position: absolute;
  top: 5px;
  right: 0;
  width: 170px;
  min-height: 100px;
}

.single-service-process .icon-holder:before {
  position: absolute;
  top: 13px;
  right: 0;
  width: 64px;
  height: 64px;
  background: url(../images/icon/arrow-1.png);
  background-repeat: no-repeat;
  content: "";
}

.single-service-process .icon-holder .icon {
  position: absolute;
  top: 0;
  right: 70px;
  height: 100px;
  width: 100px;
  background: #fff;
  display: block;
  border-radius: 50%;
  text-align: center;
  padding: 30px 0;
  -moz-box-shadow: 0px 3px 6px #e0dddd;
  -webkit-box-shadow: 0px 3px 6px #e0dddd;
  box-shadow: 0px 3px 6px #e0dddd;
}

.single-service-process .icon-holder .icon span:before {
  color: #2f2f2f;
  font-size: 40px;
  line-height: 40px;
}

.single-service-process .icon-holder .count {
  position: absolute;
  bottom: 0;
  left: 120px;
  width: 23px;
  height: 23px;
  background: #f3441e;
  border-radius: 50%;
  text-align: center;
}

.single-service-process .icon-holder .count h4 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
}

.single-service-process .icon-holder.left {
  left: 0;
}

.single-service-process .icon-holder.left .icon {
  left: 80px;
}

.single-service-process .icon-holder.left:before {
  left: 10px;
  background: url(../images/icon/arrow-2.png);
}

.single-service-process .icon-holder.left .count {
  position: absolute;
  bottom: 0;
  left: 37px;
}

/*** 
=============================================
    Specialities area style
=============================================
***/
.specialities-area {
  background-attachment: scroll;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 120px;
  padding-bottom: 128px;
  position: relative;
  z-index: 1;
}

.specialities-area::before {
  background: rgba(20, 20, 20, 0.95) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.specialities-image-box {
  position: relative;
  display: block;
  max-width: 600px;
  width: 100%;
}

.specialities-image-box img {
  width: auto;
}

.specialities-image-box .single-option {
  position: absolute;
}

.specialities-image-box .single-option p {
  margin: 0;
  display: inline-block;
  line-height: 20px;
  color: #ffffff;
}

.specialities-image-box .single-option .border {
  position: relative;
  top: 4px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 6px solid #f3441e !important;
  border-radius: 50%;
}

.specialities-image-box .single-option.left-text p {
  padding-right: 7px;
}

.specialities-image-box .single-option.right-text p {
  padding-left: 7px;
}

.specialities-image-box .single-option.one {
  left: -100px;
  top: 60px;
}

.specialities-image-box .single-option.two {
  left: -150px;
  bottom: 190px;
}

.specialities-image-box .single-option.three {
  left: 150px;
  bottom: 95px;
}

.specialities-image-box .single-option.four {
  top: 65px;
  right: 125px;
}

.specialities-image-box .single-option.five {
  top: 300px;
  right: 0px;
}

.specialities-image-box .single-option.six {
  right: -50px;
  bottom: 150px;
}

.specialities-content {
  position: relative;
  display: block;
}

.specialities-content .sec-title {
  padding-bottom: 53px;
}

.specialities-content .inner-content {
  position: relative;
  display: block;
  background: #202020;
  width: 100%;
  max-width: 370px;
  padding: 35px 30px 33px;
  margin-left: 100px;
  -moz-box-shadow: 0px 5px 5px #121212;
  -webkit-box-shadow: 0px 5px 5px #121212;
  box-shadow: 0px 5px 5px #121212;
}

.specialities-content .inner-content h3 {
  color: #f3441e;
  font-size: 18px;
  margin: 0 0 14px;
  text-transform: uppercase;
}

.specialities-content .inner-content ul {
  position: relative;
  display: block;
}

.specialities-content .inner-content ul li {
  position: relative;
  line-height: 50px;
  border-top: 1px solid #282828;
  color: #ffffff;
  font-size: 16px;
  padding: 9px 0 7px;
  z-index: 1;
}

.specialities-content .inner-content ul li p {
  color: #ffffff;
  font-size: 16px;
  margin: 0;
  display: inline-block;
  background: #202020;
  padding-right: 15px;
}

.specialities-content .inner-content ul li:before {
  position: absolute;
  top: 34px;
  left: 0px;
  width: 100%;
  height: 1px;
  border-bottom: 2px dashed #282828;
  z-index: -1;
  content: "";
}

.specialities-content .inner-content ul li:last-child {
  border-bottom: 1px solid #282828;
}

.specialities-content .inner-content ul li span:before {
  position: relative;
  top: 3px;
  color: #444444;
  font-size: 25px;
  line-height: 25px;
  display: inline-block;
  padding-right: 19px;
  background: #202020;
}

.specialities-content .inner-content ul li b {
  float: right;
  font-weight: 400;
  background: #202020;
  padding-left: 10px;
  display: inline-block;
}

.specialities-content .inner-content .button {
  display: block;
  padding-top: 28px;
}

/*** 
=============================================
    Fact counter Area style
=============================================
***/
.fact-counter-area {
  background-attachment: scroll;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 90px;
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
}

.fact-counter-area::before {
  background: rgba(246, 246, 246, 0.75);
  background: -moz-linear-gradient(left, rgba(246, 246, 246, 0.75) 12%, rgba(246, 246, 246, 0.78) 21%, rgba(246, 246, 246, 0.79) 26%, whitesmoke 90%, whitesmoke 100%);
  background: -webkit-gradient(left top, right top, color-stop(12%, rgba(246, 246, 246, 0.75)), color-stop(21%, rgba(246, 246, 246, 0.78)), color-stop(26%, rgba(246, 246, 246, 0.79)), color-stop(90%, whitesmoke), color-stop(100%, whitesmoke));
  background: -webkit-linear-gradient(left, rgba(246, 246, 246, 0.75) 12%, rgba(246, 246, 246, 0.78) 21%, rgba(246, 246, 246, 0.79) 26%, whitesmoke 90%, whitesmoke 100%);
  background: -o-linear-gradient(left, rgba(246, 246, 246, 0.75) 12%, rgba(246, 246, 246, 0.78) 21%, rgba(246, 246, 246, 0.79) 26%, whitesmoke 90%, whitesmoke 100%);
  background: -ms-linear-gradient(left, rgba(246, 246, 246, 0.75) 12%, rgba(246, 246, 246, 0.78) 21%, rgba(246, 246, 246, 0.79) 26%, whitesmoke 90%, whitesmoke 100%);
  background: linear-gradient(to right, rgba(246, 246, 246, 0.75) 12%, rgba(246, 246, 246, 0.78) 21%, rgba(246, 246, 246, 0.79) 26%, whitesmoke 90%, whitesmoke 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#f5f5f5', GradientType=1 );
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.single-fact-counter {
  position: relative;
  display: block;
  margin-bottom: 40px;
}

.single-fact-counter .count-box {
  position: relative;
  display: block;
}

.single-fact-counter .icon {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.single-fact-counter .icon span:before {
  color: #f3441e;
  font-size: 70px;
  line-height: 70px;
}

.single-fact-counter .icon-box i {
  color: #222222;
  font-size: 34px;
  line-height: 50px;
}

.single-fact-counter h1 {
  position: relative;
  display: block;
}

.single-fact-counter h1 span {
  position: relative;
  display: inline-block;
  color: #2f2f2f;
  font-size: 40px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}

.single-fact-counter h1 .plus {
  display: inline-block;
  position: relative;
  top: -3px;
}

.single-fact-counter .count-box .title {
  position: relative;
  display: block;
  margin-top: 29px;
  border-bottom: 1px solid #e6e6e6;
}

.single-fact-counter .count-box .title h3 {
  width: 230px;
  height: 52px;
  display: block;
  margin: 0 auto;
  text-align: center;
  background: #ffffff;
  color: #2f2f2f;
  line-height: 20px;
  font-weight: 400;
  text-transform: capitalize;
  font-family: 'Titillium Web', sans-serif;
  line-height: 52px;
}

/*** 
=============================================
    Gallery Area style
=============================================
***/
.gallery-area {
  padding: 90px 0 90px;
}

.gallery-area .gallery {
  margin: 0;
}

.gallery .pd0 {
  padding: 0;
}

.gallery .sec-title {
  margin: 0;
  padding: 99px 0 100px;
}

.single-gallery-item {
  position: relative;
  display: block;
  padding: 0;
  overflow: hidden;
}

.single-gallery-item .img-holder {
  position: relative;
  display: block;
  overflow: hidden;
}

.single-gallery-item .img-holder img {
  width: 100%;
  transition: all 1s ease-in-out;
}

.single-gallery-item:hover .img-holder img {
  transform: scale(1.1);
}

.single-gallery-item .img-holder .overlay-style-one {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  text-align: center;
  z-index: 1;
  background: rgba(0, 0, 0, 0.72);
  transition: all 700ms ease;
}

.single-gallery-item:hover .img-holder .overlay-style-one {
  opacity: 1;
}

.single-gallery-item .img-holder .overlay-style-one .box {
  display: table;
  height: 100%;
  width: 100%;
}

.single-gallery-item .img-holder .overlay-style-one .box .content {
  display: table-cell;
  vertical-align: middle;
}

.single-gallery-item .img-holder .overlay-style-one .box .content h3 {
  position: relative;
  opacity: 0;
  font-size: 24px;
  transition: all 700ms ease;
  transform: translateY(40px);
}

.single-gallery-item:hover .img-holder .overlay-style-one .box .content h3 {
  opacity: 1;
  transform: translate(0%);
}

.single-gallery-item .img-holder .overlay-style-one .box .content h3 a {
  color: #ffffff;
}

.single-gallery-item.more-works .button-holder {
  position: relative;
  display: block;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.single-gallery-item.more-works .button-holder .overlay-style-one {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #ffffff;
  opacity: 1;
  z-index: 1;
}

.single-gallery-item.more-works .button-holder .overlay-style-one .content a.more-works {
  position: relative;
  display: block;
  background: #f5f5f5;
  color: #2f2f2f;
  width: 220px;
  height: 60px;
  margin: 0 auto;
  line-height: 60px;
}

.single-gallery-item.more-works .button-holder .overlay-style-one .content a.more-works:hover {
  background: #151515;
  color: #ffffff;
}

/*** 
====================================================================
	Feedback Section
====================================================================
***/
.feedback-section {
  position: relative;
  display: block;
}

.feedback-section .inner-content {
  position: relative;
  display: block;
  background: #f5f5f5;
  min-height: 545px;
}

.feedback-section .carousel-outer {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 1155px;
  float: left;
  z-index: 10;
  margin-top: 90px;
}

.feedback-section .owl-carousel {
  max-width: 575px;
  position: static;
  float: right;
  width: 100%;
}

.feedback-section .owl-carousel .owl-stage-outer {
  overflow: visible;
  padding-bottom: 90px;
}

.testimonial-block-three {
  position: relative;
  display: block;
  border-top: 4px solid #f3441e;
  background: #ffffff;
  box-shadow: 0px 3px 8px #f5f5f5;
  padding: 54px 50px 55px;
}

.testimonial-block-three .inner-box {
  position: relative;
  display: block;
}

.testimonial-block-three .inner-box .upper-box {
  position: relative;
  transition: all 300ms ease;
}

.testimonial-block-three .inner-box .upper-box .text {
  position: relative;
  color: #2f2f2f;
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
}

.testimonial-block-three .inner-box .lower-box {
  position: relative;
  margin-top: 33px;
}

.testimonial-block-three .inner-box .lower-box .client-info {
  display: block;
  margin-bottom: 28px;
}

.testimonial-block-three .inner-box .lower-box .client-info h3 {
  position: relative;
  color: #2f2f2f;
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  margin: 0 0 3px;
}

.testimonial-block-three .inner-box .lower-box .client-info span {
  color: #f3441e;
}

.testimonial-block-three .inner-box .lower-box .image-box {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  transition: all 300ms ease;
}

.testimonial-block-three .inner-box .lower-box .image-box img {
  border-radius: 50%;
}

.testimonial-block-three .inner-box .quote-icon {
  position: absolute;
  bottom: -14px;
  right: 40px;
}

.testimonial-block-three .inner-box .quote-icon span:before {
  color: #f5f5f5;
  font-size: 135px;
  line-height: 135px;
}

.feedback-section .owl-item .testimonial-block-three .inner-box {
  opacity: 0.3;
}

.feedback-section .owl-item.active .testimonial-block-three {
  box-shadow: block;
}

.feedback-section .owl-item.active .testimonial-block-three .inner-box {
  opacity: 1;
}

.feedback-section .carousel-outer .owl-theme .owl-nav {
  left: 467px;
  position: absolute;
  width: 90px;
  z-index: 100;
  bottom: 230px;
  height: 90px;
  margin: 0;
}

.feedback-section .carousel-outer .owl-theme .owl-nav .owl-prev {
  background: rgba(243, 71, 30, 0.7);
  display: block;
  height: 90px;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 700ms ease 0s;
  width: 45px;
  z-index: 10;
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.feedback-section .carousel-outer .owl-theme .owl-nav .owl-next {
  background: rgba(243, 71, 30, 0.7);
  display: block;
  height: 90px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 700ms ease 0s;
  width: 45px;
  z-index: 10;
  border-radius: 0%;
  margin: 0;
  padding: 0;
}

.feedback-section .carousel-outer .owl-theme .owl-nav .owl-prev:hover {
  background: rgba(243, 71, 30, 0.9);
}

.feedback-section .carousel-outer .owl-theme .owl-nav .owl-next:hover {
  background: rgba(243, 71, 30, 0.9);
}

.feedback-section .carousel-outer .owl-theme .owl-nav div .fa:hover {
  color: #fff;
}

.feedback-section .carousel-outer .owl-theme .owl-nav div .fa {
  color: #ececec;
  font-size: 30px;
  font-weight: 100;
  display: block;
  text-align: center;
  line-height: 88px;
}

.feedback-section .inner-content .video-holder {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  max-width: 960px;
  width: 100%;
  z-index: 1;
}

.feedback-section .inner-content .video-holder img {
  transform: scale(1);
  transition: all 0.5s ease 0s;
  width: 100%;
}

.feedback-section .inner-content .video-holder .overlay-gallery {
  background-color: transparent;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  transition: all 500ms ease;
}

.feedback-section .inner-content .video-holder .overlay-gallery .icon-holder {
  display: table;
  height: 100%;
  width: 100%;
  text-align: center;
}

.feedback-section .inner-content .video-holder .overlay-gallery .icon-holder .content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.feedback-section .inner-content .video-holder .overlay-gallery .icon-holder .icon {
  width: 90px;
  height: 90px;
  display: block;
  padding: 15px;
  background: #ffffff;
  border-radius: 50%;
  margin: 0 auto;
}

.feedback-section .inner-content .video-holder .overlay-gallery .icon-holder .icon .inner {
  width: 60px;
  height: 60px;
  display: block;
  background: #ffffff;
  border-radius: 50%;
  -moz-box-shadow: 0px 0px 10px #e8e3e3;
  -webkit-box-shadow: 0px 0px 10px #e8e3e3;
  box-shadow: 0px 0px 10px #e8e3e3;
}

.feedback-section .inner-content .video-holder .overlay-gallery .icon-holder .icon .inner a {
  width: 60px;
  height: 60px;
  display: block;
}

.feedback-section .inner-content .video-holder .overlay-gallery .icon-holder .icon .inner a span:before {
  color: #f3441e;
  font-size: 20px;
  display: block;
  padding: 20px 0;
}

.feedback-section .inner-content .video-holder:hover .overlay-gallery {
  background-color: rgba(0, 0, 0, 0.6);
}

.feedback-section .inner-content .video-holder:hover img {
  transform: scale(1.1);
}

/*** 
=============================================
    Repair Area style
=============================================
***/
.repair-area {
  position: relative;
  display: block;
}

.repair-area .repair-carousel {
  position: relative;
  margin-top: -140px;
  z-index: 10;
  background: #ffffff;
}

.repair-area .owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  border-right: 1px solid #f2f2f2;
}

.single-repair-item {
  position: relative;
  display: inline-block;
  float: left;
  background: #ffffff;
  padding: 79px 0 44px;
  max-width: 290px;
  width: 100%;
  text-align: center;
  border-left: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

.single-repair-item .icon-holder {
  position: relative;
  display: block;
  margin-bottom: 25px;
  z-index: 1;
}

.single-repair-item .icon-holder:before {
  position: absolute;
  top: -20px;
  left: 100px;
  width: 40px;
  height: 40px;
  background: #f5f5f5;
  border-radius: 50%;
  z-index: -1;
  content: "";
}

.single-repair-item .icon-holder span:before {
  color: #3aabd0;
  font-size: 90px;
  line-height: 90px;
}

.single-repair-item .title-holder h3 {
  color: #d0d0d0;
}

.repair-area .owl-theme .owl-nav {
  display: none !important;
  position: absolute;
  top: 0px;
  right: -330px;
  margin: 0;
  width: 70px;
  height: 100%;
  border-left: 0px solid #f2f2f2;
  display: block;
  padding: 115px 0;
  padding-left: 30px;
}

.repair-area .owl-theme .owl-nav [class*='owl-'] {
  background: transparent;
  color: #2f2f2f;
  font-size: 35px;
  margin: 0;
  padding: 0;
  border-radius: 0;
}

.repair-area .owl-theme .owl-nav .owl-prev {
  float: left;
}

.repair-area .owl-theme .owl-nav .owl-next {
  position: absolute;
  right: 0px;
  margin: 0;
}

/*** 
=============================================
    About V2 Area style
=============================================
***/
.about-v2-area {
  position: relative;
  display: block;
  padding-top: 90px;
  padding-bottom: 90px;
  background: #ffffff;
}

.about-v2-area .about-left-content .inner-content h2 {
  color: #2f2f2f;
}

/*** 
=============================================
    Specialities V2 Area style
=============================================
***/
.specialities-v2-area {
  position: relative;
  display: block;
  background: #252525;
  padding: 90px 0 90px;
}

.specialities-image-box2 {
  position: relative;
  display: block;
  max-width: 450px;
  width: 100%;
  margin: 0;
  z-index: 1;
}

.specialities-image-box2:before {
  position: absolute;
  top: -90px;
  bottom: 0;
  right: -190px;
  height: 764px;
  width: 589px;
  background: url(../images/pattern/specialities-image-box2-bg.jpg);
  background-repeat: no-repeat;
  content: "";
  z-index: -1;
}

.specialities-image-box2 img {
  width: auto;
}

.specialities-image-box2 .single-option {
  position: absolute;
}

.specialities-image-box2 .single-option.one {
  left: 25px;
  top: -10px;
}

.specialities-image-box2 .single-option.two {
  left: 110px;
  top: 60px;
}

.specialities-image-box2 .single-option.three {
  left: 70px;
  top: 300px;
}

.specialities-image-box2 .single-option.four {
  left: 210px;
  bottom: 40px;
}

.specialities-image-box2 .single-option.five {
  left: 340px;
  bottom: 145px;
}

.specialities-image-box2 .single-option.six {
  left: 190px;
  top: 20px;
}

.specialities-image-box2 .single-option.seven {
  left: 305px;
  top: 210px;
}

.specialities-image-box2 .single-option .border-outer {
  position: relative;
  display: block;
  width: 38px;
  height: 38px;
  z-index: 1;
}

.specialities-image-box2 .single-option .border-outer:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(117, 185, 218, 0.5);
  border-radius: 50%;
  content: "";
  transform: scale(0);
  transition: all 0.7s ease;
  z-index: -1;
}

.specialities-image-box2 .single-option:hover .border-outer:before {
  transform: scale(1);
}

.specialities-image-box2 .single-option .border-outer:after {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 26px;
  height: 26px;
  text-align: center;
  background: rgba(117, 185, 218, 0.5);
  border-radius: 50%;
  content: "";
  transform: scale(1);
  transition: all 0.7s ease;
  z-index: -1;
}

.specialities-image-box2 .single-option:hover .border-outer:after {
  transform: scale(0);
}

.specialities-image-box2 .single-option .border-outer .borders {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #f5f5f5;
  border: 6px solid #3aabd0;
  border-radius: 50%;
  margin: 9px;
  z-index: 1;
}

.specialities-image-box2 .single-option .overlay-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  width: 200px;
  margin: 9px 0;
  transition: all 0.7s ease;
  transition-delay: .1s;
  opacity: 0;
  transform: translate3d(0px, 0px, 0px);
}

.specialities-image-box2 .single-option:hover .overlay-text {
  transform: translate3d(30px, 0px, 0px);
  opacity: 1;
}

.specialities-image-box2 .single-option .overlay-text p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  line-height: 20px;
}

.specialities-content2 {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.specialities-content2 .sec-title {
  padding-bottom: 53px;
}

.specialities-content2 .inner-content-box ul li {
  max-width: 285px;
  width: 100%;
  float: left;
  text-align: center;
  display: block;
  padding: 0 20px;
}

.specialities-content2 .inner-content-box ul li:nth-child(1) {
  border-bottom: 1px solid #303030;
  border-right: 1px solid #303030;
  padding-bottom: 38px;
}

.specialities-content2 .inner-content-box ul li:nth-child(2) {
  border-bottom: 1px solid #303030;
  padding-bottom: 38px;
}

.specialities-content2 .inner-content-box ul li:nth-child(3) {
  border-right: 1px solid #303030;
  padding-top: 50px;
}

.specialities-content2 .inner-content-box ul li:nth-child(4) {
  padding-top: 50px;
}

.specialities-content2 .inner-content-box ul li .icon-holder {
  display: block;
  margin-bottom: 26px;
}

.specialities-content2 .inner-content-box ul li .icon-holder span:before {
  color: #3aabd0;
  font-size: 65px;
  line-height: 65px;
}

.specialities-content2 .inner-content-box ul li .text-holder h3 {
  color: #ffffff;
  margin-bottom: 20px;
}

.specialities-content2 .inner-content-box ul li .text-holder p {
  margin: 0;
}

/*** 
=============================================
    Appointment Process area style
=============================================
***/
.appointment-process-area {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 90px 0 90px;
}

.appointment-process {
  position: relative;
  display: block;
  overflow: hidden;
}

.appointment-process ul li {
  position: relative;
  display: inline-block;
  float: left;
  width: 25%;
  text-align: center;
}

.appointment-process ul li:before {
  position: absolute;
  top: 68px;
  right: -32px;
  width: 65px;
  height: 5px;
  background: url(../images/pattern/dotted.png);
  content: "";
}

.appointment-process ul li:last-child:before {
  display: none;
}

.appointment-process ul li .icon-holder {
  position: relative;
  display: block;
  width: 140px;
  height: 140px;
  margin: 0 auto;
  border: 1px solid #f5f5f5;
  border-radius: 50%;
  padding: 38px 0;
}

.appointment-process ul li .icon-holder span:before {
  color: #d0d0d0;
  font-size: 60px;
  line-height: 60px;
  transition: all 500ms ease;
}

.appointment-process ul li:hover .icon-holder span:before {
  color: #3aabd0;
}

.appointment-process ul li .title-holder {
  display: block;
  margin-top: 25px;
  margin-bottom: 20px;
}

.appointment-process ul li .title-holder h3 {
  color: #2f2f2f;
}

.appointment-process ul li .count {
  display: block;
  width: 30px;
  height: 30px;
  background: #3aabd0;
  margin: 0 auto;
  border-radius: 50%;
}

.appointment-process ul li .count h4 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
}

.appointment-button {
  display: block;
  margin-top: 50px;
}

.appointment-button a {
  border: 2px solid #3aabd0;
  color: #3aabd0;
  width: 250px;
  height: 60px;
  display: inline-block;
  line-height: 56px;
  border-radius: 30px;
}

.appointment-button a:hover {
  background: #151515;
  color: #ffffff;
  border-color: #151515;
}

/*** 
=============================================
    Home3 Google Map Area style
=============================================
***/
.home3-google-map-area {
  display: block;
}

.google-map-box {
  position: relative;
}

#home3-google-map {
  height: 500px;
  width: 100%;
}

/*** 
=============================================
    Breadcrumb area style
=============================================
***/
.breadcrumb-area {
  background-attachment: scroll;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 156px;
  z-index: 1;
  padding-bottom: 104px;
}

.breadcrumb-area::before {
  background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.breadcrumb-area .inner-content {
  position: relative;
  display: block;
}

.breadcrumb-area .title {
  display: inline-block;
  float: left;
}

.breadcrumb-area .title h1 {
  color: #ffffff;
  font-size: 40px;
  font-weight: 400;
  line-height: 40px;
  text-transform: capitalize;
}

.breadcrumb-area .breadcrumb-menu {
  display: inline-block;
  float: right;
}

.breadcrumb-area .breadcrumb-menu ul {
  display: block;
  margin: 14px 0;
}

.breadcrumb-area .breadcrumb-menu ul li {
  display: block;
  float: left;
  margin-right: 10px;
  padding-right: 10px;
  line-height: 12px;
  font-size: 16px;
  font-weight: 400;
  border-right: 1px solid #fff;
  text-transform: capitalize;
  font-family: 'Rubik', sans-serif;
  transition: all 500ms ease;
}

.breadcrumb-area .breadcrumb-menu ul li:last-child {
  margin-right: 0;
  padding-right: 0;
  border: none;
}

.breadcrumb-area .breadcrumb-menu ul li a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  font-family: 'Rubik', sans-serif;
  transition: all 500ms ease;
}

.breadcrumb-area .breadcrumb-menu ul li.active {
  color: #999999;
}

.breadcrumb-area .breadcrumb-menu ul li a:hover {
  color: #999999;
}

/*** 
=============================================
    Breadcrumb Area Style2 style
=============================================
***/
.breadcrumb-area.Style2 {
  padding-top: 130px;
  padding-bottom: 130px;
}

.breadcrumb-area.Style2::before {
  background: none;
}

/*** 
=============================================
    Breadcrumb Area Style2 style
=============================================
***/
.breadcrumb-area.Style3 {
  padding-top: 130px;
  padding-bottom: 130px;
}

/*** 
=============================================
    About Content Area style
=============================================
***/
.about-content-area {
  position: relative;
  display: block;
  padding-top: 90px;
  padding-bottom: 90px;
}

.about-content-box {
  display: block;
  margin-top: 70px;
  padding-right: 30px;
}

.about-content-box .single-item .icon-holder {
  display: block;
  margin-bottom: 38px;
}

.about-content-box .single-item .icon-holder span:before {
  color: #ff3333;
  font-size: 65px;
  line-height: 65px;
}

.about-content-box .single-item .title-holder {
  display: block;
  margin-bottom: 29px;
}

.about-content-box .single-item .title-holder h2 {
  color: #333333;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
}

.about-content-box .single-item .title-holder h2 span {
  color: #ff3333;
}

.about-content-box .single-item .text-holder p {
  margin: 0;
}

.about-content-area .owl-dots {
  position: relative;
  margin-top: 34px !important;
  text-align: left;
}

.about-content-area .owl-dots .owl-dot {
  position: relative;
  background-image: none;
  width: 15px;
  height: 6px;
  margin: 5px 5px;
  border: 1px solid #ebe8e5;
  padding: 0px;
  background: #ffffff;
  border-radius: 0%;
  transition: all 500ms ease;
  display: inline-block;
}

.about-content-area .owl-dots .owl-dot span {
  display: none;
}

.about-content-area .owl-dots .owl-dot.active {
  position: relative;
  background: #ff3333;
  border-color: #ff3333;
}

.about-content-area .owl-dots .owl-dot.active:before {
  position: absolute;
  top: -3px;
  left: -1px;
  right: 0;
  width: 15px;
  height: 2px;
  background: #ff3333;
  content: "";
  transition: all 500ms ease;
}

/*** 
=============================================
    Team Area style
=============================================
***/
.team-area {
  position: relative;
  padding-top: 90px;
  padding-bottom: 50px;
}

.single-team-member {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 40px;
  transition: all 500ms ease;
}

.single-team-member .name {
  position: relative;
  display: block;
  background: #f5f5f5;
  padding: 35px 0 33px;
  padding-left: 30px;
  z-index: 1;
}

.single-team-member .name:before {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 1;
  z-index: -1;
  content: "";
  transition: all 500ms ease;
  background: rgba(243, 71, 30, 0.9);
  transform: translate3d(0px, -100%, 0px);
}

.single-team-member:hover .name:before {
  transform: translate3d(0px, 0%, 0px);
}

.single-team-member .name h3 {
  display: block;
  color: #333333;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 5px;
  transition: all 500ms ease;
  transition-delay: .4s;
}

.single-team-member:hover .name h3 {
  color: #ffffff;
}

.single-team-member .name span {
  color: #ff3333;
  transition: all 500ms ease;
  transition-delay: .5s;
}

.single-team-member:hover .name span {
  color: #ffffff;
}

.single-team-member .img-holder {
  overflow: hidden;
  position: relative;
  display: block;
  border-top: 10px solid #ffffff;
  transition: all 500ms ease;
  transform: translate3d(0px, 0%, 0px);
}

.single-team-member:hover .img-holder {
  transform: translate3d(0px, -10px, 0px);
}

.single-team-member .img-holder img {
  transition: all 0.5s ease-in-out 0.6s;
  width: 100%;
}

.single-team-member:hover .img-holder img {
  transform: scale(1.2, 1.2);
}

.single-team-member .img-holder .overlay-style-one {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: auto;
  height: auto;
  opacity: 1;
  z-index: 1;
  transition: all 500ms ease;
  background: rgba(20, 20, 20, 0.83);
  transform: translate3d(0px, 100%, 0px);
}

.single-team-member:hover .img-holder .overlay-style-one {
  transform: translate3d(0px, 0, 0px);
}

.single-team-member .img-holder .overlay-style-one .social-links {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
}

.single-team-member .img-holder .overlay-style-one .social-links li {
  display: inline-block;
  margin: 0 3px;
}

.single-team-member .img-holder .overlay-style-one .social-links li a i {
  width: 35px;
  height: 35px;
  background: #3a559f;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  line-height: 36px;
  transition: all 500ms ease;
}

.single-team-member .img-holder .overlay-style-one .social-links li a:hover i {
  background: #2b4793;
}

.single-team-member .img-holder .overlay-style-one .social-links li a.twitter i {
  background: #55acee;
}

.single-team-member .img-holder .overlay-style-one .social-links li a.twitter:hover i {
  background: #3590d4;
}

.single-team-member .img-holder .overlay-style-one .social-links li a.youtube i {
  background: #e02f2f;
}

.single-team-member .img-holder .overlay-style-one .social-links li a.youtube:hover i {
  background: #cb1e1e;
}

/*** 
=============================================
    Service Center Area style
=============================================
***/
.service-center-area {
  position: relative;
  display: block;
  padding: 80px 0 50px;
}

.service-center-area .title {
  display: block;
  padding-bottom: 48px;
}

.service-center-area .title h1 {
  color: #333333;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
  margin: 0 0 11px;
}

.service-center-area .title span {
  color: #f3441e;
}

.service-center-area .title p {
  color: #999999;
  font-size: 18px;
  margin: 0;
}

.service-center-area .service-box {
  margin-left: -6px;
  margin-right: -6px;
}

.service-center-area .service-box .single-box {
  padding-left: 6px;
  padding-right: 6px;
}

.single-service-center {
  position: relative;
  display: block;
  margin-bottom: 40px;
}

.single-service-center .img-holder {
  position: relative;
  display: block;
  overflow: hidden;
}

.single-service-center .img-holder img {
  width: 100%;
  transition: all 1s ease-in-out;
}

.single-service-center:hover .img-holder img {
  transform: scale(1.1);
}

.single-service-center .inner-content {
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  text-align: center;
  transition: all 500ms ease;
  transform: translate3d(0px, 0%, 0px);
}

.single-service-center:hover .inner-content {
  transition: all 500ms ease;
  opacity: 0;
  transform: translate3d(0px, -100%, 0px);
}

.single-service-center .inner-content .icon-holder {
  display: block;
  padding-bottom: 25px;
  margin: 0 0 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.single-service-center .inner-content .icon-holder span:before {
  color: #ffffff;
  font-size: 100px;
  line-height: 100px;
}

.single-service-center .inner-content .title-holder h2 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
}

.single-service-center .img-holder .overlay-content {
  position: absolute;
  bottom: 38px;
  left: 30px;
  right: 30px;
  opacity: 0;
  text-align: center;
  z-index: 1;
  transition: all 700ms ease;
  transform: translate3d(0px, 100%, 0px);
}

.single-service-center:hover .img-holder .overlay-content {
  opacity: 1;
  transform: translate3d(0px, 0%, 0px);
}

.single-service-center .img-holder .overlay-content .title-holder {
  display: block;
  padding-bottom: 25px;
  margin: 0 0 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.single-service-center .img-holder .overlay-content .title-holder h2 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
}

.single-service-center .img-holder .overlay-content .text-holder p {
  color: #ffffff;
  margin: 0 0 16px;
}

.single-service-center .img-holder .overlay-content .text-holder a {
  color: #ffffff;
}

/*** 
=============================================
    Latest project area style
=============================================
***/
.specialities-v3-area {
  position: relative;
  display: block;
  padding-top: 90px;
  padding-bottom: 60px;
  z-index: 1;
}

.specialities-v3-area:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 320px;
  background: #f5f5f5;
  content: "";
  z-index: -1;
}

.specialities-v3-area .sec-title p {
  color: #999999;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin: 19px 0 33px;
}

.specialities-v3-area .sec-title a {
  position: relative;
  display: inline-block;
  background: #f3441e;
  color: #ffffff;
  width: 180px;
  height: 55px;
  line-height: 54px;
  border-radius: 4px;
  text-align: center;
}

.specialities-v3-area .sec-title a span:before {
  font-weight: 500;
  padding-left: 19px;
}

.specialities-v3-area .sec-title a:hover {
  background: #d62b06;
}

.single-specialitie-box {
  position: relative;
  display: block;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  padding: 97px 0 100px;
  margin-bottom: 30px;
}

.single-specialitie-box span:before {
  color: #e0e0e0;
  font-size: 62px;
  line-height: 62px;
}

.single-specialitie-box h3 {
  color: #333333;
  font-size: 20px;
  font-weight: 400;
  margin: 23px 0 15px;
}

.single-specialitie-box .borders {
  height: 3px;
  width: 50px;
  background: #f3441e;
  display: block;
  margin: 0 auto;
}

/*** 
=============================================
    Corporate Services Area style
=============================================
***/
.corporate-services-area {
  position: relative;
  display: block;
}

.corporate-services-area .inner-content {
  position: relative;
  display: block;
}

.corporate-services-box {
  position: absolute;
  top: 135px;
  left: 50%;
  margin-left: -120px;
  display: block;
  width: 240px;
  height: 240px;
  background: transparent;
  text-align: center;
  z-index: 2;
  padding: 90px 0;
}

.corporate-services-box:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: -1;
  content: "";
  transform: rotate(45deg);
}

.corporate-services-box h2 {
  color: #333333;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
}

.corporate-services-area .left-side {
  padding: 0;
}

.corporate-services-area .right-side {
  padding: 0;
}

.corporate-services-left {
  position: relative;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 555px;
  z-index: 1;
}

.corporate-services-left::before {
  background: rgba(0, 0, 0, 0.9);
  content: "";
  height: 30%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  transition: all 0.7s ease 0s;
  opacity: 0;
}

.corporate-services-left:hover::before {
  opacity: 1;
  height: 100%;
}

.corporate-services-left .content-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  max-width: 530px;
  width: 100%;
  margin: 0 auto;
  background: #18391A;
  display: block;
  padding: 45px 40px 43px;
  text-align: center;
  opacity: 0;
  transition: all 0.7s ease 0s;
  transition-delay: .5s;
}

.corporate-services-left:hover .content-box {
  opacity: 1;
  bottom: 0;
}

.corporate-services-left .content-box h3 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 20px;
}

.corporate-services-left .content-box p {
  color: #ffffff;
  margin: 0;
  line-height: 24px;
}

.corporate-services-right {
  position: relative;
  padding-top: 555px;
  background-attachment: scroll;
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.corporate-services-right::before {
  background: rgba(0, 0, 0, 0.9) none repeat scroll 0 0;
  content: "";
  height: 30%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  transition: all 0.7s ease 0s;
  opacity: 0;
}

.corporate-services-right:hover::before {
  opacity: 1;
  height: 100%;
}

.corporate-services-right .content-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  max-width: 530px;
  width: 100%;
  margin: 0 auto;
  background: #18391A;
  display: block;
  padding: 45px 40px 43px;
  text-align: center;
  opacity: 0;
  transition: all 0.7s ease 0s;
  transition-delay: .5s;
}

.corporate-services-right:hover .content-box {
  opacity: 1;
  bottom: 0;
}

.corporate-services-right .content-box h3 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 20px;
}

.corporate-services-right .content-box p {
  color: #ffffff;
  margin: 0;
  line-height: 24px;
}

/*** 
=============================================
    Single Service Area style
=============================================
***/
.single-service-area {
  position: relative;
  display: block;
  padding: 90px 0 90px;
}

.single-service-top {
  position: relative;
  display: block;
  padding-bottom: 50px;
  border-bottom: 2px solid #f3441e;
}

.single-service-top .img-holder {
  position: relative;
  display: block;
  overflow: hidden;
}

.single-service-top .img-holder img {
  width: 100%;
}

.single-service-top .text-holder {
  position: relative;
  display: block;
}

.single-service-top .text-holder h2 {
  color: #333333;
  font-size: 24px;
  font-weight: 400;
  margin: -6px 0 25px;
}

.single-service-top .text-holder p {
  margin: 0 0 20px;
}

.single-service-top .text-holder .bottom {
  position: relative;
  display: block;
}

.single-service-top .text-holder .bottom h4 {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: uppercase;
  margin: 0 0 10px;
}

.single-service-top .text-holder .bottom ul li {
  line-height: 40px;
}

.single-service-top .text-holder .bottom ul li i {
  color: #f3441e;
  font-size: 16px;
  display: inline-block;
  padding-right: 9px;
}

.work-processing-step {
  position: relative;
  display: block;
  padding-top: 44px;
}

.work-processing-step h2 {
  color: #333333;
  font-size: 24px;
  font-weight: 400;
  display: block;
  margin: 0 0 21px;
}

.work-processing-step .single-box {
  position: relative;
  display: block;
  margin-bottom: 26px;
}

.work-processing-step .single-box h4 {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 11px;
  text-transform: uppercase;
}

.work-processing-step .single-box .inner-content {
  position: relative;
  display: block;
  border: 1px solid #f2f2f2;
  min-height: 85px;
  padding: 12px 20px 12px;
  padding-left: 100px;
  padding-right: 0;
}

.work-processing-step .single-box .inner-content .icon-box {
  position: absolute;
  top: 12px;
  bottom: 12px;
  left: 20px;
  width: 60px;
  height: 60px;
  background: #f5f5f5;
  border-radius: 50%;
  text-align: center;
  display: block;
  padding: 16px 0;
  transition: all 500ms ease;
}

.work-processing-step .single-box:hover .inner-content .icon-box {
  background: #f3441e;
}

.work-processing-step .single-box .inner-content .icon-box span:before {
  color: #333333;
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  transition: all 500ms ease;
}

.work-processing-step .single-box:hover .inner-content .icon-box span:before {
  color: #ffffff;
}

.work-processing-step .single-box .inner-content .title-holder {
  display: block;
  margin: 17px 0;
}

.work-processing-step .single-box .inner-content .title-holder h3 {
  color: #333333;
  font-size: 20px;
  font-weight: 400;
}

.service-price-box {
  position: relative;
  display: block;
  padding-top: 28px;
}

.service-price-box h2 {
  color: #333333;
  font-size: 24px;
  font-weight: 400;
  margin: 0 0 20px;
}

.service-price-box p {
  margin: 0 0 28px;
}

.service-price-box ul {
  position: relative;
  display: block;
  border: 1px solid #f2f2f2;
  padding: 34px 29px 0px;
}

.service-price-box ul li {
  display: block;
  overflow: hidden;
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  border-bottom: 1px solid #f2f2f2;
  line-height: 68px;
  text-transform: capitalize;
  font-family: 'Titillium Web', sans-serif;
}

.service-price-box ul li:last-child {
  border: none;
}

.service-price-box ul li span {
  color: #999999;
  font-size: 16px;
  font-weight: 400;
  float: right;
  font-family: 'Rubik', sans-serif;
}

.service-price-box ul li h3 {
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  display: block;
  margin: 0 0 14px;
}

.single-service-sidebar {
  position: relative;
  display: block;
  margin-left: 35px;
  padding: 48px 30px 50px;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 8px 3px #f5f5f5;
  box-shadow: 0px 0px 8px 3px #f5f5f5;
}

.title-box {
  position: relative;
  display: block;
  padding-left: 16px;
  padding-bottom: 29px;
}

.title-box:before {
  position: absolute;
  top: 2px;
  left: 0;
  width: 6px;
  height: 13px;
  background: #f3441e;
  content: "";
}

.title-box h3 {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
}

.single-service-sidebar .single-sidebar {
  position: relative;
  display: block;
  margin-bottom: 48px;
}

.single-service-sidebar .service-pages li {
  display: block;
  margin-bottom: 10px;
}

.single-service-sidebar .service-pages li:last {
  margin-bottom: 0;
}

.single-service-sidebar .service-pages li a {
  position: relative;
  display: block;
  border: 1px solid #f2f2f2;
  padding: 12px 29px 12px;
  padding-right: 0;
  color: #999999;
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
  font-family: 'Titillium Web', sans-serif;
  transition: all 500ms ease;
  transition-delay: .1s;
}

.single-service-sidebar .service-pages li a:hover,
.single-service-sidebar .service-pages li.active a {
  padding-left: 43px;
  color: #f3441e;
}

.single-service-sidebar .service-pages li a:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 29px;
  width: 4px;
  height: 13px;
  background: #f3441e;
  content: "";
  margin: 17px 0;
  transition: all 500ms ease;
  opacity: 0;
}

.single-service-sidebar .service-pages li a:hover:before,
.single-service-sidebar .service-pages li.active a:before {
  opacity: 1;
}

.single-service-sidebar .choose-us {
  position: relative;
  display: block;
  margin-top: 2px;
}

.single-service-sidebar .choose-us li {
  display: block;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 19px;
  margin-bottom: 19px;
}

.single-service-sidebar .choose-us li:last-child {
  margin-bottom: 0;
}

.single-service-sidebar .choose-us li a {
  position: relative;
  display: block;
  padding-left: 50px;
  transition: all 500ms ease;
}

.single-service-sidebar .choose-us li a .icon-holder {
  position: absolute;
  top: 0;
  left: 0;
}

.single-service-sidebar .choose-us li a .icon-holder span:before {
  color: #f3441e;
  font-size: 30px;
  line-height: 30px;
}

.single-service-sidebar .choose-us li a .title-holder h3 {
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  transition: all 500ms ease;
}

.single-service-sidebar .choose-us li a:hover .title-holder h3 {
  color: #f3441e;
}

.single-service-sidebar .sidebar-appoinment {
  position: relative;
  display: block;
  border: 1px solid #f2f2f2;
}

.single-service-sidebar .sidebar-appoinment .title {
  display: block;
  background: #f5f5f5;
  text-align: center;
  padding: 15px 0;
}

.single-service-sidebar .sidebar-appoinment .title h3 {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.single-service-sidebar .sidebar-appoinment .appoinment-form {
  display: block;
  padding: 24px 30px 40px;
}

.single-service-sidebar .sidebar-appoinment .appoinment-form .input-box {
  position: relative;
  display: block;
  margin-bottom: 14px;
}

.single-service-sidebar .sidebar-appoinment .appoinment-form .input-box.marbtm7 {
  margin-bottom: 7px;
}

.single-service-sidebar .sidebar-appoinment .appoinment-form .input-box .icon-box {
  position: absolute;
  right: 0;
  top: 12px;
}

.single-service-sidebar .sidebar-appoinment .appoinment-form .input-box .icon-box i {
  color: #cccccc;
  font-size: 14px;
}

.single-service-sidebar .sidebar-appoinment .appoinment-form .input-box input[type=text],
.single-service-sidebar .sidebar-appoinment .appoinment-form .input-box input[type=email] {
  width: 100%;
  border: 0px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  height: 45px;
  display: block;
  padding: 0 0px;
  color: #222222;
  font-size: 16px;
  font-weight: 400;
  border-radius: 0px;
  font-family: 'Rubik', sans-serif;
  transition: all 500ms ease;
}

.single-service-sidebar .sidebar-appoinment .appoinment-form button.btn-one {
  background: #f3441e;
  color: #ffffff;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  display: block;
  width: 100%;
  padding: 19px 40px 20px;
  text-transform: uppercase;
  border-radius: 4px;
  margin-top: 16px;
}

.single-service-sidebar .sidebar-appoinment .appoinment-form button.btn-one span:before {
  position: relative;
  top: 1px;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  padding-left: 10px;
}

.single-service-sidebar .sidebar-appoinment .appoinment-form button.btn-one:hover {
  background: #d62e09;
}

.single-service-sidebar .service-file {
  position: relative;
  display: block;
}

.single-service-sidebar .service-file a {
  position: relative;
  display: block;
  background: #f3441e;
  padding: 15px 0 16px;
  padding-left: 60px;
  color: #ffffff;
}

.single-service-sidebar .service-file a .icon-holder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  margin: 12px 0;
}

.single-service-sidebar .service-file a .icon-holder span:before {
  font-size: 30px;
  line-height: 30px;
}

.single-service-sidebar .service-file a .title-holder h3 {
  font-size: 18px;
  font-weight: 400;
}

.single-service-sidebar .service-file a .title-holder h3 span {
  font-size: 16px;
  margin-right: 20px;
  float: right;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
}

/*** 
=============================================
    Faq Area style
=============================================
***/
.faq-area {
  position: relative;
  display: block;
  padding: 88px 0 90px;
}

.faq-content-box {
  position: relative;
  display: block;
}

.faq-content-box .title-box {
  position: relative;
  display: block;
  padding-bottom: 30px;
}

.faq-content-box .title-box p {
  position: relative;
  display: block;
  margin: 0;
  margin-left: -15px;
  padding-top: 24px;
}

.faq-content-box form.search-form {
  position: relative;
  display: block;
  margin-bottom: 44px;
}

.faq-content-box form.search-form input[type="text"] {
  background: #fff;
  border: 0px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  color: #cccccc;
  display: block;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  letter-spacing: 1px;
  padding-left: 0px;
  padding-right: 55px;
  position: relative;
  -webkit-transition: all 500ms ease 0s;
  transition: all 500ms ease 0s;
  width: 100%;
}

.faq-content-box form.search-form button {
  background: transparent;
  color: #333333;
  display: block;
  height: 40px;
  padding: 8px 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: all 500ms ease 0s;
  width: 40px;
}

.faq-content-box form.search-form button i {
  font-size: 14px;
}

.faq-content-box form.search-form input[type="text"]:focus {
  border-bottom: 1px solid #000000;
  background: #fff;
  color: #000;
}

.faq-content-box form.search-form input[type="text"]:focus + button,
.faq-content-box form.search-form button:hover {
  background: #000000 none repeat scroll 0 0;
  color: #fff;
}

.faq-content-box .inner-content {
  position: relative;
  display: block;
}

.faq-content-box .inner-content .single-box {
  position: relative;
  display: block;
  overflow: hidden;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 40px;
  margin-bottom: 34px;
}

.faq-content-box .inner-content .single-box.last-child {
  border: none;
  padding: 0;
  margin: 0;
}

.faq-content-box .inner-content .single-box .left-content {
  position: relative;
  display: block;
  max-width: 335px;
  width: 100%;
  float: left;
}

.faq-content-box .inner-content .single-box .left-content h2 {
  color: #cccccc;
  font-size: 24px;
  margin: 0 0 20px;
}

.faq-content-box .inner-content .single-box .left-content h3 {
  color: #333333;
  font-size: 20px;
  line-height: 26px;
}

.faq-content-box .inner-content .single-box .right-content {
  position: relative;
  display: block;
  float: left;
  max-width: 535px;
  width: 100%;
  padding-left: 65px;
  padding-right: 35px;
}

.faq-content-box .inner-content .single-box .right-content:before {
  position: absolute;
  top: 53px;
  left: 0;
  bottom: 40px;
  width: 1px;
  height: 100%;
  background: #f2f2f2;
  content: "";
}

.faq-content-box .inner-content .single-box .right-content:after {
  position: absolute;
  bottom: -40px;
  left: 0;
  width: 1px;
  height: 40px;
  background: #ffffff;
  content: "";
}

.faq-content-box .inner-content .single-box .right-content h2 {
  color: #ff3333;
  font-size: 24px;
  margin: 0 0 20px;
}

.faq-content-box .inner-content .single-box .right-content p {
  margin: 0;
}

.faq-sidebar .sidebar-appoinment {
  position: relative;
  display: block;
  border: 1px solid #f2f2f2;
}

.faq-sidebar .sidebar-appoinment .title {
  display: block;
  background: #f5f5f5;
  text-align: center;
  padding: 15px 0;
}

.faq-sidebar .sidebar-appoinment .title h3 {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.faq-sidebar .sidebar-appoinment .appoinment-form {
  display: block;
  padding: 24px 30px 40px;
}

.faq-sidebar .sidebar-appoinment .appoinment-form .input-box {
  position: relative;
  display: block;
  margin-bottom: 14px;
}

.faq-sidebar .sidebar-appoinment .appoinment-form .input-box.marbtm7 {
  margin-bottom: 17px;
}

.faq-sidebar .sidebar-appoinment .appoinment-form .input-box input[type=text],
.faq-sidebar .sidebar-appoinment .appoinment-form .input-box input[type=email],
.faq-sidebar .sidebar-appoinment .appoinment-form .input-box textarea {
  width: 100%;
  border: 0px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  height: 45px;
  display: block;
  padding: 0 0px;
  color: #222222;
  font-size: 16px;
  font-weight: 400;
  border-radius: 0px;
  font-family: 'Rubik', sans-serif;
  transition: all 500ms ease;
}

.faq-sidebar .sidebar-appoinment .appoinment-form .input-box textarea {
  height: 58px;
}

.faq-sidebar .sidebar-appoinment .appoinment-form button.btn-one {
  background: #f3441e;
  color: #ffffff;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  display: block;
  width: 100%;
  padding: 19px 40px 20px;
  text-transform: uppercase;
  border-radius: 4px;
  margin-top: 16px;
}

.faq-sidebar .sidebar-appoinment .appoinment-form button.btn-one span:before {
  position: relative;
  top: 1px;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  padding-left: 10px;
}

/*** 
=============================================
    Locations Area style
=============================================
***/
.locations-area {
  position: relative;
  display: block;
  padding: 77px 0 32px;
}

.locations-content-box form.search-form {
  position: relative;
  display: block;
  margin-bottom: 48px;
}

.locations-content-box form.search-form input[type="text"] {
  background: #fff;
  border: 0px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  color: #cccccc;
  display: block;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  letter-spacing: 1px;
  padding-left: 0px;
  padding-right: 55px;
  position: relative;
  -webkit-transition: all 500ms ease 0s;
  transition: all 500ms ease 0s;
  width: 100%;
}

.locations-content-box form.search-form button {
  background: transparent;
  color: #333333;
  display: block;
  height: 40px;
  padding: 8px 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: all 500ms ease 0s;
  width: 40px;
}

.locations-content-box form.search-form button i {
  font-size: 14px;
}

.locations-content-box form.search-form input[type="text"]:focus {
  border-bottom: 1px solid #000000;
  background: #fff;
  color: #000;
}

.locations-content-box form.search-form input[type="text"]:focus + button,
.locations-content-box form.search-form button:hover {
  background: #000000 none repeat scroll 0 0;
  color: #fff;
}

.single-location-box {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 58px;
}

.single-location-box .title-box {
  display: block;
  overflow: hidden;
  padding-bottom: 29px;
}

.single-location-box .image-holder {
  position: relative;
  display: block;
}

.single-location-box .image-holder img {
  width: 100%;
}

.single-location-box .text-holder {
  position: relative;
  display: block;
  border: 2px solid #f2f2f2;
  padding: 33px 35px 32px;
  padding-right: 10px;
}

.single-location-box .text-holder ul {
  display: block;
}

.single-location-box .text-holder ul li {
  display: block;
  margin-bottom: 18px;
}

.single-location-box .text-holder ul li:last-child {
  margin-bottom: 0;
}

.single-location-box .text-holder ul li h3 {
  color: #333333;
  font-size: 18px;
  margin: 0 0 4px;
}

.single-location-box .text-holder ul li p {
  margin: 0;
}

#location1-google-map {
  height: 355px;
  width: 100%;
}

#location2-google-map {
  height: 355px;
  width: 100%;
}

#location3-google-map {
  height: 355px;
  width: 100%;
}

#location4-google-map {
  height: 355px;
  width: 100%;
}

#location5-google-map {
  height: 355px;
  width: 100%;
}

/*** 
=============================================
    Testimonial page style
=============================================
***/
.testimonial-page {
  padding-top: 90px;
  padding-bottom: 40px;
}

.single-testimonial-box {
  position: relative;
  display: block;
  margin-bottom: 50px;
  transition: all 500ms ease;
}

.single-testimonial-box .image-holder {
  position: relative;
  display: block;
}

.single-testimonial-box .image-holder img {
  width: 100%;
}

.single-testimonial-box .text-holder {
  position: relative;
  display: block;
  transition: all 500ms ease;
  padding: 44px 30px 40px;
  -webkit-box-shadow: 0px 4px 8px 2px rgba(236, 234, 234, 0.7);
  box-shadow: 0px 4px 8px 2px rgba(236, 234, 234, 0.7);
}

.single-testimonial-box:hover .text-holder {
  -webkit-box-shadow: 0px 4px 8px 2px rgba(236, 234, 234, 0.3);
  box-shadow: 0px 4px 8px 2px rgba(236, 234, 234, 0.3);
}

.single-testimonial-box .text-holder .text {
  display: block;
  margin-bottom: 27px;
}

.single-testimonial-box .text-holder .text p {
  color: #333333;
  font-size: 22px;
  line-height: 30px;
  margin: 0;
}

.single-testimonial-box .text-holder h3 {
  color: #333333;
  font-size: 20px;
  margin: 0 0 3px;
}

.single-testimonial-box .text-holder span {
  color: #ff3333;
}

.single-testimonial-box .text-holder .quote-icon {
  position: absolute;
  bottom: 21px;
  right: 30px;
}

.single-testimonial-box .text-holder .quote-icon span:before {
  color: #f5f5f5;
  font-size: 85px;
  line-height: 85px;
}

.single-testimonial-box .image-holder .overlay-gallery {
  background-color: transparent;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  transition: all 500ms ease;
}

.single-testimonial-box .image-holder .overlay-gallery .icon-holder {
  display: table;
  height: 100%;
  width: 100%;
}

.single-testimonial-box .image-holder .overlay-gallery .icon-holder .content {
  position: absolute;
  bottom: 30px;
  left: 30px;
}

.single-testimonial-box .image-holder .overlay-gallery .icon-holder .content .icon {
  width: 60px;
  height: 60px;
  display: inline-block;
  background: #ffffff;
  border-radius: 50%;
  text-align: center;
  float: left;
}

.single-testimonial-box .image-holder .overlay-gallery .icon-holder .content .icon a {
  width: 60px;
  height: 60px;
  display: block;
}

.single-testimonial-box .image-holder .overlay-gallery .icon-holder .content .icon a span:before {
  color: #f3441e;
  font-size: 20px;
  display: block;
  padding: 20px 0;
}

.single-testimonial-box .image-holder .overlay-gallery .icon-holder .content .title {
  display: inline-block;
  float: left;
  padding-left: 20px;
  margin: 8px 0;
}

.single-testimonial-box .image-holder .overlay-gallery .icon-holder .content .title h3 {
  color: #ffffff;
  font-size: 20px;
  margin: 0 0 3px;
}

.single-testimonial-box .image-holder .overlay-gallery .icon-holder .content .title span {
  color: #ff3333;
}

/*** 
=============================================
    pricing project area style
=============================================
***/
.pricing-table1-area {
  position: relative;
  display: block;
  padding: 88px 0 50px;
}

.single-pricing-box1 {
  position: relative;
  display: block;
  margin-bottom: 40px;
}

.single-pricing-box1 ul {
  position: relative;
  display: block;
  border: 1px solid #f2f2f2;
  padding: 0 30px 0;
}

.single-pricing-box1 ul li {
  position: relative;
  display: block;
  overflow: hidden;
  line-height: 68px;
  border-top: 1px solid #f2f2f2;
  color: #ffffff;
  z-index: 1;
}

.single-pricing-box1 ul li:first-child {
  border-top: none;
}

.single-pricing-box1 ul li.title {
  display: block;
  padding: 34px 0 14px;
}

.single-pricing-box1 ul li h3 {
  color: #333333;
  font-size: 18px;
  text-transform: uppercase;
  display: inline-block;
  float: left;
}

.single-pricing-box1 ul li h5 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin: 4px 0;
}

.single-pricing-box1 ul li h5 a {
  color: #333333;
}

.single-pricing-box1 ul li p {
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  display: inline-block;
  float: left;
}

.single-pricing-box1 ul li span:before {
  position: relative;
  color: #d0d0d0;
  font-size: 28px;
  line-height: 28px;
  display: inline-block;
  float: left;
  padding-right: 19px;
  margin: 20px 0;
}

.single-pricing-box1 ul li b {
  position: relative;
  float: right;
  color: #999999;
  font-size: 16px;
  font-weight: 400;
}

/*** 
=============================================
    Pricing Table2 Area style
=============================================
***/
.pricing-table2-area {
  position: relative;
  display: block;
  background: #151515;
  padding: 85px 0 50px;
}

.title-box.center:before {
  display: none;
}

.title-box.center h3 {
  position: relative;
  display: inline-block;
  padding-left: 16px;
  padding-right: 16px;
  color: #ffffff;
}

.title-box.center h3:before {
  position: absolute;
  top: 2px;
  left: 0;
  width: 6px;
  height: 13px;
  background: #f3441e;
  content: "";
}

.title-box.center h3:after {
  position: absolute;
  top: 2px;
  right: 0;
  width: 6px;
  height: 13px;
  background: #f3441e;
  content: "";
}

.pricing-box2 {
  margin: 0;
}

.single-pricing-box2 {
  position: relative;
  display: block;
  padding: 0;
  margin-bottom: 40px;
  background: #1b1b1b;
}

.single-pricing-box2.bg-change {
  background: #202020;
}

.single-pricing-box2 .title {
  position: relative;
  display: block;
  background: #202020;
  padding: 36px 30px 13px;
}

.single-pricing-box2 .title.bg-one {
  background: #202020;
}

.single-pricing-box2 .title.bg-two {
  background: #1b1b1b;
}

.single-pricing-box2 .title h4 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border-bottom: 1px solid #282828;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.single-pricing-box2 .title span {
  color: #f3441e;
}

.single-pricing-box2 ul {
  position: relative;
  display: block;
  padding: 27px 30px 25px;
}

.single-pricing-box2 ul li {
  line-height: 40px;
  color: #848484;
}

.single-pricing-box2 ul li i {
  color: #444444;
  font-size: 14px;
  display: inline-block;
  padding-right: 13px;
}

/*** 
=============================================
    Pricing Table3 Area style
=============================================
***/
.pricing-table3-area {
  position: relative;
  display: block;
  padding: 88px 0 50px;
}

.single-pricing-box3 {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 40px;
  box-shadow: 0px 0px 5px 2px #f2f2f2;
}

.single-pricing-box3.one {
  position: relative;
  display: block;
  z-index: 1;
}

.single-pricing-box3.one:before {
  position: absolute;
  bottom: 0px;
  right: -100px;
  font-family: "Flaticon";
  content: "\f10c";
  font-size: 200px;
  line-height: 170px;
  color: #f5f5f5;
  font-weight: 900;
  z-index: -1;
}

.single-pricing-box3.two {
  position: relative;
  display: block;
  z-index: 1;
}

.single-pricing-box3.two:before {
  position: absolute;
  bottom: 0px;
  right: -100px;
  font-family: "Flaticon";
  content: "\f10b";
  font-size: 200px;
  line-height: 170px;
  color: #f5f5f5;
  font-weight: 900;
  z-index: -1;
}

.single-pricing-box3.three {
  position: relative;
  display: block;
  z-index: 1;
}

.single-pricing-box3.three:before {
  position: absolute;
  bottom: 0px;
  right: -36px;
  font-family: "Flaticon";
  content: "\f10a";
  font-size: 160px;
  line-height: 160px;
  color: #f5f5f5;
  font-weight: 900;
  z-index: -1;
}

.single-pricing-box3 .inner-content {
  position: relative;
  display: block;
  padding: 0 40px;
}

.single-pricing-box3 .top {
  position: relative;
  display: block;
  padding: 30px 0 0;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 23px;
  margin-bottom: 17px;
}

.single-pricing-box3 .top .icon {
  width: 60px;
}

.single-pricing-box3 .top .icon span:before {
  color: #82879b;
  font-size: 40px;
  line-height: 40px;
}

.single-pricing-box3 .top .icon,
.single-pricing-box3 .top .title {
  display: table-cell;
  vertical-align: middle;
}

.single-pricing-box3 .top .title h3 {
  color: #2f2f2f;
  font-size: 20px;
  margin: 0 0 5px;
}

.single-pricing-box3 ul {
  position: relative;
  display: block;
  margin-bottom: 26px;
}

.single-pricing-box3 ul li {
  line-height: 40px;
}

.single-pricing-box3 ul li i {
  color: #d0d0d0;
  font-size: 14px;
  display: inline-block;
  padding-right: 13px;
}

.single-pricing-box3 .value {
  position: relative;
  display: inline-block;
  color: #303030;
  font-size: 72px;
  font-weight: 400;
  font-family: 'Titillium Web', sans-serif;
  line-height: 50px;
  margin-left: 20px;
}

.single-pricing-box3 .value h2 {
  position: absolute;
  bottom: -2px;
  left: -22px;
}

.single-pricing-box3 .value h3 {
  position: absolute;
  top: 30px;
  right: -70px;
  color: #303030;
  font-size: 20px;
  font-weight: 400;
}

.single-pricing-box3 .button a.btn-two {
  background: #f3441e;
  display: block;
  text-align: center;
  line-height: 60px;
  margin: 33px 0 0;
}

/*** 
=============================================
    Blog Default Area style
=============================================
***/
#blog-area {
  padding: 90px 0 90px;
}

/*** 
=============================================
    Blog large Area style
=============================================
***/
.blog-large-area .blog-post {
  position: relative;
  display: block;
  z-index: 1;
}

.blog-large-area .blog-post:before {
  position: absolute;
  top: -90px;
  bottom: -90px;
  right: -30px;
  width: 1px;
  content: "";
  z-index: -1;
  border-left: 1px solid #f2f2f2;
}

.blog-large-area .single-blog-post .text-holder {
  padding-left: 30px;
  padding-right: 0px;
  margin-top: 22px;
  border: none;
}

.blog-large-area .single-blog-post .text-holder .blog-title {
  display: block;
  line-height: 36px;
  font-size: 30px;
  padding: 0;
}

.blog-large-area .single-blog-post .text-holder .blog-title a {
  color: #2f2f2f;
}

.blog-large-area .single-blog-post .text-holder .blog-title a:hover {
  color: #f3441e;
}

.blog-large-area .single-blog-post .text-holder .meta-box .meta-info {
  padding-top: 13px;
  text-align: left;
}

.blog-large-area .single-blog-post .text-holder .text {
  display: block;
  margin-top: 28px;
}

.blog-large-area .single-blog-post .text-holder .text p {
  margin: 0 0 33px;
}

.blog-large-area .single-blog-post .text-holder .text a {
  position: relative;
  display: inline-block;
  background: #f5f5f5;
  color: #2f2f2f;
  width: 180px;
  height: 60px;
  line-height: 60px;
  border-radius: 4px;
  text-align: center;
}

.blog-large-area .single-blog-post .text-holder .text a span:before {
  font-weight: 600;
  padding-left: 40px;
}

.blog-large-area .single-blog-post .text-holder .text a:hover {
  background: #151515;
  color: #ffffff;
}

.blog-large-area .post-pagination {
  display: block;
  margin-top: 0;
  border-top: 1px solid #f2f2f2;
  padding-top: 30px;
}

#blog-area .sidebar-wrapper {
  position: relative;
  padding-left: 30px;
}

.sidebar-wrapper .single-sidebar {
  overflow: hidden;
  margin-bottom: 50px;
  position: relative;
}

.sidebar-wrapper .single-sidebar.martop-minus {
  margin-top: -7px;
}

.sidebar-wrapper .single-sidebar .title-box {
  display: block;
  margin-top: -2px;
}

.sidebar-wrapper .single-sidebar form.search-form {
  position: relative;
  margin-top: -12px;
}

.sidebar-wrapper .single-sidebar .search-form input[type="text"] {
  background: #fff;
  border: none;
  border-bottom: 1px solid #f2f2f2;
  color: #bebebe;
  display: block;
  font-size: 16px;
  font-weight: 400;
  height: 37px;
  letter-spacing: 1px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  transition: all 500ms ease 0s;
  width: 100%;
}

.sidebar-wrapper .single-sidebar .search-form button {
  position: relative;
  display: block;
  background: #ffffff;
  color: #303030;
  height: 45px;
  width: 45px;
  border: 1px solid #f2f2f2;
  line-height: 45px;
  text-align: center;
  border-radius: 3px;
  margin-top: 20px;
  transition: all 500ms ease 0s;
}

.sidebar-wrapper .single-sidebar .search-form button i {
  font-size: 14px;
}

.sidebar-wrapper .single-sidebar .search-form input[type="text"]:focus {
  border-bottom: 1px solid #f3441e;
  background: #fff;
  color: #000;
}

.sidebar-wrapper .single-sidebar .search-form input[type="text"]:focus + button,
.sidebar-wrapper .single-sidebar .search-form button:hover {
  background: #f3441e none repeat scroll 0 0;
  border-color: #f3441e;
  color: #fff;
}

.single-sidebar .recent-post {
  position: relative;
  display: block;
  margin-top: -5px;
}

.single-sidebar .recent-post li {
  position: relative;
  display: block;
  margin-bottom: 23px;
}

.single-sidebar .recent-post li:last-child {
  margin-bottom: 0;
}

.single-sidebar .recent-post li .post-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin: 0 0 9px;
  transition: all 500ms ease;
}

.single-sidebar .recent-post li .post-title a {
  position: relative;
  color: #303030;
  transition: all 500ms ease;
}

.single-sidebar .recent-post li .post-title a:before {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0px;
  content: "\f115";
  font-family: "Flaticon";
  color: #f3441e;
  font-size: 30px;
  line-height: 30px;
  opacity: 0;
  transition: all 500ms ease;
  font-weight: 500;
}

.single-sidebar .recent-post li .post-title a:hover:before {
  opacity: 1;
}

.single-sidebar .recent-post li .post-title a:hover {
  color: #f3441e;
  padding-left: 45px;
}

.single-sidebar .recent-post li .post-date {
  color: #848484;
  font-size: 16px;
  font-weight: 400;
  display: block;
  transition: all 500ms ease;
}

.single-sidebar .categories {
  position: relative;
  display: block;
  border: 1px solid #f2f2f2;
  border-bottom: none;
}

.single-sidebar .categories li {
  position: relative;
  display: block;
  border-bottom: 1px solid #f2f2f2;
  line-height: 53px;
}

.single-sidebar .categories li:last-child {
  margin-bottom: 0;
}

.single-sidebar .categories li a {
  position: relative;
  display: block;
  padding-left: 20px;
  color: #848484;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  transition: all 500ms ease;
}

.single-sidebar .categories li a:before {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 20px;
  content: "\f105";
  font-family: FontAwesome;
  color: #f3441e;
  font-size: 22px;
  opacity: 0;
  transition: all 500ms ease;
}

.single-sidebar .categories li:hover a:before {
  opacity: 1;
}

.single-sidebar .categories li a:hover {
  color: #f3441e;
  padding-left: 40px;
}

.single-sidebar .popular-tag {
  position: relative;
  display: block;
  overflow: hidden;
  margin-top: -3px;
}

.single-sidebar .popular-tag li {
  display: inline-block;
  float: left;
  margin-right: 18px;
  margin-bottom: 15px;
}

.single-sidebar .popular-tag li a {
  color: #848484;
  display: block;
  font-size: 16px;
  font-weight: 400;
  transition: all 500ms ease 0s;
}

.single-sidebar .popular-tag li.big a {
  font-size: 24px;
}

.single-sidebar .popular-tag li a:hover {
  color: #f3441e;
}

.single-sidebar.martop-minus22 {
  margin-top: -22px;
}

.single-sidebar .social-links {
  display: block;
  line-height: 16px;
}

.single-sidebar .social-links li {
  display: inline-block;
  float: left;
  margin-right: 13px;
}

.single-sidebar .social-links:last-child {
  margin: 0;
}

.single-sidebar .social-links li a i {
  color: #848484;
  font-size: 14px;
  transition: all 500ms ease;
}

.single-sidebar .social-links li a:hover i {
  color: #ff612a;
}

/*** 
=============================================
    Blog Single Area style
=============================================
***/
.blog-single-area .blog-post {
  position: relative;
  display: block;
}

.blog-single-area .blog-post:before {
  position: absolute;
  top: -90px;
  bottom: -90px;
  right: -30px;
  width: 1px;
  content: "";
  z-index: -1;
  border-left: 1px solid #f2f2f2;
}

.blog-single-area .single-blog-post.style2 {
  margin-bottom: 35px;
}

.blog-single-area .single-blog-post.style2:before {
  display: none;
}

.blog-single-area .single-blog-post.style2 .text-holder {
  padding: 0;
  margin: 0;
  border: none;
}

.blog-single-area .single-blog-post.style2 .text-holder .date-box {
  position: relative;
  display: block;
  width: 100px;
  height: 30px;
  background: #ffffff;
  text-align: center;
  z-index: 3;
  border: 1px solid #f2f2f2;
}

.blog-single-area .single-blog-post.style2 .text-holder .date-box span {
  color: #2f2f2f;
  line-height: 30px;
}

.blog-single-area .single-blog-post.style2 .text-holder .blog-title {
  display: block;
  margin: 23px 0 0;
}

.blog-single-area .single-blog-post.style2 .text-holder .meta-box .meta-info {
  display: block;
  padding: 13px 0 23px;
}

.author-box {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 40px;
}

.author-box .author-image {
  width: 60px;
}

.author-box .author-image img {
  border-radius: 50%;
}

.author-box .author-image,
.author-box .author-name {
  display: table-cell;
  vertical-align: middle;
}

.author-box .author-name {
  padding-left: 20px;
}

.author-box .author-name h3 {
  color: #2f2f2f;
  font-size: 18px;
  margin: 0 0 4px;
}

.author-box .author-name span {
  color: #848484;
}

.blog-single-area .single-blog-post.style2 .img-holder img {
  transition: none;
}

.blog-single-area .single-blog-post.style2:hover .img-holder img {
  transform: scale(1);
}

.blog-single-area .single-blog-post.style2 .text {
  position: relative;
  display: block;
  margin-top: 35px;
}

.highlight-box {
  position: relative;
  display: block;
  margin-bottom: 68px;
}

.highlight-box .inner-content {
  position: relative;
  display: block;
  border-left: 2px solid #f3441e;
  padding: 33px 30px 34px;
  box-shadow: 0px 0px 4px 3px #f7f7f7;
}

.highlight-box .inner-content p {
  color: #2f2f2f;
  font-size: 18px;
  line-height: 28px;
  text-decoration: underline;
  margin: 0;
}

.author-caption-box {
  position: relative;
  display: block;
  margin-bottom: 66px;
}

.author-caption-box .text-holder {
  position: relative;
  display: block;
  margin-top: -5px;
}

.author-caption-box .text-holder p {
  margin: 0 0 14px;
}

.author-caption-box .text-holder ul {
  display: block;
}

.author-caption-box .text-holder ul li {
  line-height: 32px;
}

.author-caption-box .text-holder ul li i {
  color: #f3441e;
  font-size: 18px;
  display: inline-block;
  padding-right: 9px;
}

.accordion-content .accordion-box .accordion {
  position: relative;
  background: #ffffff;
  margin-bottom: 10px;
  border-radius: 0;
  display: block;
  padding: 0 30px;
  border: 1px solid #f2f2f2;
}

.accordion-content .accordion-box .accordion .accord-btn {
  position: relative;
  display: block;
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 21px;
  padding-bottom: 22px;
}

.accordion-content .accordion-box .accordion .accord-btn::before {
  position: absolute;
  right: 0;
  top: 20px;
  color: #e6e6e6;
  content: "\f123";
  font-family: "Flaticon";
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  transition: all 500ms ease 0s;
  text-align: center;
  display: block;
  width: 13px;
  height: 30px;
  transform: rotate(0deg);
}

.accordion-content .accordion-box .accordion .accord-btn.active::before {
  color: #f3441e;
  transform: rotate(90deg);
}

.accordion-content .accordion-box .accordion .accord-btn.active:after {
  color: #f3441e;
}

.accordion-content .accordion-box .accordion .accord-btn.active h4 {
  color: #f3441e;
}

.tag-box.social-share-box {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 20px 0 0;
}

.tag-box.social-share-box .tag p {
  color: #f3441e;
  margin: 5px 0;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.tag-box.social-share-box .tag p span {
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Titillium Web', sans-serif;
}

.tag-box.social-share-box .title {
  display: inline-block;
  float: left;
  padding-right: 15px;
  margin: 6px 0 5px;
  color: #303030;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Titillium Web', sans-serif;
  text-transform: uppercase;
}

.tag-box.social-share-box .title i {
  display: inline-block;
  padding-left: 3px;
  color: #f3441e;
}

.tag-box.social-share-box .sociallinks-style-two li {
  display: inline-block;
  float: left;
  margin-right: 10px;
}

.tag-box.social-share-box .sociallinks-style-two li:last-child {
  margin: 0;
}

.tag-box.social-share-box .sociallinks-style-two li a i {
  position: relative;
  display: block;
  border: none;
  background: #3a559f;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  height: 35px;
  width: 35px;
  line-height: 36px;
  padding: 0;
  text-align: center;
  transition: all 500ms ease 0s;
}

.tag-box.social-share-box .sociallinks-style-two li a:hover i {
  background: #2f478a;
}

.tag-box.social-share-box .sociallinks-style-two li.twitter a i {
  background: #55acee;
}

.tag-box.social-share-box .sociallinks-style-two li.twitter a:hover i {
  background: #3b92d4;
}

.tag-box.social-share-box .sociallinks-style-two li.youtube a i {
  background: #e02f2f;
}

.tag-box.social-share-box .sociallinks-style-two li.youtube a:hover i {
  background: #c91e1e;
}

.blog-prev-next-option {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  margin: 27px 0 68px;
  border-top: 1px solid #f2f2f2;
  padding-top: 30px;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 30px;
}

.blog-prev-next-option:before {
  position: absolute;
  left: 50%;
  top: 30px;
  bottom: 30px;
  width: 1px;
  background: #f2f2f2;
  content: "";
}

.blog-prev-next-option .single {
  position: relative;
  display: block;
  width: 50%;
}

.blog-prev-next-option .single.prev {
  position: relative;
  display: block;
}

.blog-prev-next-option .single.prev .title {
  padding-right: 80px;
}

.blog-prev-next-option .single .title {
  display: inline-block;
  float: left;
  margin-top: -5px;
}

.blog-prev-next-option .single .title h4 {
  color: #303030;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 14px;
}

.blog-prev-next-option .single .title a {
  color: #848484;
  font-size: 18px;
  font-weight: 500;
  transition: all 500ms ease;
  font-family: 'Titillium Web', sans-serif;
}

.blog-prev-next-option .single .title a:hover {
  color: #f3441e;
}

.blog-prev-next-option .single .title a i {
  display: inline-block;
  padding-right: 5px;
  font-size: 18px;
}

.blog-prev-next-option .single.next {
  position: relative;
  display: block;
  float: right;
}

.blog-prev-next-option .single.next .title {
  text-align: right;
  padding-left: 80px;
}

.blog-prev-next-option .single.next .title a i {
  display: inline-block;
  padding-left: 5px;
  font-size: 18px;
}

.add-comment-box {
  overflow: hidden;
}

.add-comment-box .add-rating-box {
  padding-bottom: 17px;
}

.add-comment-box .add-rating-box h4 {
  color: #848484;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 6px;
}

.add-comment-box .add-rating-box ul li {
  border-right: 1px solid #ebebeb;
  display: inline-block;
  line-height: 20px;
  margin-right: 6px;
  padding-right: 10px;
}

.add-comment-box .add-rating-box ul li:last-child {
  border-right: none;
  margin-right: 0;
  padding-right: 0;
}

.add-comment-box .add-rating-box ul li a i {
  color: #d6d6d6;
  font-size: 12px;
}

.add-comment-box .add-rating-box ul li a:hover i {
  color: #f3441e;
  transition: all 500ms ease;
}

.add-comment-box #add-comment-form .field-label {
  color: #848484;
  display: block;
  font-size: 14px;
  font-weight: 400;
  padding: 0 0 2px;
}

.add-comment-box #add-comment-form input[type="text"],
.add-comment-box #add-comment-form input[type="email"],
.add-comment-box #add-comment-form textarea {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ececec;
  display: block;
  font-size: 14px;
  font-weight: 400;
  height: 50px;
  padding: 0 15px;
  width: 100%;
  margin-bottom: 14px;
  transition: all 500ms ease;
}

.add-comment-box #add-comment-form textarea {
  height: 100px;
  padding: 10px 15px;
  margin-bottom: 30px;
}

.add-comment-box #add-comment-form input[type="text"]:focus {
  border-color: #f3441e;
}

.add-comment-box #add-comment-form input[type="email"]:focus {
  border-color: #f3441e;
}

.add-comment-box #add-comment-form textarea:focus {
  border-color: #f3441e;
}

.add-comment-box #add-comment-form button {
  position: relative;
  display: inline-block;
  background: #f3441e;
  color: #ffffff;
  width: 180px;
  height: 60px;
  line-height: 60px;
  border-radius: 4px;
}

.add-comment-box #add-comment-form button span:before {
  font-weight: 500;
  padding-left: 19px;
}

/*** 
=============================================
    Latest project area style
=============================================
***/
.welcome-area.about-page {
  position: relative;
  display: block;
  padding-top: 90px;
  padding-bottom: 90px;
}

.welcome-area.about-page:before {
  display: none;
}

.health-coacher {
  position: relative;
}

.health-coacher .text-box {
  display: block;
  overflow: hidden;
}

.health-coacher .text-box .coacher-name {
  display: block;
  overflow: hidden;
  padding-bottom: 29px;
}

.health-coacher .text-box .coacher-name h2 {
  font-size: 24px;
  line-height: 30px;
  margin: 0 0 3px;
}

.health-coacher .text-box .coacher-content {
  display: block;
  margin-bottom: 27px;
}

.health-coacher .text-box .dwn-biography {
  display: block;
}

.health-coacher .bottom-content {
  display: block;
  overflow: hidden;
}

.health-coacher .bottom-content ul {
  display: block;
  overflow: hidden;
  margin-top: 40px;
}

.health-coacher .bottom-content ul li {
  display: inline-block;
  float: left;
  max-width: 385px;
  width: 100%;
  border-right: 1px solid #f6f4f1;
  padding-right: 40px;
  padding-left: 40px;
}

.health-coacher .bottom-content ul li:first-child {
  padding-left: 0;
}

.health-coacher .bottom-content ul li:last-child {
  padding-right: 0;
  border: none;
}

.health-coacher .bottom-content ul li .top {
  position: relative;
  display: block;
  padding-left: 100px;
  min-height: 40px;
}

.health-coacher .bottom-content ul li .top .year {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 40px;
  display: block;
  text-align: center;
  border: 1px solid #f6f4f1;
}

.health-coacher .bottom-content ul li .top .year h2 {
  color: #919191;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
}

.health-coacher .bottom-content ul li .top .title h3 {
  padding: 8px 0;
}

.health-coacher .bottom-content ul li .text {
  display: block;
  padding-top: 25px;
}

/*** 
=============================================
    Commitments Area css style
=============================================
***/
.commitments-area {
  background-attachment: fixed;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 90px;
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
}

.commitments-area .sec-title .title:before {
  color: #2d4234;
}

.commitments-area::before {
  background: rgba(3, 26, 6, 0.85) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.single-commitment-item {
  display: block;
  width: 290px;
  margin: 0 auto 40px;
}

.single-commitment-item .img-holder {
  position: relative;
  overflow: hidden;
  display: block;
}

.single-commitment-item .img-holder img {
  transition: all 0.5s ease-in-out 0.6s;
  width: 100%;
}

.single-commitment-item .statics-overlay {
  position: absolute;
  top: 65px;
  left: 65px;
  right: 65px;
  bottom: 65px;
  opacity: 1;
  border-radius: 50%;
  transition: all 500ms ease;
  transform: scale(1);
  background: #ffffff;
}

.single-commitment-item:hover .statics-overlay {
  transform: scale(0);
}

.single-commitment-item .statics-overlay .box {
  display: table;
  height: 100%;
  width: 100%;
}

.single-commitment-item .statics-overlay .box .title {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.single-commitment-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  border-radius: 50%;
  transition: all 900ms ease;
  transform: scale(0);
  background: rgba(56, 172, 73, 0.9);
}

.single-commitment-item:hover .overlay {
  transform: scale(1);
}

.single-commitment-item .overlay .box {
  display: table;
  height: 100%;
  width: 100%;
}

.single-commitment-item .overlay .box .content {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 20px;
}

.single-commitment-item .overlay .box .content p {
  color: #ffffff;
  margin: 0;
}

/*** 
=============================================
    Choosing area style
=============================================
***/
.choosing-area {
  display: block;
  background: #f6f4f1;
  padding: 90px 0 49px;
}

.choosing-area .top {
  display: block;
  padding-bottom: 16px;
  border-bottom: 1px solid #ebe8e5;
  margin-bottom: 54px;
}

.choosing-area .top .left h3 {
  line-height: 40px;
}

.choosing-area .top .left h3 span {
  color: #35ac46;
  font-size: 48px;
  font-weight: 400;
}

.choosing-area .top .right a {
  margin: 13px 0;
  display: block;
}

.choosing-area .bottom {
  display: block;
}

.choosing-area .bottom ul li {
  display: inline-block;
  float: left;
  max-width: 195px;
  width: 100%;
  margin: 0 auto 35px;
  text-align: center;
}

.choosing-area .bottom ul li .icon-holder {
  display: block;
  margin-bottom: 20px;
}

.choosing-area .bottom ul li .icon-holder span:before {
  font-size: 60px;
  line-height: 60px;
  color: #dcdad7;
  transition: all 300ms ease;
}

.choosing-area .bottom ul li:hover .icon-holder span:before {
  color: #35ac46;
}

.choosing-area .bottom ul li .title h3 {
  color: #dcdad7;
  line-height: 24px;
  transition: all 700ms ease;
}

.choosing-area .bottom ul li:hover .title h3 {
  color: #303030;
}

/*** 
=============================================
    Achivements area style
=============================================
***/
.achivements-area {
  display: block;
  padding-bottom: 44px;
}

.single-achivement {
  position: relative;
  display: block;
  width: 230px;
  margin: 0 auto 40px;
}

.single-achivement .iocn-holder {
  display: block;
  padding-bottom: 6px;
}

.single-achivement .iocn-holder span:before {
  font-size: 120px;
  line-height: 120px;
  color: #ebe8e5;
  transition: all 500ms ease;
}

.single-achivement:hover .iocn-holder span:before {
  color: #35ac46;
}

.single-achivement .text-holder {
  display: block;
}

.single-achivement .text-holder p {
  margin: 0;
}

.filters {
  position: relative;
  display: block;
  margin-bottom: 40px;
}

.filters ul {
  display: block;
}

.filters ul li {
  float: none;
  display: inline-block;
  padding: 11px 19px;
  border-right: 1px solid #f6f4f1;
}

.filters ul li:first-child {
  border-left: 1px solid #f6f4f1;
}

.filters ul li span {
  position: relative;
  color: #1c262f;
  cursor: pointer;
  display: block;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  text-transform: capitalize;
  transition: all 500ms ease 0s;
  font-family: 'Rubik', sans-serif;
  display: block;
}

.filters ul li.active span,
.filters ul li:hover span {
  color: #35ac46;
}

.project-content {
  position: relative;
  display: block;
}

.grid-view .single-project-item {
  margin-bottom: 30px;
}

.single-project-item .img-holder .overlay-style-one .box .content .video-gallery {
  position: absolute;
  left: 30px;
  top: 30px;
}

.single-project-item .img-holder .overlay-style-one .box .content .video-gallery a span:before {
  font-size: 40px;
  line-height: 40px;
  color: #ffffff;
  opacity: 0;
  transition: all 0.3s ease-in-out 0.6s;
}

.single-project-item:hover .img-holder .overlay-style-one .box .content .video-gallery a span:before {
  opacity: 1;
}

.single-project-item .img-holder .title-holder {
  position: absolute;
  left: 30px;
  bottom: 24px;
  right: 0;
  z-index: 2;
}

.single-project-item .img-holder .title-holder h3 {
  transform: rotateX(360deg);
  transition: all 500ms ease;
}

.single-project-item:hover .img-holder .title-holder h3 {
  transform: rotateX(0deg);
}

.single-project-item .img-holder .title-holder h3 a {
  color: #ffffff;
  transition: all 500ms ease;
}

.single-project-item .img-holder .title-holder h3 a:hover {
  text-decoration: underline;
}

/*** 
=============================================
    Gallery Fullwidth style
=============================================
***/
.gallery-area.gallery-fullwidth {
  padding-top: 50px;
}

.gallery-fullwidth .container-fluid {
  padding: 0;
}

.gallery-fullwidth .project-content {
  margin: 0;
}

.gallery-fullwidth .project-content .single-project {
  padding: 0;
}

.gallery-fullwidth .single-project-item .img-holder .title-holder h3 {
  transform: rotateX(90deg);
  transition: all 500ms ease;
  opacity: 0;
  transition: all 0.3s ease-in-out 0.6s;
}

.gallery-fullwidth .single-project-item:hover .img-holder .title-holder h3 {
  transform: rotateX(0deg);
  opacity: 1;
}

.gallery-fullwidth .readmore-button {
  display: block;
  margin-top: 40px;
}

/*** 
=============================================
    Gallery Single Area style
=============================================
***/
.gallery-area.gallery-single {
  position: relative;
  display: block;
}

.gallery-single .owl-theme .owl-nav {
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
}

.gallery-single .owl-theme .owl-nav .owl-prev {
  background: rgba(0, 0, 0, 0.35);
  display: block;
  height: 40px;
  position: absolute;
  left: -5px;
  top: -35px;
  transition: all 700ms ease 0s;
  width: 40px;
  z-index: 10;
  border-radius: 0;
  box-shadow: none;
}

.gallery-single .owl-theme .owl-nav .owl-next {
  background: rgba(0, 0, 0, 0.35);
  display: block;
  height: 40px;
  position: absolute;
  right: -5px;
  top: -35px;
  transition: all 700ms ease 0s;
  width: 40px;
  z-index: 10;
  border-radius: 0;
  box-shadow: none;
}

.gallery-single .owl-theme .owl-nav div .fa {
  color: #ececec;
  font-size: 25px;
  display: block;
  text-align: center;
  line-height: 32px;
}

.gallery-single .owl-theme .owl-nav .owl-prev:hover {
  background: rgba(0, 0, 0, 0.95);
}

.gallery-single .owl-theme .owl-nav .owl-next:hover {
  background: rgba(0, 0, 0, 0.95);
}

.gallery-single .owl-theme .owl-nav div .fa:hover {
  color: #fff;
}

.gallery-single .description-content {
  overflow: hidden;
  display: block;
  padding-top: 35px;
  padding-bottom: 38px;
}

.gallery-single .description-content .title {
  display: block;
  padding-bottom: 19px;
}

.gallery-single .description-content .title h2 {
  font-size: 24px;
  text-transform: uppercase;
}

.gallery-single .bottom {
  border-bottom: 1px solid #f7f7f7;
  border-top: 1px solid #f7f7f7;
  margin: 0 0 0;
  overflow: hidden;
  padding-bottom: 20px;
  padding-top: 21px;
}

.gallery-single .bottom .button a {
  color: #222222;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 500ms ease;
}

.gallery-single .bottom .button a:hover {
  color: #35ac46;
}

.gallery-single .bottom .button a i {
  display: inline-block;
  font-size: 18px;
  padding-right: 10px;
  position: relative;
  top: 1px;
}

.gallery-single .bottom .button.next a i {
  padding-right: 0px;
  padding-left: 10px;
}

.gallery-single .bottom .icon-holder a i {
  color: #222222;
  font-size: 24px;
  position: relative;
  top: 4px;
  transition: all 500ms ease 0s;
}

.gallery-single .bottom .icon-holder a:hover i {
  color: #35ac46;
}

.gallery-info {
  display: block;
  background: #35ac46;
  padding: 22px 40px 20px;
}

.gallery-info ul li {
  line-height: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  color: #ffffff;
}

.gallery-info ul li:last-child {
  border: none;
}

.gallery-info ul li b {
  width: 100px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  font-family: 'Rubik', sans-serif;
}

.gallery-info ul li span {
  display: inline-block;
  padding-left: 25px;
}

.gallery-info ul li .rating-box {
  display: inline-block;
  padding-left: 25px;
}

.gallery-info ul li .rating-box a {
  color: #ffffff;
  font-size: 14px;
  margin-right: 3px;
}

/*** 
=============================================
    Coming Soon Area style
=============================================
***/
.coming-soon-area {
  background-attachment: fixed;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 139px;
  padding-bottom: 142px;
  position: relative;
  z-index: 10;
}

.coming-soon-area::before {
  background: rgba(0, 0, 0, 0.85) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.coming-soon-content .big-title {
  color: #ffffff;
  font-size: 72px;
  font-weight: 700;
  line-height: 80px;
  font-family: 'Rubik', sans-serif;
}

.coming-soon-content .title {
  font-size: 48px;
  font-weight: 400;
  line-height: 50px;
  font-family: 'Caveat', cursive;
  margin: 6px 0 53px;
}

.timer-box {
  display: block;
  overflow: hidden;
}

.countdown-timer .default-coundown .box {
  overflow: hidden;
}

.countdown-timer li {
  position: relative;
  display: inline-block;
  float: none;
  text-align: center;
  margin: 0px 32px;
}

.countdown-timer li span.days,
.countdown-timer li span.hours,
.countdown-timer li span.minutes,
.countdown-timer li span.seconds {
  position: relative;
  font-family: 'Caveat', cursive;
  font-size: 60px;
  line-height: 120px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  width: 130px;
  height: 130px;
  display: block;
}

.countdown-timer li span.days:before,
.countdown-timer li span.hours:before,
.countdown-timer li span.minutes:before,
.countdown-timer li span.seconds:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 130px;
  height: 130px;
  background: url(../images/icon/countdown-li-bg.png);
  background-repeat: no-repeat;
  content: "";
}

.countdown-timer li span.timeRef {
  font-family: 'Rubik', sans-serif;
  font-size: 30px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 400;
  text-transform: capitalize;
  margin-top: 18px;
  display: block;
}

.coming-soon-content .subscribe-form form {
  position: relative;
  width: 100%;
  max-width: 370px;
  margin: 0 auto;
  margin-top: 49px;
}

.coming-soon-content .subscribe-form form input[type=text] {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: #39413a;
  display: block;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  height: 50px;
  padding-left: 20px;
  padding-right: 160px;
  position: relative;
  transition: all 500ms ease 0s;
  max-width: 100%;
  width: 100%;
  border-radius: 30px;
  font-family: 'Merriweather', serif;
}

.coming-soon-content .subscribe-form form button {
  display: block;
  height: 50px;
  padding: 14px 0;
  position: absolute;
  right: 0px;
  text-align: center;
  top: 0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 500ms ease 0s;
  width: 150px;
  border-radius: 30px;
  font-family: 'Rubik', sans-serif;
}

.coming-soon-content .subscribe-form form input[type=text]:focus {
  border: 1px solid rgba(255, 255, 255, 0.45);
  color: #ffff;
}

.coming-soon-content .subscribe-form form input[type=text]:focus + button,
.coming-soon-content .subscribe-form form button:hover {
  background: #0c0c0c none repeat scroll 0 0;
  color: #ffffff;
}

.coming-soon-content .social-links-box {
  display: block;
  margin-top: 33px;
}

.coming-soon-content .social-links-box span {
  display: inline-block;
  margin-right: 10px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
  text-transform: uppercase;
}

.coming-soon-content .social-links-box .social-links {
  display: inline-block;
}

.coming-soon-content .social-links-box .social-links li {
  display: inline-block;
  margin: 0 6px;
}

.coming-soon-content .social-links-box .social-links li a i {
  color: #ffffff;
  font-size: 14px;
}

/*** 
=============================================
    Not found area style
=============================================
***/
.not-found-area {
  background: #ffffff none repeat scroll 0 0;
  padding-bottom: 50px;
  padding-top: 50px;
}

.not-found-content {
  display: block;
  margin: 115px 0 113px;
}

.not-found-content h1 {
  color: #35ac46;
  font-size: 150px;
  font-weight: 700;
  margin: 0 0 25px;
  line-height: 120px;
}

.not-found-content h3 {
  color: #303030;
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 16px;
  text-transform: uppercase;
  font-family: 'Merriweather', serif;
}

.not-found-content p {
  font-size: 16px;
  margin: 0 0 33px;
}

.not-found-content .search-form {
  width: 432px;
  position: relative;
  margin: 0 auto;
}

.not-found-content .search-form input[type="text"] {
  background: #fff;
  border: 1px solid #f4f4f4;
  color: #9a9a9a;
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  height: 55px;
  letter-spacing: 1px;
  padding-left: 20px;
  padding-right: 60px;
  position: relative;
  -webkit-transition: all 500ms ease 0s;
  transition: all 500ms ease 0s;
  width: 432px;
}

.not-found-content .search-form button {
  background: #f9f9f9 none repeat scroll 0 0;
  border: 1px solid #f4f4f4;
  color: #1c1c1c;
  display: inline-block;
  height: 55px;
  padding: 11px 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  -webkit-transition: all 500ms ease 0s;
  transition: all 500ms ease 0s;
  width: 55px;
}

.not-found-content .search-form button i {
  font-size: 14px;
}

.not-found-content .search-form input[type="text"]:focus {
  border: 1px solid #1d1d1d;
  background: #fff;
  color: #252525;
}

.not-found-content .search-form input[type="text"]:focus + button,
.not-found-content .search-form button:hover {
  background: #1d1d1d none repeat scroll 0 0;
  color: #fff;
}

.not-found-content .go-home {
  display: block;
  margin-top: 15px;
}

.not-found-content .go-home a {
  font-size: 16px;
  font-weight: 600;
  transition: all 500ms ease;
  font-family: 'Rubik', sans-serif;
}

.not-found-content .go-home a:hover {
  color: #1d1d1d;
}

/*** 
=============================================
    Service page Area style
=============================================
***/
.services-area.service-page {
  background: #ffffff;
  padding-top: 85px;
  padding-bottom: 67px;
}

.services-area.service-page:before {
  display: none;
}

.services-area.service-page .single-service-item .inner-content .title-holder .title h3 a {
  color: #303030;
}

.services-area.service-page .single-service-item .inner-content .bottom-button a {
  color: #303030;
}

.services-area.service-page .border-box {
  background: #f6f4f1;
  width: 100%;
  height: 1px;
  display: block;
  margin-top: 16px;
  margin-bottom: 35px;
}

/*** 
=============================================
    Let Start Area style
=============================================
***/
.let-start-area {
  position: relative;
  display: block;
  padding-top: 90px;
  padding-bottom: 87px;
}

.starting-process ul {
  display: block;
  overflow: hidden;
}

.starting-process ul li {
  position: relative;
  display: inline-block;
  float: left;
  padding-right: 175px;
}

.starting-process ul li:before {
  position: absolute;
  top: 70px;
  right: 55px;
  background: url(../images/pattern/arrow.png);
  background-repeat: no-repeat;
  width: 64px;
  height: 12px;
  content: "";
}

.starting-process ul li:last-child:before {
  display: none;
}

.starting-process ul li:last-child {
  padding-right: 0;
}

.starting-process ul li .inner-box {
  position: relative;
  display: block;
  width: 160px;
  height: 155px;
}

.starting-process ul li .inner-box:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../images/pattern/starting-process-li-bg.png);
  background-repeat: no-repeat;
  content: "";
}

.starting-process ul li .inner-box .icon-holder {
  display: block;
  padding: 43px 0;
}

.starting-process ul li .inner-box .icon-holder span:before {
  font-size: 65px;
  line-height: 65px;
}

.starting-process ul li .title-holder {
  display: block;
  padding-top: 22px;
}

.starting-process .bottom-text {
  max-width: 820px;
  margin: 0 auto;
  padding-top: 58px;
  text-align: center;
}

.starting-process .bottom-text p {
  font-size: 20px;
  line-height: 28px;
  font-family: 'Rubik', sans-serif;
  margin: 0;
  color: #303030;
}

/*** 
=============================================
    Event Style One Area style
=============================================
***/
.event-style-one-area {
  position: relative;
  display: block;
  padding-top: 90px;
  padding-bottom: 50px;
}

.single-event-style1 {
  position: relative;
  display: block;
  border-bottom: 1px solid #f6f4f1;
  padding-bottom: 23px;
  margin-bottom: 40px;
}

.single-event-style1 .img-holder {
  position: relative;
  display: block;
}

.single-event-style1 .img-holder img {
  width: 100%;
}

.single-event-style1 .img-holder .date-box {
  position: absolute;
  bottom: -32px;
  left: 30px;
  width: 65px;
  height: 65px;
  background: #ffffff;
  border-radius: 50%;
  text-align: center;
  display: block;
  padding: 14px 0;
}

.single-event-style1 .img-holder .date-box h5 {
  color: #303030;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
}

.single-event-style1 .text-holder {
  display: block;
  margin-top: 41px;
}

.single-event-style1 .text-holder span {
  color: #35ac46;
  font-size: 14px;
}

.single-event-style1 .text-holder .title {
  line-height: 26px;
  display: block;
  margin-top: 7px;
}

.single-event-style1 .text-holder .title a {
  color: #303030;
  transition: all 500ms ease;
}

.single-event-style1:hover .text-holder .title a {
  color: #35ac46;
}

/*** 
=============================================
    Event Style Two Area style
=============================================
***/
.event-style-two-area {
  position: relative;
  display: block;
  background: #f6f4f1;
  padding-top: 90px;
  padding-bottom: 62px;
}

.event-style-two-area .events-box-holder {
  padding: 0px;
  margin: 0;
}

.event-style-two-area .events-box-holder:before {
  display: none;
}

/*** 
=============================================
    Event Style Two Area style
=============================================
***/
.event-style-three-area {
  position: relative;
  display: block;
  background: #ffffff;
  padding-top: 90px;
  padding-bottom: 50px;
}

.single-event-style2 {
  position: relative;
  display: block;
  border: 1px solid #f6f4f1;
  padding: 42px 50px 50px;
  margin-bottom: 40px;
  z-index: 1;
}

.single-event-style2:before {
  position: absolute;
  top: 9px;
  left: 9px;
  bottom: 9px;
  right: 9px;
  border: 1px solid #f6f4f1;
  content: "";
  z-index: -1;
}

.single-event-style2 .top {
  overflow: hidden;
  display: block;
}

.single-event-style2 .top .left-content ul li {
  margin-bottom: 28px;
}

.single-event-style2 .top .left-content ul li:last-child {
  margin-bottom: 0;
}

.single-event-style2 .top .left-content ul li span {
  text-transform: capitalize;
}

.single-event-style2 .top .left-content ul li h3 {
  font-size: 18px;
  margin: 5px 0 0;
}

.single-event-style2 .top .right-content {
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #f6f4f1;
  text-align: center;
  padding: 17px 0;
  margin-top: 8px;
}

.single-event-style2 .top .right-content h3 {
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
}

.single-event-style2 .bottom {
  overflow: hidden;
  border-top: 1px solid #f2f2f2;
  margin-top: 34px;
  padding-top: 30px;
}

.single-event-style2 .bottom a.details {
  color: #303030;
  font-size: 14px;
  font-weight: 600;
  background: #f6f4f1;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
  transition: all 500ms ease;
  display: inline-block;
  float: left;
  padding: 14px 30px 12px;
  border-radius: 30px;
}

.single-event-style2 .bottom a.details:hover {
  background: #e4e1de;
  color: #000000;
}

.single-event-style2 .bottom p {
  display: inline-block;
  padding-left: 30px;
  color: #303030;
  float: left;
  margin: 13px 0;
}

.single-event-style2 .bottom p span:before {
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  padding-right: 7px;
}

/*** 
=============================================
    Event Single Area style
=============================================
***/
.event-single-area {
  padding-top: 90px;
  padding-bottom: 90px;
}

.single-event-content .img-holder {
  position: relative;
  display: block;
}

.single-event-content .img-holder img {
  width: 100%;
}

.single-event-content .timer-box {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  padding-top: 15px;
  padding-bottom: 15px;
}

.countdown-timers .default-coundown .box {
  overflow: hidden;
  text-align: center;
}

.countdown-timers li {
  position: relative;
  display: inline-block;
  float: none;
  padding: 16px 0 0;
  width: 90px;
  height: 90px;
  border: 2px solid #35ac46;
  border-radius: 50%;
  text-align: center;
  margin: 0px 8px;
}

.countdown-timers li:last-child {
  margin-right: 0px;
}

.countdown-timers li span.days,
.countdown-timers li span.hours,
.countdown-timers li span.minutes,
.countdown-timers li span.seconds {
  position: relative;
  font-family: 'Rubik', sans-serif;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  display: block;
}

.countdown-timers li span.hours {
  color: #fff;
}

.countdown-timers li span.minutes {
  color: #fff;
}

.countdown-timers li span.seconds {
  color: #fff;
}

.countdown-timers li span.timeRef {
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  display: block;
}

.countdown-timers li span.timeRef.clr-1 {
  color: #fff;
}

.countdown-timers li span.timeRef.clr-2 {
  color: #fff;
}

.countdown-timers li span.timeRef.clr-3 {
  color: #fff;
}

.single-event-content .title {
  padding-bottom: 21px;
}

.single-event-content .title h3 {
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
}

.single-event-content .description {
  margin-top: 37px;
}

.single-event-content .event-schedule {
  border: 2px solid #f6f4f1;
  display: block;
  margin-top: 44px;
  padding: 35px 30px 28px;
  margin-bottom: 47px;
}

.single-event-content .event-schedule .text {
  display: block;
  margin-bottom: 13px;
}

.single-event-content .event-schedule .text p {
  margin: 0;
}

.single-event-content .event-schedule ul li {
  line-height: 36px;
}

.single-event-content .event-schedule ul li i {
  display: inline-block;
  padding-right: 8px;
  font-size: 18px;
}

.single-event-content .why-come .title {
  padding-bottom: 23px;
}

.single-event-content .why-come .single-box img {
  width: 100%;
}

.single-event-content .why-come .single-box .text-box {
  padding-top: 17px;
}

.single-event-content .why-come .single-box .text-box h5 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 2px;
}

.single-event-content .social-share-box {
  display: block;
  overflow: hidden;
  border-top: 1px solid #f2f2f2;
  margin-top: 42px;
  padding-top: 40px;
}

.single-event-content .social-share-box .icon {
  display: inline-block;
  float: left;
  padding-right: 20px;
  margin: 7px 0;
}

.single-event-content .social-share-box .icon span {
  font-size: 16px;
  color: #303030;
  font-weight: 600;
  font-family: 'Rubik', sans-serif;
}

.single-event-content .social-share-box .icon i {
  font-size: 16px;
  color: #303030;
  display: inline-block;
  padding-left: 5px;
}

.single-event-content .social-share-box .social-share-icon {
  display: inline-block;
  float: left;
}

.single-event-content .social-share-box .social-share-icon li {
  display: inline-block;
  float: left;
  margin-right: 10px;
  text-align: center;
}

.single-event-content .social-share-box .social-share-icon li:last-child {
  margin-right: 0;
}

.single-event-content .social-share-box .social-share-icon li a i {
  width: 40px;
  height: 40px;
  border: 2px solid #f2f2f2;
  border-radius: 50%;
  color: #848484;
  font-size: 14px;
  line-height: 36px;
  transition: all 500ms ease;
}

.single-event-content .social-share-box .social-share-icon li a:hover i {
  border-color: #f6f4f1;
  background: #f6f4f1;
  color: #303030;
}

.single-event-content .reply-box {
  display: block;
  margin-top: 40px;
}

.single-event-content .reply-box #reply-form .field-label {
  color: #848484;
  display: block;
  font-size: 14px;
  font-weight: 400;
  padding: 0 0 2px;
}

.single-event-content .reply-box #reply-form input[type="text"],
.single-event-content .reply-box #reply-form input[type="email"],
.single-event-content .reply-box #reply-form textarea {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ececec;
  display: block;
  font-size: 14px;
  font-weight: 400;
  height: 50px;
  padding: 0 15px;
  width: 100%;
  margin-bottom: 12px;
  transition: all 500ms ease;
}

.single-event-content .reply-box #reply-form textarea {
  height: 100px;
  padding: 10px 15px;
  margin-bottom: 30px;
}

.single-event-content .reply-box #reply-form input[type="text"]:focus {
  border-color: #dfddda;
}

.single-event-content .reply-box #reply-form input[type="email"]:focus {
  border-color: #dfddda;
}

.single-event-content .reply-box #reply-form textarea:focus {
  border-color: #dfddda;
}

.single-event-content .reply-box #reply-form button {
  padding: 19px 40px 17px;
}

.event-single-sidebar .single-sidebar-box {
  margin-bottom: 40px;
}

.event-single-sidebar .single-sidebar-box .title {
  padding-bottom: 18px;
}

.event-single-sidebar .single-sidebar-box .title h3 {
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}

.event-single-sidebar .single-sidebar-box .event-timeline {
  border: 1px solid #f2f2f2;
  display: block;
  padding: 33px 20px 27px;
}

.event-single-sidebar .single-sidebar-box .event-timeline ul li {
  position: relative;
  padding-left: 35px;
  display: block;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 12px;
  margin-bottom: 13px;
}

.event-single-sidebar .single-sidebar-box .event-timeline ul li:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.event-single-sidebar .single-sidebar-box .event-timeline ul li .icon-box {
  position: absolute;
  top: 5px;
  left: 0;
}

.event-single-sidebar .single-sidebar-box .event-timeline ul li .icon-box span:before {
  font-size: 20px;
  line-height: 20px;
}

.event-single-sidebar .single-sidebar-box .event-timeline ul li .text-box h4 {
  color: #848484;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  font-family: 'Rubik', sans-serif;
}

.event-single-sidebar .single-sidebar-box .event-timeline ul li .text-box h4 span {
  font-weight: 500;
  color: #303030;
}

#sidebar-google-map {
  width: 100%;
  height: 310px;
}

.event-single-sidebar .single-sidebar-box .book-now .title {
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  display: block;
  padding: 16px 0 16px;
  text-align: center;
}

.event-single-sidebar .single-sidebar-box .book-now .inner-content {
  display: block;
  background: #f6f4f1;
  padding: 32px 30px 36px;
}

.event-single-sidebar .single-sidebar-box .book-now .inner-content ul li {
  border-bottom: 1px solid #ebe8e5;
  padding-bottom: 13px;
  margin-bottom: 12px;
}

.event-single-sidebar .single-sidebar-box .book-now .inner-content ul li p {
  color: #303030;
  font-size: 18px;
  line-height: 30px;
  margin: 0;
}

.event-single-sidebar .single-sidebar-box .book-now .inner-content ul li h3 {
  font-weight: 500;
}

.event-single-sidebar .single-sidebar-box .book-now .inner-content .quantity-box span {
  color: #303030;
  font-size: 18px;
  display: inline-block;
  padding-right: 15px;
}

.event-single-sidebar .single-sidebar-box .book-now .inner-content .quantity-box input[type="text"] {
  width: 35px;
  height: 24px;
  text-align: center;
  color: #848484;
  font-size: 14px;
  border: none;
  border-radius: 3px;
}

.event-single-sidebar .single-sidebar-box .book-now .inner-content .button-box {
  display: block;
  margin-top: 30px;
  overflow: hidden;
}

.event-single-sidebar .single-sidebar-box .book-now .inner-content .button-box button {
  display: inline-block;
  float: left;
  padding: 13px 30px 11px;
}

.event-single-sidebar .single-sidebar-box .book-now .inner-content .button-box p {
  color: #848484;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  display: inline-block;
  float: left;
  padding-left: 15px;
}

/*** 
=============================================
   Shop area  style
=============================================
***/
#shop-area {
  padding-top: 90px;
  padding-bottom: 90px;
}

.main-shop-area {
  position: relative;
  display: block;
  background: #ffffff;
}

#shop-area .shop-content {
  position: relative;
  display: block;
}

.showing-result-shorting {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 13px;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 40px;
}

.showing-result-shorting .showing p {
  color: #f3441e;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.single-product-item {
  position: relative;
  display: block;
  overflow: hidden;
  border: 1px solid #f2f2f2;
  margin-bottom: 40px;
  transition-duration: .4s, .4s;
  transition-timing-function: ease, ease-out;
  transition-delay: 0s, .4s;
}

.single-product-item:hover {
  box-shadow: 0px 3px 8px #f0f0f0;
  transform: translate(0px, -10px);
  transition: all 500ms ease;
}

.single-product-item .img-holder {
  position: relative;
  display: block;
  overflow: hidden;
}

.single-product-item .img-holder img {
  transform: scale(1);
  transition: all 0.7s ease 0s;
  width: 100%;
}

.single-product-item:hover .img-holder img {
  transform: scale(1.1);
}

.single-product-item .img-holder .overlay-style-one {
  background: rgba(255, 255, 255, 0.3);
}

.single-product-item:hover .img-holder .overlay-style-one {
  opacity: 1;
}

.single-product-item .img-holder .overlay-style-one .content {
  position: absolute;
  bottom: 0;
  left: 29px;
  right: 28px;
  transform: scale(0);
  transition: all .5s ease-in-out;
  margin: 0 auto;
  width: 210px;
}

.single-product-item:hover .img-holder .overlay-style-one .content {
  transform: scale(1);
}

.single-product-item .img-holder .overlay-style-one .content ul li {
  position: relative;
  display: inline-block;
  float: left;
  width: 105px;
  height: 50px;
}

.single-product-item .img-holder .overlay-style-one .content ul li a {
  background: #151515;
  display: block;
  height: 50px;
  color: #ffffff;
  font-size: 14px;
  line-height: 50px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: 'Titillium Web', sans-serif;
}

.single-product-item .img-holder .overlay-style-one .content ul li a i {
  background: #f3441e;
  font-size: 20px;
  display: block;
  line-height: 50px;
}

.single-product-item .title-holder {
  display: block;
  padding: 27px 0 34px;
  border-top: 1px solid #f2f2f2;
  margin: 0 30px;
}

.single-product-item .title-holder h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 6px;
  line-height: 20px;
}

.single-product-item .title-holder h3 a {
  color: #303030;
  transition: all 500ms ease;
}

.single-product-item .title-holder .rate {
  color: #f3441e;
  font-weight: 400;
}

.main-shop-area .post-pagination {
  padding-top: 0px;
}

#shop-area .sidebar-wrapper {
  position: relative;
  display: block;
}

.single-sidebar .price-ranger {
  margin-top: 4px;
}

.single-sidebar .price-ranger .ui-widget-content {
  background: #efedea;
  border: none;
  height: 2px;
}

.single-sidebar .price-ranger .ui-slider-handle {
  background: #f3441e none repeat scroll 0 0;
  border: 2px solid #f3441e;
  border-radius: 50%;
  height: 10px;
  margin-left: -4px;
  outline: medium none;
  width: 10px !important;
  cursor: pointer;
}

.single-sidebar .price-ranger .ui-slider-horizontal .ui-slider-handle {
  top: -4px;
}

.single-sidebar .price-ranger .ui-slider .ui-slider-range {
  background: #f3441e;
}

.single-sidebar .price-ranger #slider-range {
  margin-left: 3px;
  margin-right: 5px;
  margin-top: 0;
}

.single-sidebar .price-ranger .ranger-min-max-block {
  margin-bottom: 0px;
  margin-top: 25px;
}

.single-sidebar .price-ranger .ranger-min-max-block input {
  display: inline-block;
}

.single-sidebar .price-ranger .ranger-min-max-block input[type="submit"] {
  float: left;
  margin-right: 10px;
  padding: 12px 0 12px;
  text-align: center;
  width: 90px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  margin-top: 0;
  border-radius: 0;
}

.single-sidebar .price-ranger .ranger-min-max-block input[type="text"] {
  border: medium none;
  color: #848484;
  font-size: 16px;
  font-weight: 400;
  outline: medium none;
  text-align: right;
  width: 40px;
}

.single-sidebar .price-ranger .ranger-min-max-block span {
  color: #848484;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
}

.sidebar-wrapper .single-sidebar .latest-product li {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 25px;
  margin-bottom: 25px;
  padding-left: 70px;
  position: relative;
}

.sidebar-wrapper .single-sidebar .latest-product li:last-child {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0;
}

.sidebar-wrapper .single-sidebar .latest-product li .img-holder {
  display: block;
  overflow: hidden;
  position: absolute;
  width: 70px;
  min-height: 70px;
  top: 0;
  left: 0;
  border: 1px solid #f2f2f2;
}

.sidebar-wrapper .single-sidebar .latest-product li .img-holder img {
  transform: scale(1);
  transition: all 0.7s ease 0s;
  width: 100%;
}

.sidebar-wrapper .single-sidebar .latest-product li:hover .img-holder img {
  transform: scale(1.1);
}

.sidebar-wrapper .single-sidebar .latest-product li .img-holder .overlay-style-one .box .content a i {
  transition: all 0.5s ease 0s;
  font-size: 16px;
  color: #fff;
}

.sidebar-wrapper .single-sidebar .latest-product li .img-holder .overlay-style-one .box .content a:hover i {
  color: #848484;
}

.sidebar-wrapper .single-sidebar .latest-product li:hover .img-holder .overlay-style-one {
  opacity: 1;
}

.sidebar-wrapper .single-sidebar .latest-product li .title-holder {
  position: relative;
  padding-left: 20px;
  min-height: 70px;
}

.sidebar-wrapper .single-sidebar .latest-product li .title-holder h4 {
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
  margin: -4px 0 10px;
}

.sidebar-wrapper .single-sidebar .latest-product li .title-holder h4 a {
  color: #303030;
  transition: all 500ms ease;
}

.sidebar-wrapper .single-sidebar .latest-product li .title-holder h4 a:hover {
  color: #f3441e;
}

.sidebar-wrapper .single-sidebar .latest-product li .title-holder h5 {
  color: #f3441e;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  font-family: 'Rubik', sans-serif;
}

.sidebar-wrapper .single-sidebar .latest-product li .title-holder .review-box {
  overflow: hidden;
  margin: 7px 0 0;
}

.sidebar-wrapper .single-sidebar .latest-product li .title-holder .review-box li {
  display: inline-block;
  margin: 0 1px;
  position: static;
  padding: 0;
  border: none;
  min-height: auto;
  line-height: 12px;
}

.sidebar-wrapper .single-sidebar .latest-product li .title-holder .review-box li i {
  font-size: 12px;
  color: #bebebe;
}

.sidebar-wrapper .single-sidebar .product-tag {
  margin-left: -3px;
  margin-right: -3px;
  margin-top: 0px;
}

.sidebar-wrapper .single-sidebar .product-tag li {
  display: inline-block;
  margin: 0 3px 10px;
}

.sidebar-wrapper .single-sidebar .product-tag li a {
  border: 1px solid #f2f2f2;
  color: #848484;
  display: block;
  font-size: 15px;
  font-weight: 400;
  padding: 2px 10px 2px;
  transition: all 500ms ease 0s;
}

.sidebar-wrapper .single-sidebar .product-tag li a:hover {
  background: #f3441e;
  border-color: #f3441e;
  color: #ffffff;
}

/*** 
=============================================
   Single Shop Area style
=============================================
***/
#shop-area.single-shop-area {
  padding-bottom: 50px;
}

.single-shop-content .img-holder {
  margin-right: 50px;
  border: 1px solid #f2f2f2;
}

.single-shop-content .img-holder img {
  width: 100%;
}

.single-shop-content .content-box {
  margin-left: -50px;
}

.single-shop-content .content-box h3 {
  color: #303030;
  font-size: 30px;
  font-weight: 400;
  margin: 0 0 21px;
  text-transform: capitalize;
}

.single-shop-content .content-box span.price {
  color: #848484;
  font-size: 22px;
  font-weight: 400;
}

.single-shop-content .content-box .review-box {
  margin: 6px 0 0;
  overflow: hidden;
}

.single-shop-content .content-box .review-box ul li i {
  color: #f3441e;
}

.single-shop-content .content-box .text {
  overflow: hidden;
  padding: 23px 0 15px;
}

.single-shop-content .content-box .location-box {
  margin: 4px 0 23px;
}

.single-shop-content .content-box .location-box p {
  color: #303030;
  font-size: 16px;
  margin: 0 0 13px;
}

.single-shop-content .content-box .location-box form input {
  border: 2px solid #f7f7f7;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  width: 170px;
  transition: all 500ms ease;
  margin: 0 0 10px;
}

.single-shop-content .content-box .location-box form input:focus {
  border-color: #202020;
}

.single-shop-content .content-box .location-box form button {
  background: #f7f7f7 none repeat scroll 0 0;
  border: medium none;
  color: #303030;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  margin-left: 6px;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  transition: all 500ms ease 0s;
  width: 95px;
  position: relative;
  top: -1px;
  line-height: 40px;
}

.single-shop-content .content-box .location-box form button:hover {
  background: #202020;
  color: #fff;
}

.single-shop-content .content-box .location-box form span {
  color: #f3441e;
}

.single-shop-content .content-box .addto-cart-box .input-group.bootstrap-touchspin {
  float: left;
  width: 46px;
}

.single-shop-content .content-box .addto-cart-box .bootstrap-touchspin .input-group-btn-vertical .btn {
  display: block;
  float: none;
  margin-left: 0px;
  position: relative;
  width: 100%;
  background: #efefef;
  border: 1px solid #efefef;
  color: #848484;
  padding: 12px 11px;
  cursor: pointer;
}

.single-shop-content .content-box .addto-cart-box .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0;
}

.single-shop-content .content-box .addto-cart-box .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0;
  margin-top: -2px;
}

.single-shop-content .content-box .addto-cart-box .bootstrap-touchspin .input-group-btn-vertical .btn.bootstrap-touchspin-up:before {
  content: "\f0d8";
  font-family: FontAwesome;
  color: #848484;
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 5px 5px 5px;
}

.single-shop-content .content-box .addto-cart-box .bootstrap-touchspin .input-group-btn-vertical .btn.bootstrap-touchspin-down:before {
  content: "\f0d7";
  font-family: FontAwesome;
  color: #848484;
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 2px 5px 8px;
}

.single-shop-content .content-box .addto-cart-box button.addtocart {
  height: 50px;
  width: 170px;
  margin-left: 43px;
  color: #fff;
  padding: 14px 0 12px;
  cursor: pointer;
  border-radius: 0;
}

.product-tab-box {
  position: relative;
  border: 1px solid #ececec;
  border-top: none;
  border-right: none;
  border-left: none;
  overflow: hidden;
  margin-top: 60px;
}

.product-tab-box .tab-btns {
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #ececec;
  margin-bottom: 30px;
}

.product-tab-box .tab-btns .tab-btn {
  display: inline-block;
  float: left;
  margin-bottom: -1px;
  margin-right: 3px;
}

.product-tab-box .tab-btns .tab-btn span {
  background: #f7f7f7;
  border-top: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  cursor: pointer;
  display: block;
  margin-right: 1px;
  padding: 15px 30px 13px;
  color: #303030;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  background: #f7f7f7;
  transition: all 500ms ease;
  font-family: 'Titillium Web', sans-serif;
}

.product-tab-box .tab-btns .tab-btn.active-btn span,
.product-tab-box .tab-btns .tab-btn:hover span {
  background: #ffffff;
  color: #f3441e;
  border-left-color: #ececec;
  border-top-color: #ececec;
  border-right-color: #ececec;
}

.product-tab-box .tabs-content {
  position: relative;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  margin-top: -30px;
  padding: 35px 30px 40px;
}

.product-tab-box .tabs-content .tab {
  position: relative;
  padding: 0px;
  display: none;
}

.product-tab-box .tabs-content .tab.active-tab {
  display: block;
}

.product-tab-box .tabs-content .review-box .title {
  margin-bottom: 33px;
}

.product-tab-box .tabs-content .review-box .title h3 {
  color: #303030;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
}

.product-tab-box .tabs-content .review-box .single-review-box {
  margin-bottom: 30px;
  padding-left: 60px;
  position: relative;
}

.product-tab-box .tabs-content .review-box .single-review-box .img-holder {
  left: 0;
  position: absolute;
  top: 0;
}

.product-tab-box .tabs-content .review-box .single-review-box .text-holder {
  border: 1px solid #ececec;
  margin-left: 20px;
  padding: 17px 20px 12px;
}

.product-tab-box .tabs-content .review-box .single-review-box .text-holder .top {
  margin: 0 0 10px;
  overflow: hidden;
}

.product-tab-box .tabs-content .review-box .single-review-box .text-holder .top .name h4 {
  color: #303030;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  text-transform: capitalize;
}

.product-tab-box .tabs-content .review-box .single-review-box .text-holder .top .review-box ul {
  margin-top: -2px;
  padding-left: 0px;
  position: relative;
}

.product-tab-box .tabs-content .review-box .single-review-box .text-holder .top .review-box ul li {
  display: inline-block;
  margin-right: 1px;
}

.product-tab-box .tabs-content .review-box .single-review-box .text-holder .top .review-box ul li i {
  color: #f3441e;
  font-size: 14px;
}

.product-tab-box .tabs-content .review-box .single-review-box .text-holder .text p {
  margin: 0;
}

.product-tab-box .review-form {
  padding-top: 30px;
}

.product-tab-box .review-form .title {
  padding-bottom: 30px;
}

.product-tab-box .review-form .title h3 {
  color: #303030;
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
}

.product-tab-box .review-form .add-rating-box {
  margin: 0 0 20px;
}

.product-tab-box .review-form .add-rating-box .add-rating-title h4 {
  color: #848484;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 10px;
  font-family: 'Rubik', sans-serif;
  text-transform: none;
}

.product-tab-box .review-form .add-rating-box ul li {
  border-right: 1px solid #ebebeb;
  display: inline-block;
  line-height: 20px;
  margin-right: 10px;
  padding-right: 10px;
}

.product-tab-box .review-form .add-rating-box ul li:last-child {
  margin-right: 0px;
  padding-right: 0px;
  border-right: 0px solid #ebebeb;
}

.product-tab-box .review-form .add-rating-box ul li a i {
  color: #d6d6d6;
  font-size: 12px;
  line-height: 20px;
  margin-right: 1px;
  opacity: 0.9;
  transition: all 500ms ease 0s;
}

.product-tab-box .review-form form .field-label p {
  color: #848484;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 3px;
  font-family: 'Rubik', sans-serif;
}

.product-tab-box .review-form form input[type="text"],
.product-tab-box .review-form form input[type="email"],
.product-tab-box .review-form form textarea {
  background: #fff;
  height: 50px;
  width: 100%;
  border: 1px solid #ececec;
  color: #252525;
  font-size: 14px;
  padding: 0 15px;
  margin-bottom: 20px;
  transition: all 500ms ease;
}

.product-tab-box .review-form form textarea {
  height: 110px;
  padding: 10px 15px;
  margin-bottom: 25px;
}

.product-tab-box .review-form form button {
  padding: 18px 40px 18px;
  border-radius: 0;
}

.product-tab-box .review-form .add-rating-box ul li:hover a i {
  opacity: 1;
  color: #f3441e;
}

.product-tab-box .review-form form input[type="text"]:focus {
  border-color: #1d1d1d;
}

.product-tab-box .review-form form input[type="email"]:focus {
  border-color: #1d1d1d;
}

.product-tab-box .review-form form textarea:focus {
  border-color: #1d1d1d;
}

.related-product {
  padding-top: 60px;
}

.related-product .sec-title {
  padding-bottom: 30px;
  margin-top: -4px;
}

.related-product .sec-title h1 {
  color: #303030;
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
}

/*** 
=============================================
   Cart area  style
=============================================
***/
.cart-area {
  padding-bottom: 90px;
  padding-top: 90px;
}

.cart-area .table-outer {
  overflow-x: auto;
  position: relative;
  width: 100%;
}

.cart-area .cart-table {
  min-width: 1024px;
  width: 100%;
}

.cart-area .cart-table .cart-header {
  background: #f7f7f7;
  color: #303030;
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  text-transform: capitalize;
  width: 100%;
}

.cart-area .cart-table thead tr th {
  font-weight: 500;
  line-height: 24px;
  min-width: 110px;
  padding: 20px 25px;
}

.cart-area .cart-table thead tr th.prod-column {
  padding-left: 30px;
  text-align: left;
}

.cart-area .cart-table thead tr th.availability {
  padding: 0 40px;
}

.cart-area .cart-table tbody tr {
  border-bottom: 1px solid #f7f7f7;
}

.cart-area .cart-table tbody tr td {
  color: #252525;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  min-width: 100px;
  padding: 27.5px 25px;
}

.cart-area .cart-table tbody tr .prod-column .column-box {
  min-height: 70px;
  position: relative;
}

.cart-area .cart-table tbody tr .prod-column .column-box .prod-thumb {
  width: 70px;
  border: 1px solid #f7f7f7;
}

.cart-area .cart-table tbody tr .prod-column .column-box .prod-thumb a {
  display: block;
}

.cart-area .cart-table tbody tr .prod-column .column-box .prod-thumb img {
  display: block;
  max-width: 100%;
}

.cart-area .cart-table tbody tr .prod-column .column-box .prod-thumb,
.cart-area .cart-table tbody tr .prod-column .column-box .title {
  display: table-cell;
  vertical-align: middle;
}

.cart-area .cart-table tbody tr .prod-column .column-box .title {
  padding-left: 20px;
}

.cart-area .cart-table tbody tr .prod-column .column-box .title h3 {
  color: #303030;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  text-transform: capitalize;
}

.cart-area .cart-table tbody tr .qty {
  padding-right: 25px;
  width: 120px;
}

.cart-area .cart-table tbody tr .qty .input-group.bootstrap-touchspin {
  float: left;
  width: 46px;
}

.cart-area .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  margin-left: 0px;
  position: relative;
  width: 100%;
  background: #efefef;
  border: 1px solid #efefef;
  color: #848484;
  padding: 12px 11px;
  cursor: pointer;
}

.cart-area .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0;
}

.cart-area .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0;
  margin-top: -2px;
}

.cart-area .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical > .btn.bootstrap-touchspin-up:before {
  content: "\f0d8";
  font-family: FontAwesome;
  color: #848484;
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 5px 5px 5px;
}

.cart-area .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical > .btn.bootstrap-touchspin-down:before {
  content: "\f0d7";
  font-family: FontAwesome;
  color: #848484;
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 2px 5px 8px;
}

.cart-area .cart-table tbody tr td.unit-price {
  padding-left: 40px;
  padding-right: 20px;
}

.cart-area .cart-table tbody .available-info {
  color: #848484;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 50px;
  position: relative;
  font-family: 'Rubik', sans-serif;
}

.cart-area .cart-table tbody .available-info .icon {
  background: #f3441e;
  border-radius: 50%;
  color: #ffffff;
  font-size: 18px;
  height: 40px;
  left: 0;
  line-height: 40px;
  position: absolute;
  text-align: center;
  top: 0px;
  width: 40px;
}

.cart-area .cart-table tbody tr td.price {
  color: #848484;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}

.cart-area .cart-table tbody tr .sub-total {
  color: #f3441e;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Rubik', sans-serif;
}

.cart-area .cart-table tbody tr td .remove .checkbox label {
  color: #848484;
  font-size: 16px;
  font-weight: 400;
}

.cart-area .cart-table tbody tr td .remove .checkbox label input[type="checkbox"] {
  display: inline-block;
  position: relative;
  top: 1px;
}

.cart-middle {
  padding-top: 30px;
}

.cart-middle .apply-coupon {
  padding-left: 245px;
  position: relative;
}

.cart-middle .apply-coupon input[type="text"] {
  border: 2px solid #f7f7f7;
  color: #9e9e9e;
  font-size: 14px;
  font-weight: 400;
  height: 50px;
  left: 0;
  padding: 0 15px;
  position: absolute;
  text-transform: capitalize;
  top: 0;
  transition: all 500ms ease 0s;
  border-radius: 0px;
  width: 225px;
}

.cart-middle .apply-coupon input[type="text"]:focus {
  border: 2px solid #1d1d1d;
}

.cart-middle .apply-coupon .apply-coupon-button button {
  padding: 18px 30px 18px;
  border-radius: 0;
  cursor: pointer;
}

.cart-middle .update-cart button {
  background: #f7f7f7;
  padding: 18px 30px 18px;
  cursor: pointer;
  border-radius: 0;
  color: #303030;
}

.cart-middle .update-cart button:hover {
  background: #1D1D1D;
  color: #fff;
}

.cart-bottom {
  overflow: hidden;
  padding-top: 68px;
}

.cart-bottom .calculate-shipping .ui-state-default .ui-icon {
  background: none;
}

.cart-bottom .calculate-shipping .ui-state-default {
  background: #fff none repeat scroll 0 0;
  border: 2px solid #f7f7f7;
  border-radius: 0;
  color: #848484;
  font-size: 14px;
  font-weight: normal;
  height: 45px;
  margin-bottom: 17px;
  outline: medium none;
  width: 100%;
}

.cart-bottom .calculate-shipping .ui-selectmenu-button span.ui-icon {
  margin-top: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.cart-bottom .calculate-shipping .ui-selectmenu-button span.ui-icon {
  border-left: 2px solid #f7f7f7;
  display: block;
  float: right;
  height: 41px;
  position: relative;
  text-indent: 0;
  top: 0;
  width: 43px;
}

.cart-bottom .calculate-shipping .ui-selectmenu-button span.ui-icon::before {
  color: #9b9b9b;
  content: "\f107";
  font-family: FontAwesome;
  font-size: 20px;
  overflow: visible;
  position: absolute;
  right: 14px;
  top: 6px;
}

.cart-bottom .calculate-shipping .ui-selectmenu-button span.ui-selectmenu-text {
  display: block;
  line-height: 18px;
  overflow: hidden;
  padding: 12px 17px;
  text-align: left;
}

.cart-bottom .calculate-shipping input[type="text"] {
  border: 2px solid #f7f7f7;
  color: #848484;
  display: block;
  font-size: 14px;
  height: 45px;
  padding: 0 18px;
  width: 100%;
  margin-bottom: 30px;
  transition: all 500ms ease;
}

.cart-bottom .calculate-shipping input[type="text"]:focus {
  border: 2px solid #1d1d1d;
}

.cart-bottom .calculate-shipping button {
  color: #222222;
  background: #f7f7f7;
  font-weight: 700;
  padding: 18px 30px 18px;
  border-radius: 0;
  cursor: pointer;
}

.cart-bottom .calculate-shipping button:hover {
  background: #1d1d1d;
  color: #ffffff;
}

.cart-bottom button.checkout-btn {
  margin-top: 30px;
  padding: 18px 30px 18px;
  border-radius: 0;
  cursor: pointer;
}

/*** 
=============================================
    Checkout Area style
=============================================
***/
.checkout-area {
  position: relative;
  display: block;
  padding-top: 90px;
}

.checkout-area .title-box {
  padding-bottom: 22px;
}

.checkout-area .title-box h3 {
  font-weight: 600;
}

.checkout-area .exisitng-customer {
  background: #f7f7f7;
  display: block;
  padding: 20px 28px 21px;
  position: relative;
  margin-bottom: 20px;
}

.checkout-area .exisitng-customer:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: #f3441e;
  content: "";
}

.checkout-area .exisitng-customer h5 {
  color: #303030;
  font-size: 16px;
  font-weight: 400;
}

.checkout-area .exisitng-customer h5 a {
  color: #f3441e;
  font-weight: 400;
  display: inline-block;
  padding-left: 15px;
}

.checkout-area .coupon {
  background: #f7f7f7;
  display: block;
  padding: 20px 28px 21px;
  position: relative;
  margin-bottom: 58px;
}

.checkout-area .coupon:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: #f3441e;
  content: "";
}

.checkout-area .coupon h5 {
  color: #303030;
  font-size: 16px;
  font-weight: 400;
}

.checkout-area .coupon h5 a {
  color: #f3441e;
  font-weight: 400;
  display: inline-block;
  padding-left: 15px;
}

.checkout-area .form form .field-label {
  color: #303030;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 3px;
  text-transform: capitalize;
  font-family: 'Titillium Web', sans-serif;
}

.checkout-area .form form .field-input input[type="text"] {
  border: 1px solid #ececec;
  color: #848484;
  display: block;
  font-size: 16px;
  height: 48px;
  margin-bottom: 25px;
  padding: 0 15px;
  width: 100%;
  transition: all 500ms ease;
}

.checkout-area .form form .field-input input[type="text"]:focus {
  border: 1px solid #1d1d1d;
}

.checkout-area .create-acc .checkbox {
  margin: 7px 0 0;
}

.checkout-area .create-acc .checkbox label {
  color: #f3441e;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

.checkout-area .create-acc .checkbox input {
  position: relative;
  top: 1px;
}

.checkout-area .shipping-info input[type="checkbox"] {
  cursor: pointer;
  display: inline-block;
  margin: 0 0 0 20px;
  position: relative;
  top: 0px;
  vertical-align: middle;
}

.checkout-area .form form .field-input textarea {
  border: 1px solid #ececec;
  color: #848484;
  display: block;
  font-size: 16px;
  height: 124px;
  margin-bottom: 28px;
  padding: 10px 15px;
  width: 100%;
  transition: all 500ms ease;
}

.checkout-area .form form .field-input textarea:focus {
  border-color: #1d1d1d;
}

.checkout-area .bottom {
  display: block;
  background: #f5f5f5;
  padding-top: 88px;
  padding-bottom: 90px;
  margin-top: 75px;
}

.checkout-area .table {
  overflow-x: auto;
  position: relative;
  width: 100%;
  margin-bottom: 0;
}

.checkout-area .table .cart-table {
  min-width: auto;
  width: 100%;
}

.checkout-area .table .cart-table .cart-header {
  background: #ffffff;
  color: #303030;
  font-size: 18px;
  position: relative;
  text-transform: capitalize;
  width: 100%;
  font-weight: 400;
  font-family: 'Titillium Web', sans-serif;
}

.checkout-area .table .cart-table thead tr th {
  font-weight: 500;
  line-height: 24px;
  min-width: 110px;
  padding: 19px 30px 17px;
  border-top: none;
  border-bottom: none;
}

.checkout-area .table .cart-table thead tr th.product-column {
  padding-left: 30px;
  text-align: left;
}

.checkout-area .table .cart-table tbody tr {
  border-bottom: 1px solid #e9e9e9;
}

.checkout-area .table .cart-table tbody tr td {
  vertical-align: middle;
}

.checkout-area .table .cart-table tbody tr td {
  min-width: 110px;
  padding: 25px 25px;
  border-top: none;
}

.checkout-area .table .cart-table tbody tr .product-column .column-box {
  min-height: 70px;
}

.checkout-area .table .cart-table tbody tr .product-column .column-box .prod-thumb {
  width: 70px;
}

.checkout-area .table .cart-table tbody tr .product-column .column-box .prod-thumb a {
  display: block;
  border: 1px solid #f2f2f2;
}

.checkout-area .table .cart-table tbody tr .product-column .column-box .prod-thumb img {
  display: block;
  max-width: 100%;
}

.checkout-area .table .cart-table tbody tr .product-column .column-box .prod-thumb,
.checkout-area .table .cart-table tbody tr .product-column .column-box .product-title {
  display: table-cell;
  vertical-align: middle;
}

.checkout-area .table .cart-table tbody tr .product-column .column-box .product-title {
  padding-left: 20px;
}

.checkout-area .table .cart-table tbody tr .product-column .column-box .product-title h3 {
  color: #303030;
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
}

.checkout-area .table .cart-table tbody tr .qty {
  padding-right: 30px;
  width: 130px;
}

.checkout-area .table .cart-table tbody tr .qty .input-group.bootstrap-touchspin {
  float: left;
  width: 46px;
}

.checkout-area .table .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  margin-left: 0px;
  position: relative;
  width: 100%;
  background: #efefef;
  border: 1px solid #efefef;
  color: #848484;
  padding: 12px 11px;
  cursor: pointer;
}

.checkout-area .table .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0;
}

.checkout-area .table .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0;
  margin-top: -2px;
}

.checkout-area .table .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical > .btn.bootstrap-touchspin-up:before {
  content: "\f0d8";
  font-family: FontAwesome;
  color: #848484;
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 5px 5px 5px;
}

.checkout-area .table .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical > .btn.bootstrap-touchspin-down:before {
  content: "\f0d7";
  font-family: FontAwesome;
  color: #848484;
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 2px 5px 8px;
}

.checkout-area .table .cart-table tbody tr td.price {
  color: #f3441e;
  font-size: 18px;
  font-weight: 400;
}

.cart-total-table {
  border: 1px solid #e9e9e9;
}

.cart-total-table li {
  border-bottom: 1px solid #e9e9e9;
}

.cart-total-table li:last-child {
  border-bottom: none;
}

.cart-total-table li span.col.col-title {
  border-right: 1px solid #f7f7f7;
  color: #303030;
  display: block;
  float: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  padding: 10px 18px;
  position: relative;
  width: 50%;
  font-family: 'Rubik', sans-serif;
}

.cart-total-table li span.col {
  color: #303030;
  display: block;
  float: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  padding: 10px 18px;
  position: relative;
  width: 50%;
  font-family: 'Rubik', sans-serif;
}

.cart-total-table li span.col b {
  color: #f3441e;
  font-weight: 300;
  text-decoration: underline;
}

.cart-total .payment-options {
  background: #ffffff none repeat scroll 0 0;
  margin-top: 30px;
  overflow: hidden;
  border: 1px solid #f2f2f2;
  padding: 23px 20px 35px;
}

.cart-total .payment-options .option-block {
  margin-bottom: 14px;
}

.cart-total .payment-options .option-block .checkbox {
  margin: 0 0 5px;
}

.cart-total .payment-options .option-block .checkbox label {
  display: block;
  font-weight: 500;
  min-height: 20px;
  padding-left: 0px;
  margin: 0;
}

.cart-total .payment-options .option-block .checkbox label input {
  position: relative;
  top: 1px;
}

.cart-total .payment-options .option-block .checkbox label span {
  color: #303030;
  font-size: 16px;
  padding-left: 10px;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
}

.cart-total .payment-options .option-block .checkbox label span b {
  color: #303030;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  padding-left: 25px;
  text-decoration: underline;
}

.cart-total .payment-options .option-block .text {
  padding-left: 30px;
}

.cart-total .payment-options .option-block .text p {
  margin: 0;
  line-height: 28px;
}

.placeorder-button button {
  padding: 18px 35px 18px;
  margin-top: 20px;
  border-radius: 0;
  cursor: pointer;
}

/*** 
=============================================
    Login Register Area style
=============================================
***/
.login-register-area {
  padding-bottom: 83px;
  padding-top: 88px;
}

.login-register-area .form .title-box h3 {
  font-weight: 600;
}

.login-register-area .form form {
  width: 100%;
}

.login-register-area .form .input-field {
  position: relative;
}

.login-register-area .form .input-field input[type="text"] {
  border: 2px solid #f4f4f4;
  color: #848484;
  font-size: 16px;
  height: 50px;
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 50px;
  width: 100%;
  transition: all 700ms ease 0s;
}

.login-register-area .form .input-field .icon-holder {
  position: absolute;
  right: 20px;
  top: 11px;
}

.login-register-area .form .input-field .icon-holder i {
  color: #999999;
  font-size: 14px;
  font-weight: 400;
}

.login-register-area .form button {
  margin-top: 10px;
  padding: 18px 25px 18px;
  border-radius: 0;
  cursor: pointer;
}

.login-register-area .form .remember-text {
  margin-top: 19px;
}

.login-register-area .form .remember-text .checkbox label {
  color: #848484;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  cursor: pointer;
}

.login-register-area .form .remember-text .checkbox input {
  position: relative;
  top: 1px;
}

.login-register-area .form .remember-text .checkbox span {
  color: #303030;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}

.login-register-area .form .social-icon {
  float: right;
  margin-right: -8px;
  margin-top: 15px;
  position: relative;
}

.login-register-area .form .social-icon .login-with {
  display: inline-block;
  left: -110px;
  position: absolute;
  top: 8px;
}

.login-register-area .form .social-icon li {
  display: inline-block;
  margin: 0 8px;
}

.login-register-area .form .social-icon li a i {
  background: #47588f none repeat scroll 0 0;
  border-radius: 50%;
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 100;
  height: 40px;
  line-height: 24px;
  padding: 8px 0;
  text-align: center;
  transition: all 700ms ease 0s;
  width: 40px;
}

.login-register-area .form .social-icon li a i.twitter {
  background: #33ccfe none repeat scroll 0 0;
}

.login-register-area .form .social-icon li a i.gplus {
  background: #dd4b39 none repeat scroll 0 0;
}

.login-register-area .form a.forgot-password {
  color: #f3441e;
  float: right;
  font-weight: 500;
  margin: 20px 0 0;
  font-family: 'Rubik', sans-serif;
}

.login-register-area .form .right {
  display: block;
  overflow: hidden;
  margin-left: -20px;
}

.login-register-area .form .right h6 {
  color: #848484;
  float: left;
  font-size: 14px;
  margin-top: 25px;
}

.login-register-area .form .input-field input[type="text"]:focus {
  border: 2px solid #1d1d1d;
}

.login-register-area .form .social-icon li a:hover i {
  background: #18387b;
}

.login-register-area .form .social-icon li a:hover i.twitter {
  background: #12addf;
}

.login-register-area .form .social-icon li a:hover i.gplus {
  background: #c62d1a;
}

/*** 
=============================================
    Contact Info Area style
=============================================
***/
.contact-info-area {
  position: relative;
  padding: 90px 0 90px;
}

.contact-box-content {
  position: relative;
}

.contact-box-content .img-holder {
  display: block;
  max-width: 570px;
  width: 100%;
}

.contact-box-content .img-holder img {
  width: 100%;
}

.contact-box-content .text-holder {
  display: block;
}

.contact-box-content .text-holder .opening-hours {
  position: relative;
  display: block;
  overflow: hidden;
  max-width: 270px;
  margin: 45px auto 0;
}

.contact-box-content .text-holder .opening-hours .title-box.center {
  padding-bottom: 19px;
}

.contact-box-content .text-holder .opening-hours .title-box.center h3 {
  padding-left: 16px;
  color: #2f2f2f;
}

.contact-box-content .text-holder .opening-hours .title-box.center h3:after {
  display: none;
}

.contact-box-content .text-holder .opening-hours .inner-content {
  display: block;
}

.contact-box-content .text-holder .opening-hours .inner-content h3 {
  color: #2f2f2f;
  font-size: 18px;
}

.contact-box-content .text-holder .opening-hours .inner-content h1 {
  color: #f3441e;
  font-size: 30px;
  font-weight: 400;
  margin: 10px 0 8px;
}

.contact-box-content .text-holder .opening-hours .inner-content h1 span {
  color: #2f2f2f;
  font-size: 18px;
}

.quick-contact-box {
  position: relative;
  margin-top: -90px;
  display: block;
  background: #151515;
  padding: 54px 40px 48px;
  z-index: 10;
}

.quick-contact-box .title-box h3 {
  color: #ffffff;
}

.quick-contact-box ul {
  display: block;
  overflow: hidden;
}

.quick-contact-box ul li {
  position: relative;
  display: block;
  overflow: hidden;
  padding-left: 30px;
  margin-bottom: 20px;
}

.quick-contact-box ul li:last-child {
  margin-bottom: 0;
}

.quick-contact-box ul li .icon-holder {
  position: absolute;
  top: 3px;
  left: 0;
}

.quick-contact-box ul li .icon-holder span:before {
  color: #f3441e;
  font-size: 20px;
  line-height: 20px;
}

.quick-contact-box ul li .title-holder p {
  color: #848484;
  margin: 0;
}

.quick-contact-box .map-find {
  display: block;
  margin-top: 26px;
}

.contact-form {
  position: relative;
  display: block;
  margin-left: 30px;
  padding-left: 40px;
  z-index: 1;
  min-height: 100%;
}

.contact-form .sec-title {
  padding-bottom: 28px;
}

.contact-form form {
  position: relative;
  display: block;
  border: 1px solid #e5e5e5;
}

.contact-form form input[type="text"],
.contact-form form input[type="email"],
.contact-form form textarea {
  background: transparent;
  border: 0px solid;
  border-bottom: 1px solid #e5e5e5;
  color: #303030;
  display: block;
  font-size: 16px;
  height: 55px;
  margin-bottom: 0px;
  padding: 0 20px;
  width: 100%;
  transition: all 500ms ease;
}

.contact-form form textarea {
  height: 95px;
  padding: 10px 20px;
}

.contact-form form input[type="text"]:focus {
  border-color: #1d1d1d;
  color: #222222;
}

.contact-form form input[type="email"]:focus {
  border-color: #1d1d1d;
  color: #222222;
}

.contact-form form textarea:focus {
  border-color: #e5e5e5;
  color: #222222;
}

.contact-form form button {
  width: 100%;
  padding: 15px 0 16px;
  background: #f3441e;
  border-radius: 0;
  cursor: pointer;
  display: block;
}

.contact-form form button span:before {
  font-weight: 500;
  padding-left: 10px;
}

/*** 
=============================================
    Location Map Area style
=============================================
***/
.location-map-area {
  position: relative;
  display: block;
  background: #151515;
  padding: 0 0 90px;
  z-index: 1;
}

.location-map-area:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background: #ffffff;
  content: "";
  z-index: -1;
}

.location-box {
  position: relative;
  display: block;
}

.location-box .title {
  position: relative;
  display: block;
  background: #f53f1f;
  text-align: center;
  padding: 33px 0;
}

.location-box .title h3 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
}

.location-box .accordion-box {
  margin-top: 30px;
}

.location-box .accordion-box .accordion {
  position: relative;
  display: block;
  background: #151515;
  margin: 0;
  padding: 0;
  border-radius: 0;
}

.location-box .accordion-box .accordion .accord-btn {
  border-bottom: 1px solid #282828;
  cursor: pointer;
  position: relative;
  transition: all 500ms ease;
  display: block;
  padding-left: 0px;
  padding-top: 0;
  padding-bottom: 0;
}

.location-box .accordion-box .accordion .accord-btn::after {
  display: none;
}

.location-box .accordion-box .accordion .accord-btn h4 {
  color: #848484;
  font-size: 18px;
  font-weight: 400;
  line-height: 55px;
  transition: all 500ms ease;
  font-family: 'Rubik', sans-serif;
}

.location-box .accordion-box .accordion .accord-content {
  padding-top: 22px;
  padding-bottom: 3px;
}

.location-box .single-box h3 {
  position: relative;
  display: block;
  padding-left: 30px;
  color: #ffffff;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}

.location-box .single-box h3:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20px;
  height: 3px;
  background: #ffffff;
  content: "";
  margin: 9px 0;
}

.location-box .single-box ul {
  display: block;
  overflow: hidden;
  margin-top: 24px;
}

.location-box .single-box ul li {
  position: relative;
  display: block;
  overflow: hidden;
  padding-left: 30px;
  margin-bottom: 20px;
}

.location-box .single-box ul li:last-child {
  margin-bottom: 0;
}

.location-box .single-box ul li .icon-holder {
  position: absolute;
  top: 3px;
  left: 0;
}

.location-box .single-box ul li .icon-holder span:before {
  color: #f3441e;
  font-size: 20px;
  line-height: 20px;
}

.location-box .single-box ul li .title-holder p {
  color: #848484;
  margin: 0;
}

.location-box .single-box .map-find {
  display: block;
  margin-top: 21px;
}

#contact-google-map {
  max-width: 670px;
  width: 100%;
  height: 530px;
  float: right;
}

/* Tiny Scrollbar */
#scrollbar1 {
  width: 370px;
  margin: 0;
}

#scrollbar1 .viewport {
  width: 350px;
  height: 410px;
  overflow: hidden;
  position: relative;
}

#scrollbar1 .overview {
  list-style: none;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
  max-width: 335px;
  width: 100%;
  height: 100%;
}

#scrollbar1 .scrollbar {
  background: url(../images/icon/bg-scrollbar-track-y.png) no-repeat 0 0;
  position: relative;
  background-position: 0 0;
  float: right;
  width: 10px;
}

#scrollbar1 .track {
  background: url(../images/icon/bg-scrollbar-trackend-y.png) no-repeat 0 100%;
  height: 100%;
  width: 10px;
  position: relative;
  padding: 0;
}

#scrollbar1 .thumb {
  background: url(../images/icon/bg-scrollbar-thumb-y.png) no-repeat 50% 100%;
  background-image: none;
  height: 20px;
  width: 10px;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0px;
  opacity: 1;
}

#scrollbar1 .thumb .end {
  background: url(../images/icon/bg-scrollbar-thumb-y.png) no-repeat 50% 0;
  overflow: hidden;
  height: 120px;
  width: 10px;
}

#scrollbar1 .disable {
  display: none;
}

.noSelect {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

/*** 
=============================================
    Location Map Area style
=============================================
***/
.working-section {
  position: relative;
  display: block;
  padding: 82px 0 90px;
}

.working-section .appointment-right-style2 {
  margin: 0 auto;
}

.working-section .appointment-right-style2 .button a.white-bg {
  background: #f5f5f5;
  color: #2f2f2f;
}

.working-section .appointment-right-style2 .button a:hover {
  background: #151515;
  color: #ffffff;
}

.working-section .appointment-right-style2 .button a.white-bg:hover {
  background: #151515;
  color: #ffffff;
}

/*** 
=============================================
    Testimonial Section style
=============================================
***/
.history-section {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 90px 0 90px;
}

.history-section .history-carousel {
  position: relative;
}

.history-section .carousel-outer {
  position: relative;
}

.history-section .carousel-outer .thumbs-outer {
  position: relative;
  max-width: 270px;
  margin: 0 auto;
  overflow: hidden;
  z-index: 1;
}

.history-section .carousel-outer .border {
  position: absolute;
  bottom: 37px;
  left: 0;
  right: 0;
  width: 398px;
  height: 42px;
  border: none !important;
  margin: 0 auto;
}

.history-section .history-carousel .thumbs-carousel {
  position: relative;
  max-width: 100px;
  margin: 0 auto;
}

.history-section .history-carousel .thumbs-carousel .owl-stage-outer {
  overflow: visible;
}

.history-section .history-carousel .thumbs-carousel li {
  position: relative;
  cursor: pointer;
  width: 100px;
  display: inline-block;
  transition: all 0.3s ease;
}

.history-section .history-carousel .thumbs-carousel li .img-holder {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  border: 2px solid transparent;
}

.history-section .history-carousel .thumbs-carousel li .img-holder img {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
}

.history-section .history-carousel .thumbs-carousel .owl-item.active li .img-holder {
  position: relative;
  border-color: #3aabd0;
  transform: scale(1.2);
  margin-top: 10px;
}

.history-section .carousel-outer .content-slide {
  position: relative;
}

.history-section .carousel-outer .content-carousel .owl-stage-outer {
  padding: 0px;
}

.history-section .carousel-outer .content-slide .content-inner {
  position: relative;
  text-align: center;
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
}

.history-section .carousel-outer .content-slide .content-inner .icon-holder img {
  width: auto;
  margin: 0 auto;
}

.history-section .carousel-outer .content-slide .content-inner .text {
  color: #2f2f2f;
  font-size: 20px;
  line-height: 30px;
  font-style: italic;
  margin: 33px 0 32px;
}

.history-section .carousel-outer .content-slide .content-inner .name {
  display: block;
  padding-bottom: 40px;
}

.history-section .carousel-outer .content-slide .content-inner .name h3 {
  color: #2f2f2f;
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 3px;
}

.history-section .carousel-outer .content-slide .content-inner .name span {
  color: #3aabd0;
}

/***************************************************************************************************************
||||||||||||||||||||||||||       RESPONSIVE STYLESHEET FOR MHCHESS       |||||||||||||||||||||||||||||||||||||||
****************************************************************************************************************



/* Large Layout: 1200px. */
@media only screen and (min-width: 1200px) {
  .container {
    padding: 0;
  }
  .container-fluid {
    padding: 0;
  }
}

/* Medium screen  */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /*** 
=============================================
    header Area style
=============================================
***/
  .header-style1-area .header-middle {
    max-width: 330px;
  }
  /*** 
=============================================
    About v1 Area style
=============================================
***/
  .about-left-content {
    display: block;
    margin-bottom: 50px;
  }
  /*** 
=============================================
    Appointment Style1 Area style
=============================================
***/
  .appointment-style1-area .bg-image img {
    min-height: 725px;
  }
  /*** 
=============================================
    Appointment Style1 Area style
=============================================
***/
  .appointment-style1-area {
    padding: 90px 0 90px;
    background: #18391A;
    background-image: none !important;
    margin-top: 35px;
  }
  .appointment-right-style1 {
    margin-top: 50px;
    margin-left: 100px;
  }
  /*** 
=============================================
    Latest Blog Area style
=============================================
***/
  .single-blog-post:before {
    width: 15px;
  }
  .single-blog-post .text-holder .blog-title {
    line-height: 24px;
    font-size: 18px;
    padding: 18px 20px 0;
    font-weight: 600;
  }
  /*** 
=============================================
    Testimonial Area style
=============================================
***/
  .testimonial-area:before {
    min-height: 408px;
  }
  .testimonial-area .owl-carousel {
    max-width: 475px;
  }
  .single-testimonial-style1 .text-holder {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  /*** 
=============================================
    Brand Area style
=============================================
***/
  .brand-area .brand li {
    max-width: 465px;
  }
  /*** 
=============================================
    Brand Area Style3 style
=============================================
***/
  .brand-area.style3 .brand li {
    margin-right: 20px;
  }
  /*** 
=============================================
    Footer area  style
=============================================
***/
  .single-footer-widget.margin-left {
    margin-left: 0;
  }
  .state-content ul li.inline {
    display: block;
    float: none;
    margin-right: 0;
    border-right: 0 solid;
    padding-right: 0;
  }
  /*** 
=============================================
    Header Style2 Area style
=============================================
***/
  .header-style2-logo {
    margin: 0px 0 30px;
    float: none !important;
    text-align: center;
  }
  .header-style2-logo a {
    width: 200px;
    margin: 0 auto;
  }
  .headers2-header-right {
    float: none !important;
    text-align: center;
    overflow: hidden;
  }
  .headers2-header-right ul {
    overflow: hidden;
  }
  /*** 
=============================================
    Mainmenu area style2 style
=============================================
***/
  .mainmenu-area.style2 .main-menu .navigation li {
    margin-right: 15px;
  }
  /*** 
=============================================
    Services Style2 Area  style
=============================================
***/
  .single-service-style2 .overlay-content {
    top: -189px;
  }
  /*** 
=============================================
    Why Choose Area  style
=============================================
***/
  .why-choose .content-box {
    max-width: 100%;
    width: 100%;
    min-height: 300px;
  }
  /*** 
=============================================
    Specialities Area  style
=============================================
***/
  .specialities-image-box {
    margin: 0 auto 70px;
  }
  /*** 
=============================================
    Gallery Area  style
=============================================
***/
  .gallery .sec-title {
    margin: 0;
    padding: 71px 0 73px;
  }
  /*** 
=============================================
    Appointment Style2 Area  style
=============================================
***/
  .appointment-right-style2 {
    max-width: 570px;
    background: #dfffe1;
    margin: 40px auto 0;
  }
  /*** 
=============================================
    Services Process Area  style
=============================================
***/
  .single-service-process:before {
    display: none;
  }
  /*** 
=============================================
    Feedback Section Style
=============================================
***/
  .feedback-section .carousel-outer {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    float: none;
    margin-top: 0;
  }
  .feedback-section .inner-content .video-holder {
    position: relative;
    top: auto;
    bottom: auto;
    right: auto;
    max-width: 100%;
    width: 100%;
  }
  .feedback-section .inner-content .video-holder img {
    transform: none;
  }
  .feedback-section .inner-content .video-holder:hover img {
    transform: none;
  }
  .feedback-section .carousel-outer .owl-theme .owl-nav {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 90px;
    height: 90px;
    z-index: 100;
    margin: 0;
  }
  /*** 
=============================================
    Mainmenu Area Style3  style
=============================================
***/
  .mainmenu-area.style3 .logo {
    margin: 32px 0 33px;
    margin-right: 40px;
  }
  .mainmenu-area.style3 .main-menu .navigation li {
    padding: 36.5px 10px 37.5px;
  }
  .mainmenu-area.style3 .main-menu .navigation > li > ul > li:hover > a {
    color: #4d4c4c;
  }
  .mainmenu-area.style3 .outer-search-box .seach-toggle:hover,
  .mainmenu-area.style3 .outer-search-box .seach-toggle.active {
    color: #4d4c4c;
  }
  /*** 
=============================================
    Contact Info Area  style
=============================================
***/
  .quick-contact-box {
    margin-top: 40px;
    padding: 54px 40px 48px;
  }
  /*** 
=============================================
    Working Section style
=============================================
***/
  .appointment-right-style2 {
    max-width: 570px;
    background: #dfffe1;
    margin: 40px auto 0;
    padding: 25px 0px 25px;
  }
  .appointment-right-style2 .button a.white-bg {
    background: #dfffe1;
  }
  /*** 
=============================================
    Shop Area style
=============================================
***/
  #shop-area .sidebar-wrapper {
    position: relative;
    padding-top: 70px;
  }
  /*** 
=============================================
    Single Shop Area style
=============================================
***/
  .single-shop-content .img-holder {
    margin-right: 20px;
  }
  .single-shop-content .content-box {
    margin-left: -20px;
  }
  /*** 
=============================================
    Login Register Area style
=============================================
***/
  .login-register-area .form a.forgot-password {
    margin: 70px 0 0;
  }
  .login-register-area .form.register {
    overflow: hidden;
    margin-top: 50px;
  }
  .login-register-area .form h6 {
    margin: 28px 0 0;
  }
  /*** 
=============================================
    Blog Large Area style
=============================================
***/
  .blog-large-area .single-blog-post .text-holder {
    padding-left: 15px;
  }
  /*** 
=============================================
    Blog Single area style
=============================================
***/
  .author-caption-box .text-holder {
    display: block;
    margin-top: 20px;
  }
  /*** 
=============================================
    Pricing Table3 Area   style
=============================================
***/
  .single-pricing-box3 .inner-content {
    padding: 0 5px;
  }
  /*** 
=============================================
    Single Service Area   style
=============================================
***/
  .single-service-top .text-holder {
    margin-top: 40px;
  }
  /*** 
=============================================
    Faq Area style
=============================================
***/
  .faq-sidebar .sidebar-appoinment {
    margin-top: 70px;
  }
  /*** 
=============================================
    Locations Area style
=============================================
***/
  .single-location-box .text-holder {
    padding: 60px 35px 59px;
    padding-right: 10px;
  }
  .single-location-box .text-holder ul li {
    margin-bottom: 26px;
  }
  /*** 
=============================================
    Corporate Services Area style
=============================================
***/
  .corporate-services-box {
    position: absolute;
    top: 50px;
    left: 50px;
    margin-left: 0px;
  }
  /*** 
=============================================
    Corporate Services Area style
=============================================
***/
  .specialities-image-box2 {
    display: block;
    max-width: 450px;
    padding-bottom: 100px;
  }
  .specialities-content2 .inner-content-box ul li {
    max-width: 465px;
    padding: 0 20px;
  }
  /*** 
=============================================
    Appointment Style3 Area style
=============================================
***/
  .appoinment-form3 .appoinment {
    max-width: 570px;
    float: none;
    margin-right: 0;
    background: rgba(255, 255, 255, 0.93);
    margin: 0 auto;
  }
  .appointment-right-style3 .inner-content {
    max-width: 470px;
    width: 100%;
    float: none;
    margin-left: 0;
    padding-top: 90px;
    margin: 0 auto;
  }
}

/* Tablet Layout: 768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  /*** 
=============================================
    Top Bar Area style
=============================================
***/
  .top-bar .top-right {
    max-width: 185px;
  }
  /*** 
=============================================
    header Area style  
=============================================
***/
  .header-style1-area .header-left {
    width: 225px;
  }
  .header-style1-area .header-middle {
    max-width: 240px;
  }
  .header-style1-area .header-right {
    width: 225px;
  }
  /*** 
=============================================
    About v1 Area style
=============================================
***/
  .about-left-content {
    display: block;
    margin-bottom: 50px;
  }
  .about-right-content .single-box.left {
    min-height: 185px;
    padding: 35px 0;
    margin: 0px 0 35px;
  }
  /*** 
=============================================
    Services Style1 Area style
=============================================
***/
  .single-service-style1 {
    max-width: 400px;
    margin: 0 auto 40px;
  }
  .services-style1-area .img-holder {
    margin-bottom: 40px;
  }
  /*** 
=============================================
    Appointment Style1 Area style
=============================================
***/
  .appointment-style1-area {
    padding: 90px 0 90px;
    background: #18391A;
    background-image: none !important;
    margin-top: 35px;
  }
  .appointment-right-style1 {
    margin-top: 50px;
    margin-left: 100px;
  }
  /*** 
=============================================
    Latest Blog Area style
=============================================
***/
  .single-blog-post {
    max-width: 550px;
    margin: 0 auto 60px;
  }
  /*** 
=============================================
    Testimonial Area style
=============================================
***/
  .testimonial-area:before {
    min-height: 357px;
  }
  .testimonial-area .owl-carousel {
    max-width: 360px;
  }
  .single-testimonial-style1 .text-holder {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  /*** 
=============================================
    Brand Area style
=============================================
***/
  .brand-area .brand li {
    max-width: 345px;
  }
  /*** 
=============================================
    Footer area  style
=============================================
***/
  .single-footer-widget.marbtm50 {
    display: block;
    margin-bottom: 50px;
  }
  .single-footer-widget.margin-left {
    margin-left: 0;
  }
  /*** 
=============================================
    Header Style2 Area style
=============================================
***/
  .header-style2-logo {
    margin: 0px 0 30px;
    float: none !important;
    text-align: center;
  }
  .header-style2-logo a {
    width: 200px;
    margin: 0 auto;
  }
  .headers2-header-right {
    float: none !important;
    text-align: center;
    overflow: hidden;
  }
  .headers2-header-right ul {
    overflow: hidden;
  }
  .headers2-header-right ul li {
    margin-right: 8px;
    padding-right: 8px;
  }
  /*** 
=============================================
    Mainmenu Area Style2 style
=============================================
***/
  .mainmenu-area.style2 .inner-content {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0;
  }
  .mainmenu-area.style2 .button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    height: 80px;
    z-index: 99;
    margin: 0;
    margin-right: 10px;
  }
  .mainmenu-area.style2 .button a {
    padding: 18px 20px;
    border-radius: 0;
    margin: 10px 0;
  }
  .mainmenu-area.style2 .outer-search-box {
    padding-left: 0px;
    margin: 32px 0;
    position: absolute;
    top: 0;
    right: 265px;
    z-index: 99;
  }
  .mainmenu-area.style2 .search-box.now-visible {
    margin-top: 32px;
  }
  /*** 
=============================================
    Services Style2 Area  style
=============================================
***/
  .services-style2-area {
    padding-top: 90px;
    padding-bottom: 159px;
  }
  .single-service-style2 {
    max-width: 370px;
    margin: 0 auto 60px;
  }
  /*** 
=============================================
    Why Choose Area  style
=============================================
***/
  .why-choose .content-box {
    max-width: 100%;
    width: 100%;
    padding-bottom: 46px;
  }
  .why-choose .content-box ul {
    max-width: 500px;
  }
  .why-choose .content-box ul.bdr-right:before {
    display: none;
  }
  /*** 
=============================================
    Services Process Area  style
=============================================
***/
  .single-service-process:before {
    display: none;
  }
  /*** 
=============================================
    Specialities Area  style
=============================================
***/
  .specialities-image-box {
    margin: 0 auto 70px;
  }
  /*** 
=============================================
    Gallery Area  style
=============================================
***/
  .gallery .sec-title {
    margin: 0;
    padding: 85px 0 83px;
  }
  /*** 
=============================================
    Appointment Style2 Area  style
=============================================
***/
  .appointment-right-style2 {
    max-width: 570px;
    background: #dfffe1;
    margin: 40px 0 0;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  /*** 
=============================================
    Feedback Section Style
=============================================
***/
  .feedback-section .carousel-outer {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    float: none;
    margin-top: 0;
  }
  .feedback-section .inner-content .video-holder {
    position: relative;
    top: auto;
    bottom: auto;
    right: auto;
    max-width: 100%;
    width: 100%;
  }
  .feedback-section .inner-content .video-holder img {
    transform: none;
  }
  .feedback-section .inner-content .video-holder:hover img {
    transform: none;
  }
  .feedback-section .carousel-outer .owl-theme .owl-nav {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 90px;
    height: 90px;
    z-index: 100;
    margin: 0;
  }
  /*** 
=============================================
    Header Style3 Area  style
=============================================
***/
  .header-style3-logo {
    float: none !important;
    text-align: center;
    margin-bottom: 15px;
  }
  .headers3-header-right {
    float: none !important;
    overflow: hidden;
    text-align: center;
  }
  .headers3-header-right ul {
    float: none;
    padding-right: 0;
    overflow: hidden;
  }
  .headers3-header-right ul li {
    display: block;
    float: none;
    border-right: none;
    padding-right: 0;
    margin-right: 0;
    line-height: 40px;
  }
  .headers3-header-right .toggler-button .nav-toggler {
    top: 13px;
    right: 0;
  }
  /*** 
=============================================
    Mainmenu Area Style4 style
=============================================
***/
  .mainmenu-area.style4 .main-menu .navigation li {
    position: relative;
    float: none;
    display: block;
    padding: 0;
    margin-right: 40px;
  }
  .mainmenu-area.style4 .main-menu .navigation li a {
    color: #dfffe1;
  }
  .mainmenu-area.style4 .help-line {
    position: absolute;
    top: 0;
    right: 0px;
    z-index: 10;
    margin: 28px 0;
  }
  .mainmenu-area.style4 .outer-search-box {
    position: absolute;
    top: 0;
    left: 70px;
    padding-left: 0px;
    margin: 32px 0;
    z-index: 10;
  }
  .mainmenu-area.style4 .search-box.now-visible {
    margin-top: 33px;
  }
  /*** 
=============================================
    Contact Info Area  style
=============================================
***/
  .contact-box-content .img-holder {
    max-width: 100%;
    width: 100%;
  }
  .quick-contact-box {
    margin-top: 40px;
    padding: 54px 40px 48px;
  }
  .contact-form {
    margin-left: 0;
    padding-left: 0px;
    z-index: 1;
    margin-top: 50px;
  }
  .contact-form:before {
    display: none;
  }
  .contact-form form .input-box .ui-selectmenu-button {
    background: #dfffe1;
  }
  /*** 
=============================================
    Location Map Area style
=============================================
***/
  #contact-google-map {
    max-width: 670px;
    width: 100%;
    height: 530px;
    float: left;
    margin-top: 40px;
  }
  /*** 
=============================================
    Working Section style
=============================================
***/
  .working-section .appointment-right-style2 {
    max-width: 570px;
    background: #dfffe1;
    margin: 0px auto 0;
    padding: 0;
  }
  /*** 
=============================================
    Shop Area style
=============================================
***/
  #shop-area .sidebar-wrapper {
    position: relative;
    padding-top: 70px;
  }
  /*** 
=============================================
    Single Shop Area style
=============================================
***/
  .single-shop-content .img-holder {
    margin-right: 0px;
    max-width: 330px;
  }
  .single-shop-content .content-box {
    margin-left: 0;
    margin-top: 0;
  }
  .related-product .single-product-item {
    margin-bottom: 40px;
  }
  /*** 
=============================================
    Cart Area style
=============================================
***/
  .cart-area .cart-bottom .calculate-shipping {
    padding-bottom: 60px;
  }
  /*** 
=============================================
    Checkout Area style
=============================================
***/
  .checkout-area .form.shipping-info {
    padding-top: 60px;
  }
  .checkout-area .cart-total {
    padding-top: 60px;
  }
  /*** 
=============================================
    Login Register Area style
=============================================
***/
  .login-register-area .form a.forgot-password {
    margin: 70px 0 0;
  }
  .login-register-area .register {
    padding-top: 50px;
  }
  .login-register-area .form h6 {
    color: #01ff01;
    float: left;
    font-size: 14px;
    margin-bottom: 0;
    margin-right: 0;
    margin-top: 27px;
  }
  /*** 
=============================================
    blog large area style
=============================================
***/
  .blog-large-area .blog-post:before {
    display: none;
  }
  #blog-area .sidebar-wrapper {
    margin-top: 80px;
    padding-left: 0px;
  }
  /*** 
=============================================
    Blog Single area style
=============================================
***/
  .author-caption-box .text-holder {
    display: block;
    margin-top: 20px;
  }
  /*** 
=============================================
    Brand Area Style3 style
=============================================
***/
  .brand-area.style3 .brand li {
    max-width: 325px;
    margin-bottom: 45px;
    margin-right: 20px;
    text-align: center;
  }
  /*** 
=============================================
    Single Service Area   style
=============================================
***/
  .single-service-top .text-holder {
    margin-top: 40px;
  }
  .single-service-sidebar {
    margin-left: 0px;
    margin-top: 60px;
  }
  /*** 
=============================================
    About Content Area style
=============================================
***/
  .about-content-box {
    margin-top: 0;
    padding-right: 0;
    margin-bottom: 40px;
  }
  /*** 
=============================================
    Faq Area style
=============================================
***/
  .faq-sidebar .sidebar-appoinment {
    margin-top: 70px;
  }
  .faq-content-box .inner-content .single-box .left-content {
    max-width: 100%;
    width: 100%;
    float: none;
  }
  .faq-content-box .inner-content .single-box .right-content {
    float: none;
    max-width: 100%;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-top: 30px;
  }
  .faq-content-box .inner-content .single-box .right-content:before {
    display: none;
  }
  .faq-content-box .inner-content .single-box .right-content:after {
    display: none;
  }
  /*** 
=============================================
    Corporate Services Area style
=============================================
***/
  .corporate-services-box {
    position: absolute;
    top: 50px;
    left: 50px;
    margin-left: 0px;
  }
  /*** 
=============================================
    Mainmenu Area Style3 style
=============================================
***/
  .mainmenu-area.style3 {
    position: relative;
    z-index: 999;
  }
  .mainmenu-area.style3 .logo {
    position: absolute;
    top: 0;
    left: 75px;
    margin: 27px 0 28px;
    margin-right: 0;
    z-index: 10;
  }
  .cart-toggler-option {
    position: absolute;
    top: 0;
    right: 0;
    height: 80px;
    width: 150px;
    z-index: 10;
  }
  .cart-toggler-option .cart-button a {
    width: 75px;
    height: 80px;
    line-height: 80px;
  }
  .cart-toggler-option .toggler-button .nav-toggler {
    width: 75px;
    height: 80px;
    line-height: 80px;
  }
  .cart-toggler-option .toggler-button .nav-toggler .inner {
    display: block;
    margin: 32px 0 48px;
  }
  .mainmenu-area.style3 .outer-search-box {
    padding-left: 0px;
    margin: 32px 0;
    position: absolute;
    top: 0;
    right: 160px;
    z-index: 10;
  }
  .mainmenu-area.style3 .search-box.now-visible {
    top: 100%;
    margin-top: 32px;
    opacity: 1;
    visibility: visible;
    z-index: 9999999;
  }
  /*** 
=============================================
    Repair Area style
=============================================
***/
  .repair-area .repair-carousel {
    margin-top: 0px;
    background: #dfffe1;
  }
  /*** 
=============================================
    Corporate Services Area style
=============================================
***/
  .specialities-image-box2 {
    display: block;
    max-width: 450px;
    padding-bottom: 100px;
  }
  .specialities-content2 .inner-content-box ul li {
    max-width: 345px;
    padding: 0 20px;
  }
  /*** 
=============================================
    Appointment Process Area style
=============================================
***/
  .appointment-process ul li {
    width: 50%;
    margin-bottom: 40px;
  }
  .appointment-process ul li:before {
    display: none;
  }
  .appointment-button {
    display: block;
    margin-top: 10px;
  }
  /*** 
=============================================
    Appointment Style3 Area style
=============================================
***/
  .appoinment-form3 .appoinment {
    max-width: 570px;
    float: none;
    margin-right: 0;
    background: rgba(255, 255, 255, 0.93);
    margin: 0 auto;
  }
  .appointment-right-style3 .inner-content {
    max-width: 470px;
    width: 100%;
    float: none;
    margin-left: 0;
    padding-top: 90px;
    margin: 0 auto;
  }
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
  /*** 
=============================================
    Top Bar Area style
=============================================
***/
  .top-bar-style1 {
    padding: 20px 0;
  }
  .top-bar .top-left {
    max-width: 100%;
    width: 100%;
    float: none !important;
    overflow: hidden;
  }
  .top-bar .top-left ul {
    width: 300px;
    margin: 0 auto;
    text-align: center;
  }
  .top-bar .top-left ul li {
    display: block;
    float: none;
    margin-right: 0;
    padding-right: 0;
    border-right: none;
    line-height: 24px;
  }
  .top-bar .top-right {
    max-width: 100%;
    width: 100%;
    float: none !important;
    margin: 10px 0 0;
  }
  .top-bar .top-right .header-social-links {
    text-align: center;
    line-height: 10px;
    margin: 0;
  }
  .top-bar .top-right .header-social-links li {
    margin: 0 7px;
  }
  /*** 
=============================================
    header Area style  
=============================================
***/
  .header-style1-area .header-left {
    width: 235px;
    float: none !important;
    margin: 0 auto;
    border: none;
  }
  .header-style1-area .header-middle {
    max-width: 240px;
    float: none !important;
    margin: 0 auto;
  }
  .logo {
    display: inline-block;
    transition: all 500ms ease;
    margin: 10px 0;
  }
  .header-style1-area .header-right {
    width: 235px;
    float: none !important;
    margin: 0 auto;
    border: none;
  }
  /*** 
=============================================
    Call To Action Area style
=============================================
***/
  .call-to-action-area {
    padding-bottom: 68px;
    padding-top: 30px;
  }
  .callto-action ul .single-box {
    width: 100%;
    max-width: 420px;
    float: none;
    text-align: center;
    margin: 0 auto;
    border: none;
  }
  .callto-action ul .single-box .icon-holder {
    top: -140px;
  }
  .callto-action ul .single-box .icon-holder:before {
    display: none;
  }
  .callto-action ul .single-box .inner {
    margin-top: 135px;
    border: none;
  }
  /*** 
=============================================
    About v1 Area style
=============================================
***/
  .about-left-content {
    display: block;
    margin-bottom: 50px;
  }
  .about-right-content .single-box.left {
    min-height: 185px;
    padding: 35px 0;
    margin: 0px 0 35px;
  }
  /*** 
=============================================
    Services Style1 Area style
=============================================
***/
  .single-service-style1 {
    max-width: 400px;
    margin: 0 auto 40px;
  }
  .services-style1-area .img-holder {
    margin-bottom: 40px;
  }
  /*** 
=============================================
    Appointment Style1 Area style
=============================================
***/
  .appointment-style1-area {
    padding: 90px 0 90px;
    background: #18391A;
    background-image: none !important;
    margin-top: 35px;
  }
  .appointment-right-style1 {
    margin-top: 50px;
    margin-left: 0px;
  }
  .appoinment .text p br {
    display: none;
  }
  /*** 
=============================================
    Latest Blog Area style
=============================================
***/
  .single-blog-post {
    max-width: 450px;
    margin: 0 auto 60px;
  }
  /*** 
=============================================
    Testimonial Area style
=============================================
***/
  .testimonial-area {
    background: #dfffe1;
  }
  .testimonial-area .owl-carousel {
    max-width: 100%;
  }
  .single-testimonial-style1 .text-holder {
    margin-right: 0px;
    margin-bottom: 10px;
    background: #dfffe1;
  }
  .testimonial-area .owl-theme .owl-nav {
    position: relative;
    left: 0;
    right: 0;
    bottom: 270px;
  }
  /*** 
=============================================
    Brand Area style
=============================================
***/
  .brand-area .brand li {
    max-width: 100%;
  }
  /*** 
=============================================
    Footer area  style
=============================================
***/
  .single-footer-widget.marbtm50 {
    display: block;
    margin-bottom: 50px;
  }
  .single-footer-widget.margin-left {
    margin-left: 0;
  }
  .single-footer-widget.pdtop-50 {
    display: block;
    padding-top: 50px;
  }
  /*** 
=============================================
    Top Bar style2  style
=============================================
***/
  .top-bar-style2 .top-bar {
    padding: 12px 0 7px;
  }
  .topmenu {
    float: none !important;
    overflow: hidden;
    text-align: center;
  }
  .topmenu li {
    float: none;
    margin: 0 5px;
    line-height: 40px;
  }
  .topmenu li:before {
    display: none;
  }
  .top-right-v2 {
    padding-right: 60px;
    margin: 0px 0 11px;
    float: none !important;
  }
  /*** 
=============================================
    Header Style2 Area style
=============================================
***/
  .header-style2-area {
    padding: 29px 0 4px;
  }
  .header-style2-logo {
    margin: 0px 0 30px;
    float: none !important;
    text-align: center;
  }
  .header-style2-logo a {
    width: 200px;
    margin: 0 auto;
  }
  .headers2-header-right {
    float: none !important;
    text-align: center;
    overflow: hidden;
  }
  .headers2-header-right ul {
    overflow: hidden;
  }
  .headers2-header-right ul li {
    margin: 0;
    padding: 0;
    display: block;
    float: none;
    border: none;
    margin-bottom: 20px;
  }
  /*** 
=============================================
    Mainmenu Area Style2 style
=============================================
***/
  .mainmenu-area.style2 .inner-content {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0;
  }
  .mainmenu-area.style2 .button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    height: 80px;
    z-index: 99;
    margin: 0;
    margin-right: 10px;
  }
  .mainmenu-area.style2 .button a {
    padding: 18px 20px;
    border-radius: 0;
    margin: 10px 0;
  }
  .mainmenu-area.style2 .outer-search-box {
    padding-left: 0px;
    margin: 32px 0;
    position: absolute;
    top: 0;
    right: 265px;
    z-index: 99;
  }
  .mainmenu-area.style2 .search-box.now-visible {
    margin-top: 32px;
  }
  /*** 
=============================================
    Services Style2 Area  style
=============================================
***/
  .services-style2-area {
    padding-top: 90px;
    padding-bottom: 159px;
  }
  .single-service-style2 {
    max-width: 370px;
    margin: 0 auto 60px;
  }
  /*** 
=============================================
    Why Choose Area  style
=============================================
***/
  .why-choose .content-box {
    max-width: 100%;
    width: 100%;
    padding-bottom: 46px;
  }
  .why-choose .content-box ul {
    max-width: 500px;
  }
  .why-choose .content-box ul.bdr-right:before {
    display: none;
  }
  /*** 
=============================================
    Services Process Area  style
=============================================
***/
  .single-service-process:before {
    display: none;
  }
  .single-service-process {
    padding-bottom: 90px;
    padding-right: 190px;
  }
  .single-service-process.right {
    padding-left: 190px;
    padding-right: 0;
  }
  /*** 
=============================================
    Specialities Area  style
=============================================
***/
  .specialities-image-box {
    margin: 0 auto 70px;
    max-width: 250px;
  }
  .specialities-image-box .single-option.one {
    left: -45px;
    top: 60px;
  }
  .specialities-image-box .single-option.two {
    left: -125px;
    bottom: 100px;
  }
  .specialities-image-box .single-option.four {
    top: 35px;
    right: -70px;
    width: 165px;
  }
  .specialities-image-box .single-option.five {
    top: 120px;
    right: -50px;
  }
  .specialities-image-box .single-option.six {
    right: -85px;
    bottom: 75px;
  }
  .specialities-image-box .single-option.three {
    left: 20px;
    bottom: 40px;
    width: 120px;
  }
  .specialities-content .inner-content {
    margin-left: 0px;
  }
  /*** 
=============================================
    Gallery Area  style
=============================================
***/
  .gallery .sec-title {
    margin: 0;
    padding: 0px 0 50px;
  }
  /*** 
=============================================
    Feedback Section Style
=============================================
***/
  .feedback-section .carousel-outer {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    float: none;
    margin-top: 0;
  }
  .feedback-section .inner-content .video-holder {
    position: relative;
    top: auto;
    bottom: auto;
    right: auto;
    max-width: 100%;
    width: 100%;
  }
  .feedback-section .inner-content .video-holder img {
    transform: none;
  }
  .feedback-section .inner-content .video-holder:hover img {
    transform: none;
  }
  .feedback-section .carousel-outer .owl-theme .owl-nav {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 90px;
    height: 90px;
    z-index: 100;
    margin: 0;
  }
  /*** 
=============================================
    Appointment Style2 Area  style
=============================================
***/
  .appointment-right-style2 {
    max-width: 570px;
    background: #dfffe1;
    margin: 40px 0 0;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  /*** 
=============================================
    Header Style3 Area  style
=============================================
***/
  .header-style3-logo {
    float: none !important;
    text-align: center;
    margin-bottom: 15px;
  }
  .headers3-header-right {
    float: none !important;
    overflow: hidden;
    text-align: center;
  }
  .headers3-header-right ul {
    float: none;
    padding-right: 0;
    overflow: hidden;
  }
  .headers3-header-right ul li {
    display: block;
    float: none;
    border-right: none;
    padding-right: 0;
    margin-right: 0;
    line-height: 40px;
  }
  .headers3-header-right .toggler-button .nav-toggler {
    top: 13px;
    right: 0;
  }
  /*** 
=============================================
    Mainmenu Area Style4 style
=============================================
***/
  .mainmenu-area.style4 .main-menu .navigation li {
    position: relative;
    float: none;
    display: block;
    padding: 0;
    margin-right: 40px;
  }
  .mainmenu-area.style4 .main-menu .navigation li a {
    color: #dfffe1;
  }
  .mainmenu-area.style4 .help-line {
    position: absolute;
    top: 0;
    right: 0px;
    z-index: 10;
    margin: 28px 0;
  }
  .mainmenu-area.style4 .outer-search-box {
    position: absolute;
    top: 0;
    left: 70px;
    padding-left: 0px;
    margin: 32px 0;
    z-index: 10;
  }
  .mainmenu-area.style4 .search-box.now-visible {
    margin-top: 33px;
  }
  /*** 
=============================================
    Breadcrumb Area Style
=============================================
***/
  .breadcrumb-area .title {
    display: block;
    float: none;
    margin: 0 0 20px;
  }
  .breadcrumb-area .breadcrumb-menu {
    display: block;
    float: none;
  }
  /*** 
=============================================
    Contact Info Area  style
=============================================
***/
  .contact-box-content .img-holder {
    max-width: 100%;
    width: 100%;
  }
  .quick-contact-box {
    margin-top: 40px;
    padding: 54px 40px 48px;
  }
  .contact-form {
    margin-left: 0;
    padding-left: 0px;
    z-index: 1;
    margin-top: 50px;
  }
  .contact-form:before {
    display: none;
  }
  .contact-form form .input-box .ui-selectmenu-button {
    background: #dfffe1;
  }
  /*** 
=============================================
    Location Map Area style
=============================================
***/
  #contact-google-map {
    max-width: 670px;
    width: 100%;
    height: 530px;
    float: left;
    margin-top: 40px;
  }
  /*** 
=============================================
    Working Section style
=============================================
***/
  .working-section .appointment-right-style2 {
    max-width: 570px;
    background: #dfffe1;
    margin: 0px auto 0;
    padding: 0;
  }
  /*** 
=============================================
    Shop Area style
=============================================
***/
  #shop-area .sidebar-wrapper {
    position: relative;
    padding-top: 70px;
  }
  /*** 
=============================================
    Single Shop Area style
=============================================
***/
  .single-shop-content .img-holder {
    margin-right: 0px;
    max-width: 400px;
  }
  .single-shop-content .content-box {
    margin-left: 0;
    margin-top: 35px;
  }
  .related-product .single-product-item {
    margin-bottom: 40px;
  }
  /*** 
=============================================
    Cart Area style
=============================================
***/
  .cart-middle .apply-coupon {
    padding-left: 0;
  }
  .cart-middle .apply-coupon input[type="text"] {
    margin: 0 0 20px;
    position: static;
    width: 225px;
  }
  .cart-area .cart-bottom .calculate-shipping {
    padding-bottom: 60px;
  }
  .cart-middle .update-cart {
    float: left !important;
    margin: 20px 0 0;
  }
  .cart-bottom .calculate-shipping input[type="text"] {
    margin-bottom: 25px;
  }
  /*** 
=============================================
    Checkout Area style
=============================================
***/
  .checkout-area .form.shipping-info {
    padding-top: 60px;
  }
  .checkout-area .cart-total {
    padding-top: 60px;
  }
  .checkout-area .exisitng-customer h5 a {
    display: block;
    width: 100%;
    padding: 0;
    margin-top: 7px;
  }
  .checkout-area .coupon h5 a {
    display: block;
    width: 100%;
    padding: 0;
    margin-top: 7px;
  }
  /*** 
=============================================
    Login Register Area style
=============================================
***/
  .login-register-area .form a.forgot-password {
    float: left;
    margin: 35px 0 0;
  }
  .login-register-area .form.register {
    padding-top: 60px;
  }
  .login-register-area .form .social-icon {
    float: left;
    margin-right: 0;
    margin-top: 30px;
  }
  .login-register-area .form .social-icon span {
    left: 0;
    position: relative;
  }
  .login-register-area .form .social-icon .login-with {
    position: static;
  }
  .login-register-area .form h6 {
    margin: 20px 0 0;
  }
  .login-register-area .form .right {
    display: block;
    overflow: hidden;
    margin-left: 0px;
  }
  /*** 
=============================================
    blog large area style
=============================================
***/
  .blog-large-area .blog-post:before {
    display: none;
  }
  #blog-area .sidebar-wrapper {
    margin-top: 80px;
    padding-left: 0px;
  }
  /*** 
=============================================
    Blog Single area style
=============================================
***/
  .blog-prev-next-option:before {
    display: none;
  }
  .author-caption-box .text-holder {
    display: block;
    margin-top: 20px;
  }
  .blog-prev-next-option .single {
    width: 100%;
  }
  .blog-prev-next-option .single.next {
    float: left;
    margin-top: 30px;
  }
  .blog-prev-next-option .single.next .title {
    text-align: left;
    padding-left: 0px;
  }
  /*** 
=============================================
    Brand Area Style3 style
=============================================
***/
  .brand-area.style3 .brand li {
    max-width: 100%;
    margin-bottom: 45px;
    margin-right: 0px;
    text-align: center;
  }
  /*** 
=============================================
    Single Service Area   style
=============================================
***/
  .single-service-top .text-holder {
    margin-top: 40px;
  }
  .single-service-sidebar {
    margin-left: 0px;
    margin-top: 60px;
  }
  /*** 
=============================================
    About Content Area style
=============================================
***/
  .about-content-box {
    margin-top: 0;
    padding-right: 0;
    margin-bottom: 40px;
  }
  /*** 
=============================================
    Faq Area style
=============================================
***/
  .faq-sidebar .sidebar-appoinment {
    margin-top: 70px;
  }
  .faq-content-box .inner-content .single-box .left-content {
    max-width: 100%;
    width: 100%;
    float: none;
  }
  .faq-content-box .inner-content .single-box .right-content {
    float: none;
    max-width: 100%;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-top: 30px;
  }
  .faq-content-box .inner-content .single-box .right-content:before {
    display: none;
  }
  .faq-content-box .inner-content .single-box .right-content:after {
    display: none;
  }
  /*** 
=============================================
    Corporate Services Area style
=============================================
***/
  .corporate-services-box {
    position: absolute;
    top: 50px;
    left: 50px;
    margin-left: 0px;
    display: none;
  }
  .corporate-services-left {
    padding-top: 400px;
  }
  .corporate-services-right {
    padding-top: 400px;
  }
  /*** 
=============================================
    Top Bar Style3 style
=============================================
***/
  .top-bar-style3 {
    padding: 17px 0 19px;
  }
  .top-bar.style3 .top-left ul li {
    border: none;
    line-height: 30px;
  }
  .top-bar.style3 .top-right {
    float: none !important;
    text-align: center;
  }
  /*** 
=============================================
    Mainmenu Area Style3 style
=============================================
***/
  .mainmenu-area.style3 {
    position: relative;
    z-index: 999;
  }
  .mainmenu-area.style3 .logo {
    position: absolute;
    top: 0;
    left: 75px;
    margin: 27px 0 28px;
    margin-right: 0;
    z-index: 10;
  }
  .cart-toggler-option {
    position: absolute;
    top: 0;
    right: 0;
    height: 80px;
    width: 150px;
    z-index: 10;
  }
  .cart-toggler-option .cart-button a {
    width: 75px;
    height: 80px;
    line-height: 80px;
  }
  .cart-toggler-option .toggler-button .nav-toggler {
    width: 75px;
    height: 80px;
    line-height: 80px;
  }
  .cart-toggler-option .toggler-button .nav-toggler .inner {
    display: block;
    margin: 32px 0 48px;
  }
  .mainmenu-area.style3 .outer-search-box {
    padding-left: 0px;
    margin: 32px 0;
    position: absolute;
    top: 0;
    right: 160px;
    z-index: 10;
  }
  .mainmenu-area.style3 .search-box.now-visible {
    top: 100%;
    margin-top: 32px;
    opacity: 1;
    visibility: visible;
    z-index: 9999999;
  }
  /*** 
=============================================
    Repair Area style
=============================================
***/
  .repair-area .repair-carousel {
    margin-top: 0px;
    background: #dfffe1;
  }
  /*** 
=============================================
    Corporate Services Area style
=============================================
***/
  .specialities-image-box2 {
    display: block;
    max-width: 450px;
    padding-bottom: 100px;
  }
  .specialities-content2 .inner-content-box ul li {
    max-width: 345px;
    margin: 0 auto;
    padding: 0 20px;
    border: none;
    float: none;
  }
  .specialities-content2 .inner-content-box ul li:nth-child(1) {
    border: none;
  }
  .specialities-content2 .inner-content-box ul li:nth-child(2) {
    border: none;
    padding: 0;
  }
  .specialities-content2 .inner-content-box ul li:nth-child(3) {
    border: none;
  }
  /*** 
=============================================
    Appointment Process Area style
=============================================
***/
  .appointment-process ul li {
    width: 100%;
    margin-bottom: 40px;
  }
  .appointment-process ul li:before {
    display: none;
  }
  .appointment-button {
    display: block;
    margin-top: 10px;
  }
  /*** 
=============================================
    Appointment Style3 Area style
=============================================
***/
  .appoinment-form3 .appoinment {
    max-width: 570px;
    float: none;
    margin-right: 0;
    background: rgba(255, 255, 255, 0.93);
    margin: 0 auto;
  }
  .appointment-right-style3 .inner-content {
    max-width: 470px;
    width: 100%;
    float: none;
    margin-left: 0;
    padding-top: 90px;
    margin: 0 auto;
  }
}

/* Wide Mobile Layout: 480px. */
@media only screen and (max-width: 599px) {
  .single-repair-item {
    display: block;
    float: none;
    background: #dfffe1;
    padding: 79px 0 44px;
    max-width: 100%;
    width: 100%;
    text-align: center;
    border-left: 1px solid #93ff99;
    border-top: 1px solid #93ff99;
    border-bottom: 1px solid #93ff99;
    border-right: 1px solid #93ff99;
  }
}

@media (min-width: 992px) {
  .navbar-collapse.collapse {
    display: block !important;
    height: auto;
    padding-bottom: 0;
    overflow: visible !important;
  }
  .navbar-toggle {
    display: none !important;
  }
}

@media only screen and (max-width: 991px) {
  .mainmenu-area .inner-content {
    position: relative;
    display: block;
  }
  .main-menu {
    display: block;
    padding-top: 17px;
    padding-bottom: 0px;
    z-index: 5;
    width: 100%;
  }
  .main-menu .navigation li {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
  .main-menu .collapse {
    border: none;
    border-radius: 0px !important;
    float: none;
    margin: 0;
    height: auto;
    overflow: auto;
    padding: 0;
    width: 100%;
  }
  .main-menu .collapse.in,
  .main-menu .collapsing {
    border: none;
    border-radius: 0px;
    margin: 0;
    padding: 0;
    border-top: none;
  }
  .main-menu .navbar-header {
    position: relative;
    float: none;
    display: block;
    text-align: right;
    width: 100%;
    padding: 63px 0 0;
    right: 0px;
    z-index: 12;
  }
  .main-menu .navbar-header .navbar-toggle {
    background: #000100;
    border: 1px dashed #000100;
    display: inline-block;
    border-radius: 3px;
    float: none;
    margin: 0;
    z-index: 7;
    width: 50px;
    height: 47px;
    cursor: pointer;
    padding: 0 9px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .main-menu .navbar-header .navbar-toggle .icon-bar {
    display: block;
    background: #dfffe1;
    width: 30px;
    height: 2px;
    border-radius: 1px;
  }
  .main-menu .navbar-header .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 6px;
  }
  .main-menu .navbar-collapse .navigation > li > ul {
    padding: 0px 0px;
  }
  .main-menu .navbar-collapse .navigation > li > ul:before {
    display: none;
  }
  .main-menu .navbar-collapse > .navigation {
    background: #000000 !important;
    float: none !important;
    width: 100% !important;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .main-menu .navbar-collapse .navigation > li {
    margin: 0px !important;
    float: none !important;
    width: 100%;
    padding: 0;
  }
  .main-menu .navbar-collapse .navigation li::before {
    display: none;
  }
  .main-menu .navbar-collapse .navigation li::after {
    display: none;
  }
  .main-menu .navbar-collapse .navigation li.dropdown > a:after {
    display: none !important;
  }
  .main-menu .navbar-collapse .navigation li a:after {
    display: none;
  }
  .main-menu .navbar-collapse > .navigation > li > ul,
  .main-menu .navbar-collapse > .navigation > li > ul > li > ul {
    position: relative;
    float: none;
    visibility: visible;
    opacity: 1;
    display: none;
    margin: 0px;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    width: 100%;
    background: none;
    border-radius: 0px;
    transition: none !important;
  }
  .main-menu .navbar-collapse > .navigation > li,
  .main-menu .navbar-collapse > .navigation > li > ul > li {
    border-top: 1px solid #000100;
    opacity: 1 !important;
    top: 0px !important;
    left: 0px !important;
    visibility: visible !important;
  }
  .main-menu .navbar-collapse > .navigation > li:last-child,
  .main-menu .navbar-collapse > .navigation > li > ul > li:last-child {
    border-bottom: 1px solid #000100;
  }
  .main-menu .navbar-collapse > .navigation > li > a {
    padding: 10px 10px !important;
    line-height: 22px;
    color: #dfffe1;
    text-align: left;
  }
  .main-menu .navbar-collapse .navigation li a::before {
    display: none;
  }
  .main-menu .navbar-collapse .navigation li ul li {
    padding: 0;
  }
  .main-menu .navbar-collapse .navigation li ul li:last-child {
    border-bottom: none;
  }
  .main-menu .navbar-collapse .navigation li ul li a {
    padding: 10px 10px !important;
    line-height: 22px;
    color: #dfffe1;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    font-family: 'Rubik', sans-serif;
  }
  .main-menu .navbar-collapse .navigation li ul li a:before {
    display: none;
  }
  .main-menu .navbar-collapse > .navigation > li:hover > a,
  .main-menu .navbar-collapse > .navigation > li > ul > li:hover > a,
  .main-menu .navbar-collapse > .navigation > li > ul > li > ul > li:hover > a,
  .main-menu .navbar-collapse > .navigation > li.current > a {
    background: #4d4c4c;
    color: #dfffe1 !important;
  }
  .main-menu .navbar-collapse > .navigation li.dropdown .dropdown-btn {
    display: block;
  }
  .mainmenu-area.style2 {
    margin-bottom: 0px;
  }
  .mainmenu-area.style2 .main-menu {
    position: relative;
    display: block;
    float: none;
  }
  .mainmenu-area.style2 .main-menu .navbar-collapse .navigation li {
    position: relative;
    display: block;
    padding: 0;
  }
  .mainmenu-area.style2 .main-menu .navbar-collapse .navigation li a {
    padding: 22px 5px;
    color: #dfffe1;
  }
  .main-menu .navbar-collapse > .navigation > li:hover > a,
  .main-menu .navbar-collapse > .navigation > li > ul > li:hover > a,
  .main-menu .navbar-collapse > .navigation > li.current > a {
    background: #4d4c4c;
    color: #dfffe1 !important;
  }
  .mainmenu-area.style3 .main-menu .navbar-collapse > .navigation > li:hover > a,
  .mainmenu-area.style3 .main-menu .navbar-collapse > .navigation > li > ul > li:hover > a,
  .mainmenu-area.style3 .main-menu .navbar-collapse > .navigation > li.current > a {
    background: #4d4c4c;
    color: black !important;
  }
  .mainmenu-area.style3 .main-menu .navigation li {
    position: relative;
    padding: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .main-slider .slide-content.left-slide {
    margin-left: 100px;
  }
}

@media only screen and (min-width: 1040px) and (max-width: 1199px) {
  .main-slider .slide-content.left-slide {
    margin-left: 100px;
  }
}

@media only screen and (min-width: 778px) and (max-width: 1039px) {
  .main-slider .slide-content.left-slide {
    margin-left: 100px;
  }
  .main-slider .slide-content .big-title {
    font-size: 32px;
    line-height: 1.2em;
  }
  .main-slider .slide-content .big-title span {
    font-weight: 600;
  }
  .main-slider .slide-content .text {
    font-size: 16px;
    margin: 5px 0 0px;
  }
  .main-slider .slide-content.left-slide .btn-box {
    margin: 0px;
  }
  .main-slider .slide-content .btn-box a.sb1 {
    padding: 17px 18px 19px;
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 777px) {
  /*** 
=============================================
    Rev slider wrapper style
=============================================
***/
  .main-slider {
    position: relative;
    margin-top: 0;
  }
  .tparrows {
    top: 50% !important;
    display: block;
  }
  .tp-forcenotvisible {
    visibility: visible !important;
    display: block !important;
  }
  .main-slider .slide-content {
    display: none;
  }
}

/***************************************************************************************************************
||||||||||||||||||||||||||       FIELDSET STYLESHEET FOR MHCHESS         |||||||||||||||||||||||||||||||||||||||
****************************************************************************************************************
/* 

/*** PAGE CONTENT ***/
.bard-content {
  margin: 4em 0;
}

/** image_set **/
.bard-image .width-quarter {
  width: 25%;
}

@media (max-width: 960px) {
  .bard-image .width-quarter {
    width: 100%;
  }
}

.bard-image .width-half {
  width: 50%;
}

@media (max-width: 960px) {
  .bard-image .width-half {
    width: 100%;
  }
}

.bard-image .width-three-quarter {
  width: 75%;
}

@media (max-width: 960px) {
  .bard-image .width-three-quarter {
    width: 100%;
  }
}

.bard-image .width-full {
  width: 100%;
}

.bard-image.left img {
  float: left;
  margin-top: .5em;
  margin-right: 2em;
  margin-bottom: 1em;
}

@media (max-width: 960px) {
  .bard-image.left img {
    margin-right: 0;
    margin-bottom: 1.5em;
  }
}

.bard-image.right img {
  float: right;
  margin-top: .5em;
  margin-left: 2em;
  margin-bottom: 1em;
}

@media (max-width: 960px) {
  .bard-image.right img {
    margin-left: 0;
    margin-bottom: 1.5em;
  }
}

.bard-image.center img {
  margin: 0 auto;
  margin-top: .5em;
  margin-bottom: 1em;
}

@media (max-width: 960px) {
  .bard-image.center img {
    width: 100%;
    margin-bottom: 1.5em;
  }
}

img.bard-img, .tab-content img {
  padding: 5px;
  border: 1px solid #e0e0e0;
  display: block;
  margin: 0 auto;
  box-shadow: 0px 0px 6px rgba(110, 110, 110, 0.2);
}

/** header_set **/
.heading_set h2, .heading_set h3, .heading_set h4, .heading_set h5, .heading_set h6 {
  margin-bottom: 1.2em;
}

.heading_set .float-left {
  float: left;
}

.heading_set .float-center {
  text-align: center;
}

.heading_set .float-right {
  float: right;
}

/** video_set **/
.bard-video.width-quarter {
  width: 25%;
}

@media (max-width: 960px) {
  .bard-video.width-quarter {
    width: 100%;
  }
}

.bard-video.width-half {
  width: 50%;
}

@media (max-width: 960px) {
  .bard-video.width-half {
    width: 100%;
  }
}

.bard-video.width-three-quarter {
  width: 75%;
}

@media (max-width: 960px) {
  .bard-video.width-three-quarter {
    width: 100%;
  }
}

.bard-video.width-full {
  width: 100%;
}

.bard-video.left {
  float: left;
  margin-top: .5em;
  margin-right: 2em;
  margin-bottom: 1em;
}

@media (max-width: 960px) {
  .bard-video.left {
    margin-right: 0;
    margin-bottom: 1.5em;
  }
}

.bard-video.right {
  float: right;
  margin-top: .5em;
  margin-left: 2em;
  margin-bottom: 1em;
}

@media (max-width: 960px) {
  .bard-video.right {
    margin-left: 0;
    margin-bottom: 1.5em;
  }
}

.bard-video.center {
  margin: 0 auto;
  margin-top: .5em;
  margin-bottom: 1em;
}

@media (max-width: 960px) {
  .bard-video.center {
    width: 100%;
    margin-bottom: 1.5em;
  }
}

/** horizontal_rule_set **/
.horizontal-rule.width-new-line {
  width: 100%;
  display: inline-block;
  clear: both;
}

.horizontal-rule.m-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.horizontal-rule.m-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.horizontal-rule.m-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

/** button_set **/
.bard-button {
  margin: 1.5em 0 2em;
}

.bard-button.left {
  text-align: left;
}

.bard-button.right {
  text-align: right;
}

.bard-button.center {
  text-align: center;
}

/** image_gallery_set **/
.bard-gall {
  padding: 2em 1em 0 1em;
  border-radius: 4px;
  margin-bottom: 1em;
}

.bard-gall .bard-gallery img {
  margin-bottom: 30px;
  transition: all .2s ease-in-out;
}

.bard-gall .bard-gallery img:hover {
  transform: scale(1.1);
}

/** blockquote **/
blockquote {
  margin: 15px 0 20px;
  color: grey;
}

/** tabs_set **/
.tabs-set {
  margin: 2em 0;
  background-color: #f7f7f7;
  padding: 2em;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
}

.tabs-set .tab-content {
  padding: 2em 1em;
}

.tabs-set .tab-nopadding {
  padding: 0 2em;
}

/***************************************************************************************************************
||||||||||||||||||||||||||       STATAMIC STYLESHEET FOR MHCHESS         |||||||||||||||||||||||||||||||||||||||
****************************************************************************************************************


/*** @IMPORTED 'statamic.scss' ***/
html, body {
  min-width: 360px;
}

body {
  font-size: 16px;
  line-height: 1.5em;
  box-sizing: border-box;
}

p.white, .state-content ul li .text p.white {
  color: #fff !important;
}

.content-section {
  padding: 80px 0 50px;
}

.content-section img {
  padding: 5px;
  border: 1px solid #e0e0e0;
  display: block;
  margin: 0 auto;
  box-shadow: 0px 0px 6px rgba(110, 110, 110, 0.2);
}

.content-section h1, .content-section h2, .content-section h3, .content-section h4, .content-section h5, .content-section h6, .content-section .h1, .content-section .h2, .content-section .h3, .content-section .h4, .content-section .h5, .content-section .h6 {
  color: #333333;
  font-weight: 300;
  line-height: 40px;
  margin: 0 0 11px;
}

.content-section h1, .content-section .h1 {
  font-size: 2.4em;
}

.content-section h2, .content-section .h2 {
  font-size: 2em;
}

.content-section h3, .content-section .h3 {
  font-size: 1.5em;
}

.content-section h4, .content-section .h4 {
  font-size: 1em;
  line-height: 1em;
}

.content-section h5, .content-section .h5 {
  font-size: .8em;
  line-height: 1em;
}

.content-section h6, .content-section .h6 {
  font-size: .5em;
  line-height: 1em;
}

.grey-background {
  background-color: #e9e9e9;
}

.black-background {
  background-color: #000;
}

.primary-background {
  background-color: #18391A;
  background-image: url(/assets/media/backgrounds/egg-shell.png);
}

.secondary-background {
  background-color: #000000;
  background-image: url(/assets/media/backgrounds/egg-shell.png);
}

.tertiary-background {
  background-color: #FFCF70;
  background-image: url(/assets/media/backgrounds/egg-shell.png);
}

.primary-color {
  color: #18391A !important;
}

.secondary-color {
  color: #000000 !important;
}

.tertiary-color {
  color: #FFCF70 !important;
}

.bg-light a {
  color: #18391A;
}

.bg-light a:hover {
  color: #45a54b;
}

/*** UTILITY CLASSES ***/
.clear {
  clear: both;
}

.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

/*** CONTENT CSS ***/
.header-top,
.layout-default .mainmenu-area {
  background-image: url(/assets/media/backgrounds/checker.jpg);
}

.layout-default .stricky-fixed .logo {
  display: none;
}

.layout-default .stricky-fixed .main-menu.float-left {
  float: none !important;
  text-align: center !important;
}

.layout-default nav {
  margin-top: 3em;
}

.layout-default .stricky-fixed nav {
  margin: 0;
}

.mainmenu-area.style3.stricky-fixed {
  background: #000;
}

@media screen and (min-width: 990px) {
  .mainmenu-area.style3.stricky-fixed {
    background: #f5f5f5;
  }
}

a {
  color: #36813b;
  transition: all .5s ease-in-out;
}

a:hover {
  color: #ccc;
}

.icon-holder img {
  height: 120px;
  margin-top: 1em;
}

h1.title {
  color: #18391A;
}

h1.title span {
  color: #FFCF70;
}

.header-style1-area .header-right p {
  margin: 0;
}

@media screen and (min-width: 768px) {
  .header-style1-area .header-right p {
    margin: 0 30px 3px;
  }
}

.header-style1-area .header-right h2 {
  padding-right: 0;
}

@media screen and (min-width: 980px) {
  .header-style1-area .header-right h2 {
    padding-right: 40px;
  }
}

.header-style1-area .header-right p.small {
  margin-right: 30px;
}

@media screen and (min-width: 980px) {
  .header-style1-area .header-right p.small {
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .header-style1-area .header-right p.small {
    margin-right: 36px;
  }
}

.primary {
  color: #18391A !important;
}

.secondary {
  color: #000000 !important;
}

.tertiary {
  color: #FFCF70 !important;
}

/*** FLEXSLIDER STUFF ****/
.flexslider {
  margin: 0;
  border: none;
  border-radius: 0;
}

.flex-viewport {
  border: none;
}

.flex-direction-nav a:before {
  margin-top: 10px;
}

.flexslider .slides > li {
  line-height: .8em;
}

@media (min-width: 480px) {
  .flexslider .slides > li {
    line-height: 2em;
  }
}

@media (min-width: 780px) {
  .flexslider .slides > li {
    line-height: 3em;
  }
}

@media (min-width: 960px) {
  .flexslider .slides > li {
    line-height: 5em;
  }
}

ul.slides li {
  position: relative;
}

ul.slides li .slide-content {
  padding-top: 1em;
  top: 0;
  left: 0;
  position: absolute;
  font-family: 'Poppins', Arial, Helvetica, sans-serif !important;
  color: #fff;
  font-size: 1vw;
}

@media (min-width: 960px) {
  ul.slides li .slide-content {
    padding-top: 2em;
  }
}

@media (min-width: 1500px) {
  ul.slides li .slide-content {
    padding-top: 150px;
  }
}

ul.slides li .slide-content p {
  text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.2);
}

ul.slides li .slide-content .small-line {
  font-size: 2em;
  font-weight: 300;
  white-space: nowrap;
}

ul.slides li .slide-content .medium-line {
  font-size: 4em;
  font-weight: 400;
  white-space: nowrap;
}

ul.slides li .slide-content .large-line {
  font-size: 6em;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
}

ul.slides li .slide-content a.btn-one {
  background-color: #18391A;
  margin-top: 1em;
}

ul.slides li .slide-content a.btn-one:hover {
  background-color: #FFCF70;
  color: #18391A;
}

.flex-direction-nav a,
.flex-direction-nav a::before {
  color: #36813b;
}

.slidertext-row .btn-one {
  padding: 13px 16px 13px;
}

/*** EVENTS CSS ***/
.event-date {
  background-color: #cfcfcf;
  position: relative;
  padding: 55px 5px 0;
  margin-left: 10px;
  height: 90px;
  border-radius: 10px;
  min-width: 145px;
  margin-bottom: .5em;
}

.event-date .year {
  display: block;
  position: absolute;
  right: -5px;
  top: 29px;
  transform: rotate(-90deg);
  font-size: 32px;
  font-weight: 100;
}

.event-date .month {
  text-transform: uppercase;
  font-size: 18px;
}

.event-date .day {
  font-size: 45px;
  line-height: 45px;
  position: absolute;
  left: 15px;
  top: 10px;
}

/*** NAVS & PILLS ***/
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: #FFCF70;
}

.contact-form .map {
  display: none;
}

@media (min-width: 980px) {
  .contact-form .map {
    display: block;
    position: absolute;
    top: -90px;
    left: 0;
    bottom: -90px;
    right: -10000px;
    content: "";
    z-index: 2;
  }
}

/*** SPONSOR PAGES ***/
.sponsor-lists ul, .sponsor-lists li {
  list-style: inherit;
}

.sponsor-categories {
  font-size: 1.4em;
}

.sponsor-categories a {
  background-color: #36813b;
  color: #fff;
  margin-right: 1em;
  transition: all .5s ease-in-out;
  padding: 20px;
  margin-bottom: 1em;
}

.sponsor-categories a:hover {
  color: #FFCF70;
  background-color: #18391A;
}

/*** CONTACT FORM ***/
.email-success {
  background-color: #63946e;
  border-top: 4px solid #14461f;
  border-bottom: 4px solid #14461f;
  height: 100px;
}
