/***************************************************************************************************************
||||||||||||||||||||||||||       FIELDSET STYLESHEET FOR MHCHESS         |||||||||||||||||||||||||||||||||||||||
****************************************************************************************************************
/* 

/*** PAGE CONTENT ***/

.bard-content {
    margin: 4em 0;
}

/** image_set **/
.bard-image {
    .width-quarter {
        width: 25%;

        @media (max-width: 960px) {
            width: 100%; 
        }
    }

    .width-half {
        width: 50%;
 
        @media (max-width: 960px) {
            width: 100%;
        }
    }

    .width-three-quarter {
        width: 75%;

        @media (max-width: 960px) {
            width: 100%;
        }
    }

    .width-full {
        width: 100%;
    }

    &.left img {
        float: left;
        margin-top: .5em;
        margin-right: 2em;
        margin-bottom: 1em;

        @media (max-width: 960px) {
            margin-right: 0;
            margin-bottom: 1.5em;
        }
    
    }

    &.right img {
        float: right;
        margin-top: .5em;
        margin-left: 2em;
        margin-bottom: 1em;

        @media (max-width: 960px) {
            margin-left: 0;
            margin-bottom: 1.5em;
        }
    }

    &.center img {
        margin: 0 auto;
        margin-top: .5em;
        margin-bottom: 1em;

        @media (max-width: 960px) {
            width: 100%;
            margin-bottom: 1.5em;
        }
    }

}
img.bard-img, .tab-content img {
    padding: 5px;
    border: 1px solid #e0e0e0;
    display: block;
    margin: 0 auto;
    box-shadow: 0px 0px 6px rgba(110, 110, 110, 0.2);
    }


/** header_set **/
.heading_set {

    h2, h3, h4, h5, h6 {
        margin-bottom: 1.2em;

    }

    .float-left {
        float: left;
    }
    .float-center {
        text-align: center;
    }
    .float-right {
        float: right;
    }

}

/** video_set **/
.bard-video {
    &.width-quarter {
        width: 25%;

        @media (max-width: 960px) {
            width: 100%;
        }
    }

    &.width-half {
        width: 50%;

        @media (max-width: 960px) {
            width: 100%;
        }
    }

    &.width-three-quarter {
        width: 75%;

        @media (max-width: 960px) {
            width: 100%;
        }
    }

    &.width-full {
        width: 100%;
    }

    &.left {
        float: left;
        margin-top: .5em;
        margin-right: 2em;
        margin-bottom: 1em;

        @media (max-width: 960px) {
            margin-right: 0;
            margin-bottom: 1.5em;
        }
    }

    &.right {
        float: right;
        margin-top: .5em;
        margin-left: 2em;
        margin-bottom: 1em;

        @media (max-width: 960px) {
            margin-left: 0;
            margin-bottom: 1.5em;
        }
    }

    &.center {
        margin: 0 auto;
        margin-top: .5em;
        margin-bottom: 1em;

        @media (max-width: 960px) {
            width: 100%;
            margin-bottom: 1.5em;
        }
    }
}

/** horizontal_rule_set **/
.horizontal-rule {

    &.width-new-line {
        width: 100%;
        display: inline-block;
        clear: both;
    }                               

    &.m-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    &.m-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    &.m-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

}

/** button_set **/
.bard-button {
    margin: 1.5em 0 2em;
    &.left {
        text-align: left;
    }

    &.right {
        text-align: right;
    }

    &.center {
        text-align: center;
    }
}

/** image_gallery_set **/
.bard-gall {
    padding: 2em 1em 0 1em;
    // background-color: rgb(240, 240, 240);
    border-radius: 4px;
    margin-bottom: 1em;

    .bard-gallery img {
        margin-bottom: 30px;
        transition: all .2s ease-in-out;
    }
    .bard-gallery img:hover {
        transform: scale(1.1);
    }
}

/** blockquote **/
blockquote {
    margin: 15px 0 20px;
    color: grey;
}

/** tabs_set **/
.tabs-set {
    margin: 2em 0;
    background-color: #f7f7f7;
    padding: 2em;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    
    .tab-content {
        padding: 2em 1em;
    }
    .tab-nopadding {
        padding: 0 2em;
    }
}