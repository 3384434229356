/******************************************************************************************
 ***********************              VARIABLES             *******************************
 ******************************************************************************************
*/

$primary-color: #18391A;
$secondary-color: #000000;
$tertiary-color: #FFCF70;
$light: #dfffe1; 
$dark: #000100;


/*** MIXINS ***/

@mixin clearfix() {
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }


/*** INSERTS ***/
@import 'style';
@import 'responsive';
// @import 'color-switcher-design';
// @import 'color-themes/olive-theme';
@import 'fieldsets';
@import 'statamic';
